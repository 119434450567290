import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ImportOptionsSectionProps {
  shouldAddOrUpdate: boolean;
  setShouldAddOrUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  disableCheckbox: boolean;
}

const ImportOptionsSection: React.FC<ImportOptionsSectionProps> = ({
  shouldAddOrUpdate,
  setShouldAddOrUpdate,
  disableCheckbox,
}) => {
  const { t } = useTranslation();

  return (
    <Box marginTop='1rem'>
      <Typography variant='h6'>
        {t('STEP_3_CHOOSE_OPTIONS_AND_IMPORT')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={shouldAddOrUpdate}
            color='default'
            disabled={disableCheckbox}
            onChange={() => setShouldAddOrUpdate(!shouldAddOrUpdate)}
            name='csv-import-option'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={t('UPDATE_RECORDS_THAT_ALREADY_EXIST')}
      />
    </Box>
  );
};

export default ImportOptionsSection;

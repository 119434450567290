import { Generate } from '@jsonforms/core';
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  configureDeviceRealTime,
  getDeviceConfigurationSchema,
} from '../../../api';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import FormSubmitButton from '../../../shared/components/FormSubmitButton';
import TitledCard from '../../../shared/components/TitledCard';
import Stack from '../../../shared/layouts/Stack';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';

interface ConfigureDeviceProps {
  device: Device;
}

const ConfigureDevice: React.FC<ConfigureDeviceProps> = ({ device }) => {
  const { t } = useTranslation();

  const deviceKey = device.key;
  const token = useSelector((state: State) => state.token)!;
  const [schema, setSchema] = useState({ type: 'object' });
  const [configData, setConfigData] = useState(device?.device_settings);

  const invalidInputs = false; // use handleSubmitConfig _errors

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(configureDeviceRealTime, {
      token,
      deviceKey,
      config: configData,
    });

  const handleFormDataChange = ({ data, _errors }: any) =>
    _errors
      ? setFeedback({
          message: t('FORM_DATA_ERROR'),
          msgType: 'error',
        })
      : setConfigData(data);

  const handleSubmitConfig = makeSubmitHandler({
    onSuccess: () => {
      setFeedback({
        message: t('CONFIGURING_DEVICE_WAS_SUCCESSFUL'),
        msgType: 'success',
      });
    },
  });

  // get the schema from device
  // Note: device must be connected via socket to the backend.
  useEffect(() => {
    getDeviceConfigurationSchema({
      token,
      deviceKey,
    }).then((resultSchema) => setSchema(resultSchema));
  }, [token, deviceKey]);

  return (
    <TitledCard
      title={t('CONFIGURE_DEVICE')}
      iconAvatar={<SettingsApplicationsOutlinedIcon />}
    >
      {schema ? (
        <Stack>
          <JsonForms
            schema={schema}
            uischema={Generate.uiSchema(schema)}
            renderers={materialRenderers}
            data={configData}
            cells={materialCells}
            onChange={handleFormDataChange}
          />
          <FormSubmitButton
            btnName={t('SEND_CONFIGURATION')}
            handleSubmit={handleSubmitConfig}
            invalidInputs={invalidInputs}
            loading={loading}
            feedback={feedback}
          />
        </Stack>
      ) : (
        <Typography>{t('LOADING_CONFIGURATION_FORM')}</Typography>
      )}
    </TitledCard>
  );
};

export default ConfigureDevice;

import { useState } from 'react';

// allows to update state with partial values only using the third function
export const usePartialStateUpdate = function <T>(
  initialValueObject: T
): [
  T,
  React.Dispatch<React.SetStateAction<T>>,
  (subValue: Partial<T>) => void
] {
  const [value, setValue] = useState(initialValueObject);
  const updateFields = (subValue: Partial<T>) =>
    setValue({ ...value, ...subValue });

  return [value, setValue, updateFields];
};

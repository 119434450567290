import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../api';
import { useInactivityCountdownTimer } from '../../hooks/useInactivityCountdownTimer';
import { setAuthToken } from '../../redux/actionCreators';

interface CountDownTimerProps {
  minutesForCountdown?: number;
}

const useLogoutWhenTimeOver = (countdownSeconds: number) => {
  const dispatch = useDispatch();
  // Note: use the automaticLogoutActive
  // to only trigger the logout if the setting is acutally turned on
  const certosSettings = useSelector((state: State) => state.settings);
  const { automaticLogoutActive } = certosSettings.securitySettings;

  useEffect(() => {
    if (countdownSeconds <= 0 && automaticLogoutActive) {
      logoutUser().then(() => {
        dispatch(setAuthToken(null));
      });
    }
  }, [countdownSeconds, automaticLogoutActive, dispatch]);
};

const CountDownTimer: React.FC<CountDownTimerProps> = ({
  minutesForCountdown = 1,
}) => {
  const { t } = useTranslation();

  const { displayTime, countdown } =
    useInactivityCountdownTimer(minutesForCountdown);

  useLogoutWhenTimeOver(countdown);

  return (
    <Typography variant='caption'>
      {t('INACTIVITY_COUNTDOWN', {
        displayTime,
      })}
    </Typography>
  );
};

export default CountDownTimer;

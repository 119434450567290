export enum RouteNames {
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgotpassword',
  RESET_PASSWORD = '/resetpassword',
  ACTIVATE_ACCOUNT = '/activateaccount',
  DASHBOARD = '/dashboard',
  DEVICES = '/devices',
  USERS_MANAGEMENT = '/users',
  TOOLS = '/tools',
  AUDIT_TRAIL = '/auditTrail',
  SETTINGS = '/settings',
  RULE_ENGINE = '/ruleEngine',
  PROFILE = '/profile',
  WIDGETS_LIBRARY = '/widgets',
  SUPPORT = '/support',
  IMPRINT = '/imprint',
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import JSONTreeView from '../../shared/components/JSONTreeView';
import TitledCard from '../../shared/components/TitledCard';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import EditSubMenuRecordForm from './EditSubMenuRecordForm';
import RemoveSubMenuRecord from './RemoveSubMenuRecord/RemoveSubMenuRecord';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import { useTranslation } from 'react-i18next';
import { PdfExport } from './PdfExport/PdfExport';

const SubMenuDetailPage: React.FC = () => {
  const { t } = useTranslation();

  const { deviceKey, submenueName, subMenuDataId } = useParams<{
    deviceKey: string;
    submenueName: string;
    subMenuDataId: string;
  }>();

  const device: Device = useSelector(
    (state: State) => state.devices.filter((d) => d.key === deviceKey)[0]
  );

  const subMenuDefinition =
    device && device.device_submenues
      ? device.device_submenues[submenueName]
      : null;

  const deviceDataTargetRecord: DevicesData = useSelector(
    (state: State) =>
      state.devicesData?.filter((record) => record._id === subMenuDataId)[0]
  );

  if (!deviceDataTargetRecord) {
    const subMenuPage = [RouteNames.DEVICES, deviceKey, submenueName].join('/');
    // this redirect would mean the user hasn't visited the subMenuPage first (in order to load the data form Backend)...
    // perhaps a 'manual' fetch of this single target record for better user experience would make sense, but consider ...
    return <Redirect to={subMenuPage} />;
  }

  return (
    <DefaultPageLayout>
      <Stack>
        <PdfExport
          deviceKey={deviceKey}
          subMenuName={submenueName}
          recordId={subMenuDataId}
        />
        <TitledCard title={t('DETAILS')} iconAvatar={<ListOutlinedIcon />}>
          <JSONTreeView
            name={submenueName + ' - ' + subMenuDataId}
            jsonObj={deviceDataTargetRecord}
          />
        </TitledCard>
        <EditSubMenuRecordForm
          deviceKey={deviceKey}
          subMenuName={submenueName}
          subMenuDefinition={subMenuDefinition}
          subMenuRecord={deviceDataTargetRecord}
        />
        <RemoveSubMenuRecord
          deviceKey={deviceKey}
          subMenuName={submenueName}
          subMenuDefinition={subMenuDefinition}
          subMenuRecord={deviceDataTargetRecord}
        />
      </Stack>
    </DefaultPageLayout>
  );
};

export default SubMenuDetailPage;

import React from 'react';
import NotificationsListItem from './NotificationsListItem';
import { List } from '@material-ui/core';

interface NotificationsListProps {
  notifications: UserNotification[];
}

const NotificationList: React.FC<NotificationsListProps> = ({
  notifications,
}) => {
  return (
    <List data-test-id='notifications-list'>
      {notifications.map((n) => (
        <NotificationsListItem notification={n} />
      ))}
    </List>
  );
};

export default NotificationList;

import React from 'react';
import { useTranslation } from 'react-i18next';
import CenteredFormLayout from '../../shared/layouts/CenteredFormLayout';
import ActivateAccountForm from './ActivateAccountForm';

const ActivateAccount: React.FC = () => {
  const { t } = useTranslation();
  return (
    <CenteredFormLayout
      title={t('ACCOUNT_ACTIVATION')}
      form={ActivateAccountForm}
    />
  );
};

export default ActivateAccount;

import React from 'react';
import { useTranslation } from 'react-i18next';

import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import TabletOutlinedIcon from '@material-ui/icons/TabletOutlined';

import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import IconCardLink from '../../shared/components/IconCardLink';
import Stack from '../../shared/layouts/Stack';
import ContactForm from './ContactForm';
import Section from '../../shared/layouts/Section';
import IconCardSection from '../../shared/layouts/IconCardSection';

const Support: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultPageLayout title={t('SUPPORT')}>
      <Stack>
        <IconCardSection title={t('DOCUMENTATION')}>
          <IconCardLink
            href='https://certos-core-backend-staging.herokuapp.com/api/docs/'
            title='Cloud Documentation'
            icon={<LayersOutlinedIcon />}
          />
          <IconCardLink
            href='https://certos-core-device-simulator.herokuapp.com/'
            title='Device Simulator'
            icon={<TabletOutlinedIcon />}
          />
        </IconCardSection>

        <IconCardSection title={t('WHITEPAPERS')}>
          <IconCardLink
            href='https://certos-files.s3.eu-central-1.amazonaws.com/001+Security+%26+Encryption+.pdf'
            title={t('SECURITY_WHITEPAPER')}
            icon={<SecurityOutlinedIcon />}
          />
          <IconCardLink
            href='https://certos-files.s3.eu-central-1.amazonaws.com/002+FDA+Whitepaper.pdf'
            title={t('FDA_WHITEPAPER')}
            icon={<LocalHospitalOutlinedIcon />}
          />
        </IconCardSection>

        <Section title={t('CONTACT_SUPPORT')}>
          <ContactForm />
        </Section>
      </Stack>
    </DefaultPageLayout>
  );
};

export default Support;

// maps an object to a query string params
// eg { s: "hello", page: 1, otherSearch: "foo" } to ?s=hello&page=1&otherSearch=foo
export const objectToQueryStringParams = (obj: any) =>
  '?' +
  Object.keys(obj)
    .filter((key) => !!obj[key]) // only the ones that are not undefined
    .map((key) => key + '=' + obj[key])
    .join('&');

export const decodeJWTPayload = (jwtToken: string) => {
  const payloadBase64 = jwtToken.split('.')[1];
  const payloadBuff = Buffer.from(payloadBase64, 'base64');
  const payloadJSONString = payloadBuff.toString();
  return JSON.parse(payloadJSONString);
};

export const extractPrimitivesFromJsonSchema = (jsonSchema: any) => {
  const properties = jsonSchema.properties;
  const propertyNames = Object.keys(properties);

  const isPrimitiveProp = (propName: string) =>
    !(properties[propName].type === 'object') &&
    !(properties[propName].type === 'array');

  const primitivePropNames = propertyNames.filter(isPrimitiveProp);

  return { properties, primitivePropNames };
};

export const schemaWithPrimitivePropsOnly = (jsonSchema: any) => {
  const { properties, primitivePropNames } =
    extractPrimitivesFromJsonSchema(jsonSchema);

  const primitiveProps = primitivePropNames.reduce(
    (primitiveProps, propName) => {
      primitiveProps[propName] = properties[propName];

      return primitiveProps;
    },
    {} as any
  );

  // override the schema and return
  const primitivePropsSchema = { ...jsonSchema, properties: primitiveProps };
  // do some clean up of "null" fields to fix bug "required should be array"
  // TODO: fix on backend side: the inserted document should not set null to the field if it doesn't exist on creation
  if (!primitivePropsSchema.required) {
    delete primitivePropsSchema.required;
  }
  if (!primitivePropsSchema.idField) {
    delete primitivePropsSchema.idField;
  }
  return primitivePropsSchema;
};

export const excludeProps = (targetObj: any, propsToRemove: string[]) => {
  const objectClone = { ...targetObj };
  propsToRemove.forEach((propName) => {
    delete objectClone[propName];
  });
  return objectClone;
};

export const downloadRowAsJsonFile = function (
  onClickEvent: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  filePrefix: string,
  rowObject: any
) {
  // remove fake id that was used by DataGrid
  //also this clones the row object, so no mutable manipulations affect the original row
  const rowObjectClone = excludeProps(rowObject, ['id']);

  // generate the content
  const jsonFileContent = JSON.stringify(rowObjectClone, null, 4);

  // set the link
  // Note: setting the href only onClick, defers the (expensive) calculation of the jsonFileContent on every render
  const dataLink =
    'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonFileContent);
  onClickEvent.currentTarget.setAttribute('href', dataLink);

  // make it a download link and set filename
  const rowId = rowObjectClone._id;
  const timestamp = Date.now();
  const fileName = `${filePrefix}-${rowId}-${timestamp}.json`;
  onClickEvent.currentTarget.setAttribute('download', fileName);
};

export const downloadBlobAsFile = async function (
  fileName: string,
  fileContent: Blob
) {
  const hiddenDownloadAnchor = document.createElement('a');
  hiddenDownloadAnchor.setAttribute('display', 'hidden');

  const dataLink = URL.createObjectURL(fileContent);
  hiddenDownloadAnchor.setAttribute('href', dataLink);
  hiddenDownloadAnchor.setAttribute('download', fileName);

  // trigger the download
  hiddenDownloadAnchor.click();
};

export const downloadFile = async function (
  fileName: string,
  fileContent: Blob
) {
  const hiddenDownloadAnchor = document.createElement('a');
  hiddenDownloadAnchor.setAttribute('display', 'hidden');

  const fileContentString: string = await fileContent.text();

  const dataLink =
    'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContentString);
  hiddenDownloadAnchor.setAttribute('href', dataLink);
  hiddenDownloadAnchor.setAttribute('download', fileName);

  // trigger the download
  hiddenDownloadAnchor.click();
};

export const fileSizeAsHumanReadable = (fileSizeInBytes: number) => {
  const oneKiloByte = 1024;
  const oneMegaByte = oneKiloByte * oneKiloByte;

  if (fileSizeInBytes > oneMegaByte) {
    return `${Math.floor(fileSizeInBytes / oneMegaByte)} MB`;
  } else {
    const fileSizeInKB = Math.floor(fileSizeInBytes / oneKiloByte);
    const lessThanOne = '< 1'; // '0 KB' can happen yet feels misleading, thus it becomes '< 1 KB'
    return `${fileSizeInKB || lessThanOne} KB`;
  }
};

export function isEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getRuleEngineUrl() {
  // Note: 'production' here is valid for both staging AND production servers.
  if (process.env.NODE_ENV === 'production') {
    return 'https://certos-core-rule-engine.herokuapp.com/red/';
  } else {
    // process.env.NODE_ENV is either 'development' or 'test'
    return 'http://localhost:8000/red/';
  }
}

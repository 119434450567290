import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { samplePagesData } from './sampleData';

const LineChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='Simple LineChart'>
        <ResponsiveContainer width='100%' height={400}>
          <LineChart data={samplePagesData}>
            <Line type='monotone' dataKey='uv' stroke='#8884d8' dot={false} />
            <Line type='monotone' dataKey='pv' stroke='#84d888' />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </TitledCard>

      <TitledCard title='LineChart Width Grid and Axes'>
        <ResponsiveContainer width='100%' height={400}>
          <LineChart data={samplePagesData}>
            <Line type='monotone' dataKey='amt' stroke='#8884d8' />
            <CartesianGrid stroke='#ccc' baselineShift='1500' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default LineChartSamples;

import {
  Paper,
  InputBase,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

interface SearchBoxProps {
  value: string;
  placeholder: string;
  onChange: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBarRoot: {
      padding: '4px 8px',
      display: 'flex',
      alignItems: 'center',
    },
    searchBarInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    searchBarIcon: {
      margin: 10,
      color: theme.palette.grey.A700,
    },
  })
);

const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.searchBarRoot}>
      <SearchIcon className={classes.searchBarIcon} />
      <InputBase
        fullWidth
        className={classes.searchBarInput}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        data-test-id='filter-input'
      />
    </Paper>
  );
};

export default SearchBox;

import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface DialogInfoTextProps {
  infoName: string;
  infoValue: string;
  variant?: 'body1' | 'body2';
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogInfoText: {
    wordBreak: 'break-all', // used for long filenames
  },
  bold: {
    fontWeight: 'bold',
  },
}));
export const DialogInfoText: React.FC<DialogInfoTextProps> = ({
  infoName,
  infoValue,
  variant = 'body2',
}) => {
  const classes = useStyles();
  return (
    <Typography
      variant={variant}
      component='p'
      className={classes.dialogInfoText}
    >
      <span className={classes.bold}>{infoName}:</span> {infoValue}
    </Typography>
  );
};

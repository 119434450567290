import { combineReducers } from 'redux';
import { ActionTypes } from './actionCreators';
import {
  dummy_profile,
  dummy_devices,
  dummy_users,
  dummy_settings,
} from './dummy_state';

// const initialState: State = {}

const tokenReducer = (
  token: string | null = null,
  action: Action
): string | null => {
  switch (action.type) {
    case ActionTypes.SET_TOKEN:
      return action.token || null;
    case ActionTypes.REMOVE_TOKEN:
      return null;
  }
  return token;
};

const userProfileReducer = (
  userProfile: UserProfile = dummy_profile,
  action: Action
): UserProfile => {
  switch (action.type) {
    case ActionTypes.SET_USER_PROFILE:
      return { ...userProfile, ...action.data };
    case ActionTypes.REMOVE_TOKEN:
      return dummy_profile;
  }
  return userProfile;
};

const devicesReducer = (
  devices: Device[] = dummy_devices,
  action: Action
): Device[] => {
  // update devices state based on the action...
  switch (action.type) {
    case ActionTypes.SET_DEVICES:
      return action.devices;
    case ActionTypes.DEVICE_WAS_REMOVED:
      return devices.filter((d) => d._id !== action.device._id);
    case ActionTypes.REMOVE_TOKEN:
      return dummy_devices;
  }
  return devices;
};

const usersReducer = (
  users: UserProfile[] = dummy_users,
  action: Action
): UserProfile[] => {
  switch (action.type) {
    case ActionTypes.SET_USERS:
      return action.data;
    case ActionTypes.UPDATE_USER:
      return users.map((user) =>
        user._id === action.userId ? { ...user, ...action.updateInfo } : user
      );
    case ActionTypes.REMOVE_TOKEN:
      return dummy_users;
    default:
      return users;
  }
};

const auditTrailInfoReducer = (
  auditTrailInfo: AuditTrailEventsAndMeta = {
    auditTrailEvents: [],
    totalSize: 0,
  },
  action: Action
): AuditTrailEventsAndMeta => {
  switch (action.type) {
    case ActionTypes.SET_AUDIT_TRAIL_INFO:
      return action.data;
  }
  return auditTrailInfo;
};

const settingsReducer = (
  settings: CertosSettings = dummy_settings,
  action: Action
): CertosSettings => {
  switch (action.type) {
    case ActionTypes.SET_CERTOS_SETTINGS:
      return action.data;
  }
  return settings;
};

const devicesDataReducer = (
  devicesData: DevicesData = null,
  action: Action
): DevicesData => {
  switch (action.type) {
    case ActionTypes.SET_DEVICES_DATA:
      return action.data;
    case ActionTypes.ADD_DEVICE_DATA:
      return [...(devicesData ?? []), ...action.data];
    case ActionTypes.UPDATE_DEVICE_DATA:
      return (devicesData ?? []).map((deviceDataRecord) =>
        deviceDataRecord._id === action.editedSingleRecord._id
          ? { ...deviceDataRecord, ...action.editedSingleRecord }
          : deviceDataRecord
      );
    case ActionTypes.REMOVE_TOKEN:
      return null;
    default:
      return devicesData;
  }
};

const notificationsReducer = (
  notifications: UserNotification[] = [],
  action: Action
): UserNotification[] => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return action.data;
    case ActionTypes.REMOVE_TOKEN:
      return [];
  }
  return notifications;
};

const rootReducer = combineReducers<State, Action>({
  token: tokenReducer,
  userProfile: userProfileReducer,
  devices: devicesReducer,
  devicesData: devicesDataReducer,
  users: usersReducer,
  auditTrailInfo: auditTrailInfoReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
});

export default rootReducer;

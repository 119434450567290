import React from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';

const CertosAreaChart: React.FC<CertosChart> = ({
  title,
  data,
  xAxis,
  yAxis,
}) => {
  return (
    <TitledCard title={title}>
      <ResponsiveContainer width='100%' height={400}>
        <AreaChart data={data}>
          <defs>
            <linearGradient
              id='fade-out-gradient'
              x1='0%'
              y1='0%'
              x2='0%'
              y2='100%'
            >
              <stop offset='0%' stopColor='#c4a484' stopOpacity='1' />
              <stop offset='100%' stopColor='#c4a484' stopOpacity='0' />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={xAxis} />
          <YAxis dataKey={yAxis} />
          <Tooltip />
          <Legend />
          <Area
            type='monotone'
            dataKey={yAxis}
            stroke='#c4a484'
            fill='url(#fade-out-gradient)'
            strokeWidth={3}
            dot={{ r: 2 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </TitledCard>
  );
};

export default CertosAreaChart;

import { Link } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowData } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import {
  downloadRowAsJsonFile,
  extractPrimitivesFromJsonSchema,
} from '../../utils';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import getTranslation from '../../utils/getSchemaTranslation';
import { useTranslation } from 'react-i18next';

interface SubMenuTableProps {
  deviceKey: string;
  submenueName: string;
  subMenuDefinition: any; // JSON Schema
  subMenuData: any[];
}

const SubMenuTable: React.FC<SubMenuTableProps> = ({
  deviceKey,
  submenueName,
  subMenuDefinition,
  subMenuData,
}) => {
  const history = useHistory();
  const navigateToDetailPage = (row: GridRowData) =>
    history.push(
      [RouteNames.DEVICES, deviceKey, submenueName, row._id].join('/')
    );

  const bareDataGridColumns = jsonSchemaToDataGridColumns(subMenuDefinition);
  const dataGridColumns = useDataGridColumnExtensions(
    bareDataGridColumns,
    'SubMenuData_' + submenueName,
    navigateToDetailPage
  );
  const dataRows = subMenuData?.map(addIdPropFromIndex);

  return (
    <DataGrid
      columns={dataGridColumns}
      rows={dataRows}
      onRowDoubleClick={(rowParam) => {
        console.log(rowParam.row);
        navigateToDetailPage(rowParam.row);
      }}
      autoHeight
    />
  );
};

// converts a json schema object to DataGrid GridColDef[] columns
// it only takes
// * a maximum of 7
// * primitive (ie. no array or object props)
// * first layer props form the json schema
const jsonSchemaToDataGridColumns = (
  jsonSchema: SchemaObject
): GridColDef[] => {
  const { properties, primitivePropNames } =
    extractPrimitivesFromJsonSchema(jsonSchema);

  const toGridColDef = (propName: string) => {
    const propertyDef = properties[propName];
    const fallbackHeaderName = propName.toUpperCase();

    const headerName = getTranslation(propertyDef, 'title', fallbackHeaderName);
    const description = getTranslation(propertyDef, 'description', headerName); // just using the headerName as fallback for description, so user sees at least something

    return {
      field: propName,
      headerName,
      description,
      // flex: 1,
      disableClickEventBubbling: true,
      width: 200,
      // editable: jsonSchema.allowedUserActions?.updateRecord, // this doesn't work as the clicking is reserved to opening the detailView
    } as GridColDef;
  };

  // TODO/Hint: Think about formatting using GridColDef.valueFormatter
  // e.g. for Timestamps -> Local Dates (see Audit Trail Table)
  // and maybe large strings (cut to a user friendly max-size for with a '...' at the end)
  // or maybe reduce non-primitive types according to a default method (eg arrays to length)
  //    and/or extend JSON Schema to support reduction method for complex types
  const bareDataGridColumns = primitivePropNames.map(toGridColDef).slice(0, 7);
  return bareDataGridColumns;
};

const useDataGridColumnExtensions = (
  bareDataGridColumns: GridColDef[],
  filePrefix: string,
  navigateToDetailPage: (row: GridRowData) => void
): GridColDef[] => {
  const { t } = useTranslation();

  const extensions: GridColDef[] = [
    {
      field: '_details_',
      description: 'Open Details of this Audit Trail Event.',
      width: 220,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <span>{t('DETAILS')}</span>;
      },

      renderCell: (params) => {
        return (
          <Link component='a' onClick={() => navigateToDetailPage(params.row)}>
            {t('MORE')}...
          </Link>
        );
      },
    },
    {
      field: '_JSON_DOWNLOAD_', // putting some arbitrary field-name to prevent grey header background...
      headerName: 'JSON_DOWNLOAD',
      description: 'CLICK_LINK_TO_DOWNLOAD_JSON',
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <CloudDownloadOutlinedIcon />;
      },
      renderCell: (params) => {
        const handleDownloadLinkClicked = (
          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ) => downloadRowAsJsonFile(event, filePrefix, params.row);

        return (
          <Link component='a' onClick={handleDownloadLinkClicked}>
            JSON
          </Link>
        );
      },
    },
  ];

  return [...bareDataGridColumns, ...extensions];
};

const addIdPropFromIndex = (obj: object, index: number) => ({
  ...obj,
  id: index,
});

export default SubMenuTable;

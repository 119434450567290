import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '../../../shared/layouts/Stack';
import ConfigureDevice from './ConfigureDevice';
import LiveConsole from './LiveConsole';
import LiveData from './LiveData';

const RemoteAccessPage: React.FC<{ device: Device }> = ({ device }) => {
  const { t } = useTranslation();

  /*
  this was used when RemoteAccessPage was an acutal page, wrapped by <DefaultPageLayout />
  const { deviceKey } = useParams<{ deviceKey: string }>();
  const device = useSelector((state: State) =>
    state.devices.find((d) => d.key === deviceKey)
  ); */

  return (
    <Stack>
      <Typography variant='h5'>{t('REMOTE_ACCESS')}</Typography>
      <LiveData device={device!} />
      <ConfigureDevice device={device!} />
      <LiveConsole device={device!} />
    </Stack>
  );
};

export default RemoteAccessPage;

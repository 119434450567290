import React from 'react';
import {
  Typography,
  Box,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';

interface SectionProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    display: 'grid',
    maxWidth: '100%',
    gap: theme.spacing(2),
    alignContent: 'start',
  },
}));

const Section: React.FC<SectionProps> = ({ title, children }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box className={classes.section} gridGap={theme.spacing(2)}>
      <Typography variant='h5'>{title}</Typography>
      {children}
    </Box>
  );
};

export default Section;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import Stack from '../../shared/layouts/Stack';
import {
  DateRangeControls,
  useMonthlyDateRange,
} from './FilterControls/DateRangeControls';
import StringSelect from './FilterControls/StringSelect';
import { useTranslation } from 'react-i18next';

interface AuditTrailFilterControlsProps {
  filterOptions: AuditTrailEventFilter;
  handleFilterChange: (filter: AuditTrailEventFilter) => void;
  dialogOpened: boolean;
  closeDialog: () => void;
}

const AuditTrailFilterControls: React.FC<AuditTrailFilterControlsProps> = ({
  filterOptions,
  handleFilterChange,
  dialogOpened,
  closeDialog,
}) => {
  const { t } = useTranslation();

  const [userId, setUserId] = useState(filterOptions.userId || '');

  const [eventType, setEventType] = useState<string | undefined>(
    filterOptions.eventType
  );
  const [eventOutcome, setEventOutcome] = useState<
    'success' | 'failed' | undefined
  >(filterOptions.eventOutcome);

  const [message, setMessage] = useState(filterOptions.message || '');

  const { timestampStart, timestampEnd, setTimestampStart, setTimestampEnd } =
    useMonthlyDateRange({
      timestampStart: filterOptions.timestampStart,
      timestampEnd: filterOptions.timestampEnd,
    });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleFilterChange({
      userId,
      message,
      eventType,
      eventOutcome,
      timestampStart,
      timestampEnd,
    });
    closeDialog();
  };

  const resetAllFilterOptions = () => {
    setUserId('');
    setEventType(undefined);
    setEventOutcome(undefined);
    setMessage('');
    setTimestampStart(0);
    setTimestampEnd(Date.now());
  };

  return (
    <Dialog
      open={dialogOpened}
      //onBackdropClick={closeDialog}
      //onEscapeKeyDown={closeDialog}
      onClose={closeDialog}
      fullWidth
    >
      <DialogTitle>{t('FILTERS')}</DialogTitle>

      <DialogContent>
        <Stack>
          <DateRangeControls
            timestampStart={timestampStart}
            timestampEnd={timestampEnd}
            setTimestampStart={setTimestampStart}
            setTimestampEnd={setTimestampEnd}
          />
          <StringSelect
            label={t('EVENT_TYPE')}
            menuItems={[
              'ACTIVATE_USER_ACCOUNT',
              'ADD_DEVICE_DATA',
              'ADD_DEVICE_DATA_CSV_IMPORT',
              'ADD_DEVICE_SUBMENU',
              'CHANGE_USER_PASSWORD',
              'FORGOT_PASSWORD',
              'LOGIN_USER',
              'LOGOUT_USER',
              'REFRESH_TOKEN',
              'REGISTER_DEVICE',
              'REGISTER_USER',
              'REMOVE_DEVICE',
              'RESET_PASSWORD',
              'SENT_MAIL',
              'SET_CERTOS_SETTINGS',
              'UPDATE_USER_ACCESS',
              'UPDATE_USER_PROFILE',
            ]}
            currentValue={eventType}
            defaultIsNone
            handleChange={(selectedValue) => setEventType(selectedValue)}
          />
          <StringSelect
            label={t('EVENT_OUTCOME')}
            menuItems={[
              { value: 'success', display: t('SUCCESS') },
              { value: 'failed', display: t('FAILED') },
            ]}
            currentValue={eventOutcome}
            defaultIsNone
            handleChange={(selectedValue) =>
              setEventOutcome(selectedValue as 'success' | 'failed')
            }
          />
          <TextField
            label={t('SEARCH_INSIDE_MESSAGE')}
            variant='standard'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            data-test-id='user-id-filter-input'
          />
          <TextField
            label={t('USER_ID')}
            variant='standard'
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            data-test-id='user-id-filter-input'
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog}>{t('CANCEL')}</Button>
        <Button onClick={resetAllFilterOptions}>{t('RESET_ALL')}</Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleSubmit}
          data-test-id='apply-audit-trail-filter'
        >
          {t('APPLY')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditTrailFilterControls;

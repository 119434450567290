import { makeStyles } from '@material-ui/core/styles';
import React, { ImgHTMLAttributes } from 'react';
import logoWhite from '../static/logos/Certos-Landscape-White-Logo-Small.png';
import logoDark from '../static/logos/Certos-Landscape-Logo-Small.png';
import { useTranslation } from 'react-i18next';

/*
 * this component takes the logo image
 * (which is a squared image, with a "landscape" content)
 * and displays this square image as a "landscape image"
 * by using a wrapper and hiding the overlfow
 *
 */

interface LogoProps {
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  dark?: boolean;
}

const useStyles = makeStyles<any, { imgTargetHeight: number }>({
  logoWrapper: (props) => ({
    height: props.imgTargetHeight,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  }),
  logoSquareImg: (props) => ({
    minWidth: 2 * props.imgTargetHeight,
    height: 2 * props.imgTargetHeight, // overflow will be hidden
    margin: `${(-1 * props.imgTargetHeight) / 2}px 0`,
  }),
  logoAnyImg: (props) => ({
    maxHeight: props.imgTargetHeight,
  }),
});

const Logo: React.FC<LogoProps> = ({ imageProps, dark = false }) => {
  const { t } = useTranslation();

  const imgTargetHeight =
    imageProps && imageProps.height && typeof imageProps.height === 'number'
      ? imageProps.height
      : 64;
  const isSquaredImage = imageProps && imageProps.height === imageProps.width;

  const classes = useStyles({ imgTargetHeight });

  return (
    <div className={classes.logoWrapper}>
      <img
        className={isSquaredImage ? classes.logoSquareImg : classes.logoAnyImg}
        alt={t('CERTOS_LOGO')}
        src={dark ? logoDark : logoWhite}
        title={t('CERTOS_LOGO')}
        {...imageProps}
      />
    </div>
  );
};

export default Logo;

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

const UserInfoNav: React.FC = () => {
  const userProfile = useSelector((state: State) => state.userProfile);
  const theme = useTheme();

  const fullName = userProfile.firstname + ' ' + userProfile.lastname;
  const avatarInitalLetters =
    userProfile.firstname.charAt(0) + userProfile.lastname.charAt(0);
  return (
    <Box padding={2} display='grid' gridGap={theme.spacing(1)}>
      <Avatar
        src={userProfile.imgSrc}
        variant='circular'
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {avatarInitalLetters}
      </Avatar>

      <Box>
        <Typography variant='body1' style={{ fontWeight: 500 }}>
          {fullName}
        </Typography>
        <Typography variant='subtitle2'>{userProfile.email}</Typography>
        <Typography variant='caption'>
          {userProfile.role.toUpperCase()}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserInfoNav;

import React from 'react';
import { Button, ButtonGroup, SvgIconTypeMap } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export type CreateSubMenuRecordOption = 'form' | 'json';

interface CreateSubMenuRecordOptionMenuProps {
  selectedOption: CreateSubMenuRecordOption;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<CreateSubMenuRecordOption>
  >;
}

const CreateSubMenuRecordOptionMenu: React.FC<
  CreateSubMenuRecordOptionMenuProps
> = ({ selectedOption, setSelectedOption }) => {
  const createOptionButton = (
    optionName: CreateSubMenuRecordOption,
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  ) => (
    <Button
      color='default'
      variant='outlined'
      disabled={selectedOption === optionName}
      onClick={() => setSelectedOption(optionName)}
    >
      <Icon />
    </Button>
  );

  return (
    <ButtonGroup>
      {createOptionButton('form', AssignmentOutlinedIcon)}
      {createOptionButton('json', EditOutlinedIcon)}
    </ButtonGroup>
  );
};

export default CreateSubMenuRecordOptionMenu;

import {
  createStyles,
  Grid,
  Typography,
  makeStyles,
  Theme,
  Link,
} from '@material-ui/core';
import React from 'react';
import { useCompanyInfo } from '../../hooks/useCompanyInfo';
import { useCurrentLocalDateTime } from '../../hooks/useCurrentLocalDateTime';
import { RouteNames } from '../../routing/RouteNames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      // position: 'absolute',
      // bottom: 0,
      paddingLeft: 10,
      maxWidth: '100%',
      color: theme.palette.grey[700],
      background: theme.palette.background.paper,
      zIndex: 1500,
    },
    footerText: {
      fontSize: 11,
      display: 'flex',
      gap: theme.spacing(1),
    },
  })
);

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { companyName } = useCompanyInfo() || {}; // using
  const currentDateTime = useCurrentLocalDateTime();
  const version = '4.0.1';

  return (
    <Grid container direction='column' className={classes.footer}>
      <FooterText>&copy; {companyName}</FooterText>
      <FooterText>{currentDateTime}</FooterText>
      <FooterText>{t('CERTOS_VERSION', { version })}</FooterText>
      <FooterText>
        <Link href={RouteNames.IMPRINT}>{t('IMPRINT')}</Link>
        <Link href={'https://certos.ai/privacy-policy/'} target={'_blank'}>
          {t('PRIVACY_POLICY')}
        </Link>
      </FooterText>
    </Grid>
  );
};

const FooterText: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Typography className={classes.footerText} component='pre'>
      {props.children}
    </Typography>
  );
};

export default Footer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TitledCard from '../../shared/components/TitledCard';
import Stack from '../../shared/layouts/Stack';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { updateCertosSettings } from '../../api';
import { setCertosSettings } from '../../redux/actionCreators';

import { FormGroup } from '@material-ui/core';
import FormSection from '../../shared/components/FormElementsBox';
import NumberSlider from '../../shared/components/NumberSlider';
import LabeledSwitch from '../../shared/components/LabeledSwitch';
import { usePartialStateUpdate } from '../../hooks/usePartialStateUpdate';

const SecuritySettingsForm: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const certosSettings = useSelector((state: State) => state.settings);

  const [securitySettings, , updateSettings] =
    usePartialStateUpdate<SecuritySettings>(certosSettings.securitySettings);

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateCertosSettings, {
      token,
      ...certosSettings,
      securitySettings,
    });

  const handleSubmit = makeSubmitHandler({
    onSuccess: (dispatch, data) => {
      dispatch(setCertosSettings(data));
      setFeedback({
        message: t('SECURITY_SETTINGS_UPDATED'),
        msgType: 'success',
      });
    },
  });

  return (
    <TitledCard
      title={t('FDA_AND_SECURITY_SETTINGS')}
      iconAvatar={<SecurityOutlinedIcon />}
    >
      <Stack>
        <FormGroup aria-label='position'>
          <FormSection
            helpText={t('INACTIVE_USERS_WILL_BE_LOGGED_OUT', {
              logoutTimeoutMinutes: securitySettings.logoutTimeoutMinutes,
              forceReLoginHours: securitySettings.forceReLoginHours,
            })}
            showHelpText={securitySettings.automaticLogoutActive}
          >
            <LabeledSwitch
              label={t('AUTOMATIC_LOGOUT')}
              checked={securitySettings.automaticLogoutActive}
              onChange={() =>
                updateSettings({
                  automaticLogoutActive:
                    !securitySettings.automaticLogoutActive,
                })
              }
            />
            <NumberSlider
              title={t('LOGOUT_TIMEOUT_MINUTES')}
              label={(value) => `${value} ${t('MINUTES')}`}
              min={1}
              max={60}
              value={securitySettings.logoutTimeoutMinutes}
              onChange={(logoutTimeoutMinutes) =>
                updateSettings({
                  logoutTimeoutMinutes,
                })
              }
              disabled={!securitySettings.automaticLogoutActive}
            />

            <NumberSlider
              title={t('FORCE_RE_LOGIN')}
              label={(value) => `${value} ${t('HOURS')}`}
              min={1}
              max={24}
              value={securitySettings.forceReLoginHours}
              onChange={(forceReLoginHours) =>
                updateSettings({
                  forceReLoginHours,
                })
              }
              disabled={!securitySettings.automaticLogoutActive}
            />
          </FormSection>

          <FormSection helpText={t('MAXIMUM_LOGIN_RETRIES_TEXT')}>
            <NumberSlider
              title={t('LOGIN_RETRIES')}
              label={(value) => `${value} ${t('RETRIES')}`}
              min={1}
              max={10}
              value={securitySettings.maxLoginRetries}
              onChange={(maxLoginRetries) =>
                updateSettings({
                  maxLoginRetries,
                })
              }
              disabled={false}
            />
          </FormSection>

          <FormSection
            helpText={t('PASSWORD_MUST_BE_RENEWED_AFTER', {
              passwordLifespanDays: securitySettings.passwordLifespanDays,
              passwordExpWarningDays: securitySettings.passwordExpWarningDays,
            })}
            showHelpText={securitySettings.passwordExpActive}
          >
            <LabeledSwitch
              label={t('PASSWORD_EXPIRATION')}
              checked={securitySettings.passwordExpActive}
              onChange={() =>
                updateSettings({
                  passwordExpActive: !securitySettings.passwordExpActive,
                })
              }
            />

            <NumberSlider
              title={t('PASSWORD_LIFESPAN')}
              label={(value) => `${value} ${t('DAYS')}`}
              min={30}
              max={365}
              value={securitySettings.passwordLifespanDays}
              onChange={(passwordLifespanDays) =>
                updateSettings({
                  passwordLifespanDays,
                })
              }
              disabled={!securitySettings.passwordExpActive}
            />

            <NumberSlider
              title={t('PASSWORD_EXPIRATION_WARNING')}
              label={(value) => `${value} ${t('DAYS')}`}
              min={7}
              max={Math.min(30, securitySettings.passwordLifespanDays)}
              value={securitySettings.passwordExpWarningDays}
              onChange={(passwordExpWarningDays) =>
                updateSettings({
                  passwordExpWarningDays,
                })
              }
              disabled={!securitySettings.passwordExpActive}
            />
          </FormSection>

          <FormSection
            helpText={t('SUBMENU_EXPORTS_MUST_BE_SIGNED')}
            showHelpText={securitySettings.electronicSignaturesActive}
          >
            <LabeledSwitch
              label={t('ELECTRONIC_SIGNATURES')}
              checked={securitySettings.electronicSignaturesActive}
              onChange={() =>
                updateSettings({
                  electronicSignaturesActive:
                    !securitySettings.electronicSignaturesActive,
                })
              }
            />
          </FormSection>

          <FormSection
            helpText={t('ANY_VALUE_CHANGE_MUST_REASON')}
            showHelpText={securitySettings.auditReasonPromptActive}
          >
            <LabeledSwitch
              label={t('REASON_PROMPT_FOR_AUDIT_TRAIL')}
              checked={securitySettings.auditReasonPromptActive}
              onChange={() =>
                updateSettings({
                  auditReasonPromptActive:
                    !securitySettings.auditReasonPromptActive,
                })
              }
            />
          </FormSection>
        </FormGroup>

        <FormSubmitButton
          btnName={t('SAVE_CHANGES')}
          handleSubmit={handleSubmit}
          invalidInputs={false}
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

export default SecuritySettingsForm;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from '../api';
import { setAuthToken } from '../redux/actionCreators';
import { decodeJWTPayload } from '../utils';

const refreshAccessToken = (
  dispatch: DispatchType,
  callback?: (error: any) => void
) => {
  refreshToken()
    .then(({ token, ...errorData }) => {
      if (errorData.error) {
        callback && callback(errorData.error);
        return;
      }
      dispatch(setAuthToken(token));

      // silent refresh 1 minute before access token expires
      const tokenPayload = token ? decodeJWTPayload(token) : null;
      if (tokenPayload === null) {
        return;
      }

      const expInMs = tokenPayload.exp * 1000;
      const tokenValidityDurationMs = expInMs - Date.now();
      const oneMinuteMs = 60 * 1000;
      const silentRefreshAfterMs = tokenValidityDurationMs - oneMinuteMs;
      setTimeout(() => {
        refreshAccessToken(dispatch, callback);
      }, silentRefreshAfterMs);

      callback && callback(null);
    })
    .catch((e) => {
      console.log('refresh token failed', e);
    });
};

export const useAuth = (minAuthRole: UserRole = 'default_user') => {
  const token = useSelector((state: State) => state.token);
  const isAuthenticated = !!token; // set to true for designing layouts

  const [loading, setLoading] = useState(isAuthenticated ? false : true);
  const [authFailed, setAuthFailed] = useState(false);

  const dispatch = useDispatch();

  // refresh the token
  useEffect(() => {
    if (!isAuthenticated) {
      refreshAccessToken(dispatch, (error) =>
        error ? setAuthFailed(true) : setLoading(false)
      );
    }
  }, [isAuthenticated, dispatch]);

  const tokenPayload = token ? decodeJWTPayload(token) : null;
  const isAuthorized =
    tokenPayload && verifyMinAuthLevel(tokenPayload.role, minAuthRole);

  return [isAuthenticated, isAuthorized, loading, authFailed];
};

function verifyMinAuthLevel(role: UserRole, minRole: UserRole): boolean {
  switch (minRole) {
    case 'steri24_user':
      return true;
    case 'default_user':
      return (
        role === 'default_user' ||
        role === 'manufacturer' ||
        role === 'certos_admin'
      );
    case 'manufacturer':
      return role === 'manufacturer' || role === 'certos_admin';
    case 'certos_admin':
      return role === 'certos_admin';
  }
}

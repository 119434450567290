import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { dummy_profile } from '../../redux/dummy_state';
import Stack from '../../shared/layouts/Stack';
import { updateUserProfile } from '../../api/index';
import { setUserProfile } from '../../redux/actionCreators';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import TitledCard from '../../shared/components/TitledCard';
import { useEmailInputValidation } from '../../hooks/useEmailInputValidation';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useTranslation } from 'react-i18next';

interface ProfileInfoFormProps {
  profile: UserProfile;
}

const ProfileInfoForm: React.FC<ProfileInfoFormProps> = ({ profile }) => {
  const { t } = useTranslation();

  const [firstname, setFirstname] = useState(profile.firstname);
  const [lastname, setLastname] = useState(profile.lastname);
  const [email, setEmail] = useState(profile.email);

  const [emailError, emailHelperText, validateAsEmail] =
    useEmailInputValidation(email);

  const invalidInputs =
    firstname === '' || lastname === '' || email === '' || emailError === '';

  const token = useSelector((state: State) => state.token)!;

  useEffect(() => {
    if (profile !== dummy_profile) {
      setFirstname(profile.firstname);
      setLastname(profile.lastname);
      setEmail(profile.email);
    }
  }, [profile]);

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateUserProfile, {
      token,
      ...profile,
      firstname,
      lastname,
      email,
    });

  const handleSaveClick = makeSubmitHandler({
    preChecker: () => {
      const tryingProfileUpdate =
        firstname !== profile.firstname ||
        lastname !== profile.lastname ||
        email !== profile.email;

      if (!tryingProfileUpdate) {
        setFeedback({
          message: t('YOU_HAVENT_CHANGED_ANYTHING'),
          msgType: 'info',
        });
      }

      return tryingProfileUpdate;
    },
    onSuccess: (dispatch, data) => {
      // check that updated Profile matches current local state
      if (data.updatedProfile && data.updatedProfile.firstname === firstname) {
        dispatch(setUserProfile(data.updatedProfile));
        setFeedback({ message: t('PROFILE_UPDATED'), msgType: 'success' });
      } else {
        setFeedback({ message: t('ERROR_UPDATING_PROFILE'), msgType: 'error' });
      }
    },
  });

  return (
    <TitledCard title='Edit Profile' iconAvatar={<EditOutlinedIcon />}>
      <form>
        <Stack>
          <TextField
            fullWidth
            label={t('FIRSTNAME')}
            variant='standard'
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <TextField
            fullWidth
            label={t('LASTNAME')}
            variant='standard'
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <TextField
            fullWidth
            label={t('EMAIL')}
            variant='standard'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateAsEmail}
            error={emailError}
            helperText={emailHelperText}
          />

          <FormSubmitButton
            btnName={t('SAVE_CHANGES')}
            handleSubmit={handleSaveClick}
            invalidInputs={invalidInputs}
            loading={loading}
            feedback={feedback}
          />
        </Stack>
      </form>
    </TitledCard>
  );
};

export default ProfileInfoForm;

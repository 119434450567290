import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePasswordExpInfo } from '../../hooks/usePasswordExpInfo';
import FlatJsonView from '../../shared/components/FlatJsonView';
import TitledCard from '../../shared/components/TitledCard';
import Stack from '../../shared/layouts/Stack';

interface ProfileInfoProps {
  profile: UserProfile;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ profile }) => {
  const { t } = useTranslation();

  const { passwordExpiresInDays, passwordExpActive } = usePasswordExpInfo();

  const profileInfoView = {
    ...profile,
    createdOn: new Date(profile.createdOn).toLocaleString(),
  };
  return (
    <TitledCard title='Profile Information' fixedHeight={390}>
      <Stack>
        <FlatJsonView
          jsonObj={profileInfoView}
          style={{ wordBreak: 'break-all' }}
        />
        {passwordExpActive && (
          <Typography>
            {t('YOUR_PASSWORD_EXPIRES_IN_N_DAYS', {
              passwordExpiresInDays,
            })}
          </Typography>
        )}
      </Stack>
    </TitledCard>
  );
};

export default ProfileInfo;

import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  makeStyles,
  useTheme,
  createStyles,
  Theme,
  Box,
  Divider,
} from '@material-ui/core';

import { SvgIconComponent } from '@material-ui/icons';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';

import LogoutButton from '../components/LogoutButton';
import { useHistory } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import { useAuth } from '../../hooks/useAuth';
import Footer from './Footer';
import UserInfoNav from '../components/UserInfoNav';
import { useTranslation } from 'react-i18next';

interface SideBarProps {
  openOnMobile: boolean;
  handleDrawerToggle: () => void;
}

const drawerWidth = 240;
// overriding "top: 0;" so the first element appears after headerbar
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: { width: drawerWidth },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar, // necessary for content to be below app bar
    footerWrapper: {
      position: 'absolute',
      bottom: 0,
    },
  });
});

const SideBar: React.FC<SideBarProps> = ({
  openOnMobile,
  handleDrawerToggle,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const FooterWrapper = (
    <Box className={classes.footerWrapper}>
      <Footer />
    </Box>
  );

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={openOnMobile}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.paper }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContent />
          {FooterWrapper}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer classes={{ paper: classes.paper }} variant='permanent' open>
          <DrawerContent />
          {FooterWrapper}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const DrawerContent: React.FC = () => {
  const { t } = useTranslation();

  //User Management, Settings, Rule Engine & Widgets Library only visible for roles above including manufacturer
  const [, isAuthorized] = useAuth('manufacturer');
  //Steri24 users must not see Tools and Audit Trail as well
  const [, isAuthorizedForSteri24] = useAuth('default_user');

  return (
    <>
      <UserInfoNav />

      <Divider />

      <List>
        <SideBarLink
          to={RouteNames.DASHBOARD}
          text={t('DASHBOARD')}
          icon={DashboardOutlinedIcon}
        />
        <SideBarLink
          to={RouteNames.DEVICES}
          text={t('DEVICES')}
          icon={DeviceHubIcon}
        />
        {isAuthorized && (
          <SideBarLink
            to={RouteNames.USERS_MANAGEMENT}
            text={t('USERS_AND_ACCESS')}
            icon={PeopleAltOutlinedIcon}
          />
        )}
        {isAuthorizedForSteri24 && (
          <SideBarLink
            to={RouteNames.TOOLS}
            text={t('TOOLS')}
            icon={BuildOutlinedIcon}
          />
        )}
        {isAuthorizedForSteri24 && (
          <SideBarLink
            to={RouteNames.AUDIT_TRAIL}
            text={t('AUDIT_TRAIL')}
            icon={BugReportOutlinedIcon}
          />
        )}
        {isAuthorized && (
          <SideBarLink
            to={RouteNames.SETTINGS}
            text={t('SETTINGS')}
            icon={SettingsOutlinedIcon}
          />
        )}
        {isAuthorized && (
          <SideBarLink
            to={RouteNames.RULE_ENGINE}
            text={t('RULE_ENGINE')}
            icon={CodeOutlinedIcon}
          />
        )}
        <SideBarLink
          to={RouteNames.PROFILE}
          text={t('PROFILE')}
          icon={AccountBoxOutlinedIcon}
        />
        {isAuthorized && (
          <SideBarLink
            to={RouteNames.WIDGETS_LIBRARY}
            text={t('WIDGETS_LIBRARY')}
            icon={WidgetsOutlinedIcon}
          />
        )}
        <SideBarLink
          to={RouteNames.SUPPORT}
          text={t('SUPPORT')}
          icon={LiveHelpOutlinedIcon}
        />
        <LogoutButton />
      </List>
    </>
  );
};

interface SideBarLinkProps {
  to: string;
  text: string;
  icon: SvgIconComponent;
}

const SideBarLink: React.FC<SideBarLinkProps> = ({ to, text, icon }) => {
  const Icon = icon;

  const history = useHistory();
  const navigateTo = (path: string) => {
    const onClickHandler = () => {
      // Note: onClickHandler has an event param, if needed for something
      history.push(to);
    };
    return onClickHandler;
  };

  return (
    <ListItem button key={to} onClick={navigateTo(to)}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default SideBar;

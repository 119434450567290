import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface NoDataPlaceholderProps {
  noData?: boolean;
  placeholder?: {
    image: any;
    textOverlay: { title: string; subTitle: string };
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeHolderContainer: {
      maxWidth: '100%',
      maxHeight: '70vh',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    placeHolderTextOverlay: {
      /*position: 'absolute',
      top: 'calc(100% + 8px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',*/
      textAlign: 'center',
    },
  })
);

const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({
  noData,
  placeholder,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      {noData ? (
        <div className={classes.placeHolderContainer}>
          {placeholder?.image}
          <div className={classes.placeHolderTextOverlay}>
            <Typography variant='h6'>
              {placeholder?.textOverlay?.title}
            </Typography>
            <Typography variant='body1'>
              {placeholder?.textOverlay?.subTitle}
            </Typography>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default NoDataPlaceholder;

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';

const boxPlotData = [
  { name: 'day 1', min: 16, low: 28, high: 36, max: 37 },
  { name: 'day 2', min: 20, low: 25, high: 36, max: 45 },
  { name: 'day 3', min: 14, low: 28, high: 30, max: 42 },
  { name: 'day 4', min: 3, low: 6, high: 10, max: 11 },
  { name: 'day 5', min: 20, low: 23, high: 31, max: 40 },
  { name: 'day 6', min: 12, low: 28, high: 46, max: 50 },
];

const CandleStickChartCustomExample: React.FC = () => {
  return (
    <>
      <TitledCard title='CandleStickChart'>
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            width={730}
            height={250}
            data={boxPlotData}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip
              content={(props: any) => (
                <Box
                  style={{
                    border: '1px solid black',
                    background: '#fff',
                    borderRadius: 6,
                    padding: 12,
                  }}
                >
                  <Typography>Note: Tooltips can be customized too!</Typography>
                </Box>
              )}
            />
            {/* 
              Why 4 bars ? 
              * first bar is used for spacing at the bottom, that's why it's transparent and has no width (just to be sure it stays hidden)
              * second bar is used for 'Lower Wick' (aka to go from min value to low)
              * third bar is used for 'Real Body' of the CandleStick (aka to go from low to high)
              third bar is used to for the 'Upper Wick' (aka go from high to max)
            */}

            <Bar dataKey='min' stackId='a' fill='transparent' maxBarSize={0} />
            <Bar
              dataKey={(dataPoint) => dataPoint.low - dataPoint.min}
              stackId='a'
              fill='#000'
              maxBarSize={3}
            />
            <Bar
              dataKey={(dataPoint) => dataPoint.high - dataPoint.low}
              stackId='a'
              fill='#000'
              maxBarSize={30}
            />
            <Bar
              dataKey={(dataPoint) => dataPoint.max - dataPoint.high}
              stackId='a'
              fill='#000'
              maxBarSize={3}
            />
          </BarChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default CandleStickChartCustomExample;

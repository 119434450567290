import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useTranslation } from 'react-i18next';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import OpenJsonFileSection from './OpenJsonFileSection';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import { getDevicesData, jsonImportDeviceData } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import JsonPreviewSection from './JsonPreviewSection';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import { setDevicesData } from '../../../redux/actionCreators';

interface JsonImportDialogProps {
  deviceKey: string;
  subMenuDefinition: SchemaObject;
  subMenuName: string;
  pagination: number;
}

const JsonImportDialog: React.FC<JsonImportDialogProps> = ({
  deviceKey,
  subMenuDefinition,
  subMenuName,
  pagination,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [importDone, setImportDone] = useState(false);
  const [fileFeedback, setFileFeedback] = useState<Feedback>();
  const [jsonFileData, setJsonFileData] = useState<string>();
  const [jsonDataRecords, setJsonDataRecords] = useState<number>();
  const [shouldAddOrUpdate, setShouldAddOrUpdate] = useState(false);

  const [file, setFile] = useState<File>();
  const fileName = file?.name;
  const fileSize = file?.size;

  const token = useSelector((state: State) => state.token)!;
  const { loading, feedback, makeSubmitHandler, setFeedback } =
    useAsyncFormSubmit(jsonImportDeviceData, {
      token,
      jsonData: jsonFileData!,
      jsonFileName: fileName!,
      shouldUpdateDuplicateRecords: shouldAddOrUpdate,
      deviceKey,
      subMenuName,
    });

  const openImportDialog = () => setDialogOpened(true);
  const closeImportDialog = () => {
    setDialogOpened(false);
    resetFileRelatedState();
  };

  useEffect(() => {
    if (file) {
      file
        .text()
        .then((textValue: string) => {
          setJsonFileData(textValue);
          const records = JSON.parse(textValue).length;
          setJsonDataRecords(records);
        })
        .catch((error) => {
          console.log(error);
          setFileFeedback({
            msgType: 'error',
            message: t('ERROR_READING_FILE'),
          });
        });
    }
  }, [file, t]);

  const resetFileRelatedState = () => {
    setFile(undefined);
    setFileFeedback(undefined);
    setJsonFileData(undefined);

    setFeedback(null);
    setImportDone(false);
    setShouldAddOrUpdate(false);
  };

  const handleBulkWriteErrors = (data: any) => {
    // BulkWriteErrors
    const totalRecords = jsonDataRecords;
    const insertedRecords = data.result.nInserted;
    if (insertedRecords === 0) {
      return setFeedback({
        msgType: 'info',
        message: t('NOTHING_INSERTED_DUPLICATE_KEYS'),
      });
    } else {
      return setFeedback({
        msgType: 'info',
        message: t('ONLY_NUMBER_INSERTED_DUPLICATE_KEYS', {
          insertedRecords,
          totalRecords,
        }),
      });
    }
  };

  const handleResolvedBulkWriteErrors = (data: any) => {
    console.log(data);
    const { bulkWriteErrors, bulkWriteResult } = data;

    const bulkWriteUpdateWasSuccessful =
      bulkWriteResult.ok === 1 &&
      bulkWriteResult.nMatched === bulkWriteErrors.writeErrors.length;
    console.log(bulkWriteUpdateWasSuccessful);

    const nWriteErrors = bulkWriteErrors.writeErrors.length;
    const nMatched = bulkWriteResult.nMatched;
    const nModified = bulkWriteResult.nModified;

    let nInsertedProperly = 0;

    if (jsonDataRecords) {
      nInsertedProperly = jsonDataRecords - nWriteErrors;
    }

    // noRecordChanged if the bulkWrite was okay yet nothing was inserted or updated (aka the csv data is up to date)
    const noRecordChanged =
      bulkWriteResult.ok === 1 && nInsertedProperly === 0 && nModified === 0;
    if (noRecordChanged) {
      return setFeedback({
        msgType: 'info',
        message: t('IT_TURNED_OUT_THERE_WAS_NO_NEW_DATA'),
      });
    }

    // final case: some records were inserted (or maybe not), some were updated (or maybe not)
    return setFeedback({
      msgType: 'success',
      message: t('IMPORT_SUMMARY', {
        jsonDataRecords,
        nInsertedProperly,
        nMatched,
        nModified,
      }),
    });
  };

  const importJsonSubmitHandler = makeSubmitHandler({
    onSuccess: (_, data: Array<any> | any) => {
      setImportDone(true);

      const allRecordsWereImported = Array.isArray(data);
      if (allRecordsWereImported) {
        //refresh table with new data (maybe there is a better way with useEffect())
        getDevicesData({
          token: token!,
          deviceKey,
          submenueName: subMenuName,
          pagination,
        })
          .then((data) => {
            if (data.error) {
              alert(data.error);
            } else {
              dispatch(setDevicesData(data));
            }
          })
          .catch(console.log);
        return setFeedback({
          msgType: 'success',
          message: t('IMPORTED_RECORDS_SUCCESSFULLY', {
            importedRecordsCount: data.length,
          }),
        });
      }

      const isBulkWriteError = data.name && data.name === 'BulkWriteError';
      if (isBulkWriteError) {
        return handleBulkWriteErrors(data);
      }

      const isResolvedBulkWriteError =
        data.bulkWriteErrors && data.bulkWriteResult;

      if (isResolvedBulkWriteError) {
        return handleResolvedBulkWriteErrors(data);
      }

      // unknown
      return setFeedback({
        msgType: 'error',
        message: t('UNKNOWN_ERROR_JSON_FORMAT'),
      });
    },
  });

  const handleJsonFileSelected = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // reset File Feedback from any previous tries
    resetFileRelatedState();

    if (!event.target.files) {
      setFileFeedback({
        msgType: 'info',
        message: t('NOTHING_SELECTED'),
      });
      return;
    }

    const file = event.target.files[0];
    if (file && !file.name.includes('.json')) {
      setFileFeedback({
        msgType: 'error',
        message: t('FILE_NOT_A_JSON'),
      });
      return;
    }

    setFile(file);
  };

  const createRecordIsEnabled =
    subMenuDefinition.allowedUserActions &&
    subMenuDefinition.allowedUserActions.createRecord === true;

  return (
    <>
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={openImportDialog}
        disabled={!createRecordIsEnabled}
      >
        {t('IMPORT_JSON')}
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        aria-labelledby='json-import-dialog-title'
        open={dialogOpened}
      >
        <DialogTitle>
          {t('IMPORT_JSON_DEVICE_DATA_SUBMENUNAME', { subMenuName })}
        </DialogTitle>
        <DialogContent>
          <OpenJsonFileSection
            file={file}
            fileFeedback={fileFeedback}
            handleJsonFileSelected={handleJsonFileSelected}
          />
          {fileName && (
            <>
              <JsonPreviewSection
                fileName={fileName!}
                fileSize={fileSize!}
                recordCount={jsonDataRecords!}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImportDialog}>{t('CANCEL')}</Button>
          <Button
            color='primary'
            variant='contained'
            disabled={loading}
            startIcon={loading && <LoopOutlinedIcon />}
            onClick={importDone ? closeImportDialog : importJsonSubmitHandler}
          >
            {importDone ? t('DONE') : t('IMPORT')}
          </Button>
        </DialogActions>
        <DialogContent>
          {!loading && feedback && (
            <FeedbackMessage
              type={feedback.msgType}
              message={feedback.message}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JsonImportDialog;

import React from 'react';
import { useSelector } from 'react-redux';
import { postUploadImage, updateUserProfile } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { setUserProfile } from '../../redux/actionCreators';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import ImageUploader from '../../shared/components/ImageUploader';
import TitledCard from '../../shared/components/TitledCard';
import Stack from '../../shared/layouts/Stack';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import { useTranslation } from 'react-i18next';

interface ProfilePictureUploaderProps {
  profile: UserProfile;
}

const ProfilePictureUploader: React.FC<ProfilePictureUploaderProps> = ({
  profile,
}) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateUserProfile, {
      token,
      ...profile,
    });

  const handleUploadSubmit = (image: File) => {
    postUploadImage({ token, image })
      .then((result) => {
        if (result && result.imageLocation) {
          const triggerUpdateProfilePicture = makeSubmitHandler({
            paramsToSubmitHandler: {
              imgSrc: result.imageLocation,
            },
            onSuccess: (dispatch, data) => {
              dispatch(setUserProfile(data.updatedProfile));
              setFeedback({
                message: t('PROFILE_PICTURE_UPLOADED'),
                msgType: 'success',
              });
            },
          });

          triggerUpdateProfilePicture();
        }
      })
      .catch((error) => {
        console.log(error);
        setFeedback({
          message: t('ERROR_UPDATING_PROFILE_PICTURE'),
          msgType: 'error',
        });
      });
  };

  return (
    <TitledCard
      title={t('PROFILE_PICTURE')}
      iconAvatar={<FaceOutlinedIcon />}
      fixedHeight={390}
    >
      <Stack>
        <ImageUploader
          defaultPreview={profile.imgSrc}
          onUploadSubmit={handleUploadSubmit}
          profileAvatar
          variant='circular'
        />
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
      </Stack>
    </TitledCard>
  );
};

export default ProfilePictureUploader;

import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Stack from '../../../shared/layouts/Stack';
import { useTranslation } from 'react-i18next';

// ensures timestampStart is beginning of current month and timestampEnd is now (unless defaults are specified)
export const useMonthlyDateRange = (
  defaults?: Partial<{
    timestampStart: number;
    timestampEnd: number;
  }>
) => {
  const timestampStartDefault = defaults && defaults.timestampStart;
  const timestampEndDefault = defaults && defaults.timestampEnd;

  const nowTimeStamp = Date.now();
  const datetime = new Date(nowTimeStamp);
  const year = datetime.getFullYear();
  const month = datetime.getMonth();
  const startOfMonth = new Date(year, month, 1);
  const startOfMonthTimestamp = startOfMonth.getTime();

  const [timestampStart, setTimestampStart] = useState<number>(
    timestampStartDefault || startOfMonthTimestamp
  );
  const [timestampEnd, setTimestampEnd] = useState(
    timestampEndDefault || nowTimeStamp
  );
  return {
    timestampStart,
    timestampEnd,
    setTimestampStart,
    setTimestampEnd,
  };
};

/*
Note: 
  This components state is managed wherever it's called (ideally together with above useMonthlyDateRange hook)
  changes are passed up using 'setTimestampStart' and 'setTimestampEnd'
*/

interface DateRangeControlsProps {
  timestampStart: number;
  timestampEnd: number;
  setTimestampStart: React.Dispatch<React.SetStateAction<number>>;
  setTimestampEnd: React.Dispatch<React.SetStateAction<number>>;
}

export const DateRangeControls: React.FC<DateRangeControlsProps> = ({
  timestampStart,
  timestampEnd,
  setTimestampStart,
  setTimestampEnd,
}) => {
  const { t } = useTranslation();

  const handleDateInputChange = (setter: Function, dateString: string) => {
    const date = new Date(dateString);
    const timestamp = date.getTime();
    const isInvalidDate = isNaN(timestamp);
    return setter(isInvalidDate ? 0 : timestamp);
  };

  // turns 0 into '1970-01-01T00:00' and 1621337900696 into 2021-05-18T11:38 for example
  const timestampToLocalISO = (timestamp: number): string => {
    const actualDate = new Date(timestamp);
    const localOffsetInMs = actualDate.getTimezoneOffset() * 60 * 1000;
    // Note: actualDate.getTime() required for TS, JS would support date - number
    const localTimeStamp: number = actualDate.getTime() - localOffsetInMs;
    const localDate = new Date(localTimeStamp);
    return localDate.toISOString().substring(0, 16);
  };

  return (
    <Stack>
      <TextField
        label={t('START')}
        type='datetime-local'
        value={timestampToLocalISO(timestampStart)}
        onChange={(e) =>
          handleDateInputChange(setTimestampStart, e.target.value)
        }
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
      <TextField
        label={t('END')}
        type='datetime-local'
        value={timestampToLocalISO(timestampEnd)}
        onChange={(e) => handleDateInputChange(setTimestampEnd, e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
    </Stack>
  );
};

import { useEffect, useState } from 'react';

export const useCurrentLocalDateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const intervalTimer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(intervalTimer); // return a cleanup of async interval
  }, []);
  return currentTime.toLocaleString();
};

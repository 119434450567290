import { Button, makeStyles, Theme } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import React from 'react';

interface SelectedFiltersChipViewProps {
  filterOptions: AuditTrailEventFilter;
  /*
    This flag is used to differentiate between any default values inside filterOptions and values that were actually set by user (even if they match up exactly with the default values).
    For example:
    The 'timestampEnd' is set to Date.now() at some point in the initialization and thus is shown as being set in the chips (essentially because it has a valid and truthy value).
    However now with this flag it's only shown if the user intentionally selects it.
  */
  userHasPerformedFilter: boolean;
  resetOneFilterOption: (filterOptionName: string) => void;
  resetAllFilterOptions: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
  roundedButton: {
    height: 32,
    borderRadius: 16,
  },
}));

const SelectedFiltersChipView: React.FC<SelectedFiltersChipViewProps> = ({
  filterOptions,
  userHasPerformedFilter,
  resetOneFilterOption,
  resetAllFilterOptions,
}) => {
  const somethingWasSelectedByUser =
    userHasPerformedFilter &&
    ((typeof filterOptions.eventOutcome === 'string' &&
      filterOptions.eventOutcome.length > 0) ||
      (typeof filterOptions.eventType === 'string' &&
        filterOptions.eventType.length > 0) ||
      (typeof filterOptions.message === 'string' &&
        filterOptions.message.length > 0) ||
      (typeof filterOptions.userId === 'string' &&
        filterOptions.userId.length > 0) ||
      (typeof filterOptions.timestampStart === 'number' &&
        filterOptions.timestampStart > 0) ||
      (typeof filterOptions.timestampEnd === 'number' &&
        filterOptions.timestampEnd > 0));

  const classes = useStyles();
  return (
    <>
      {typeof filterOptions.timestampStart === 'number' &&
        filterOptions.timestampStart > 0 &&
        userHasPerformedFilter && (
          <Chip
            className={classes.chip}
            variant='outlined'
            label={`Start Date: ${new Date(
              filterOptions.timestampStart! as number
            ).toLocaleString()}`}
            onDelete={() => resetOneFilterOption('timestampStart')}
          />
        )}
      {typeof filterOptions.timestampEnd === 'number' &&
        filterOptions.timestampEnd > 0 &&
        userHasPerformedFilter && (
          <Chip
            className={classes.chip}
            variant='outlined'
            label={`End Date: ${new Date(
              filterOptions.timestampEnd! as number
            ).toLocaleString()}`}
            onDelete={() => resetOneFilterOption('timestampEnd')}
          />
        )}
      {filterOptions.eventType && userHasPerformedFilter && (
        <Chip
          className={classes.chip}
          variant='outlined'
          label={`Event Type: ${filterOptions.eventType}`}
          onDelete={() => resetOneFilterOption('eventType')}
        />
      )}
      {filterOptions.eventOutcome && userHasPerformedFilter && (
        <Chip
          className={classes.chip}
          variant='outlined'
          label={`Event Outcome: ${filterOptions.eventOutcome}`}
          onDelete={() => resetOneFilterOption('eventOutcome')}
        />
      )}
      {filterOptions.message && userHasPerformedFilter && (
        <Chip
          className={classes.chip}
          variant='outlined'
          label={`Message Search: ${filterOptions.message}`}
          onDelete={() => resetOneFilterOption('message')}
        />
      )}
      {filterOptions.userId && userHasPerformedFilter && (
        <Chip
          className={classes.chip}
          variant='outlined'
          label={`User ID: ${filterOptions.userId}`}
          onDelete={() => resetOneFilterOption('userId')}
        />
      )}
      {somethingWasSelectedByUser && (
        <Button
          onClick={resetAllFilterOptions}
          variant='contained'
          color='primary'
          className={classes.roundedButton}
        >
          Reset All
        </Button>
      )}
    </>
  );
};

export default SelectedFiltersChipView;

import { useLocation } from 'react-router';

function useQuery() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams;
}

const valOrUndefined = (value: string | null) =>
  typeof value === 'string' ? value : undefined;
const asNumberOrDefault = (strVal: string | undefined, defaultVal: number) => {
  const parsedVal = parseInt(strVal || '');
  return isNaN(parsedVal) ? defaultVal : parsedVal;
};

export function useAuditTrailFilterQuery() {
  const queryParams = useQuery();

  // Note: disabling pagination and pagesize for now, as MUI DataGrid seems to not support external pagination params!
  // Perhaps it's even a good thing not to include pagination because when I share a link you get the full data from the beginning...
  // If we wanted to share a specific even then the auditTrail DetailPage can handle this... (needs clarification)

  // const paginationString = valOrUndefined(queryParams.get('pagination'));
  // const pageSizeString = valOrUndefined(queryParams.get('pageSize'));
  const filterOptionStrings: Partial<{
    _id: string;
    userId: string;
    eventType: string;
    eventOutcome: string;
    message: string;
    timestampEnd: string;
    timestampStart: string;
  }> = {
    _id: valOrUndefined(queryParams.get('_id')),
    userId: valOrUndefined(queryParams.get('userId')),
    eventType: valOrUndefined(queryParams.get('eventType')),
    eventOutcome: valOrUndefined(queryParams.get('eventOutcome')),
    message: valOrUndefined(queryParams.get('message')),
    timestampEnd: valOrUndefined(queryParams.get('timestampEnd')),
    timestampStart: valOrUndefined(queryParams.get('timestampStart')),
  };

  const sortModelStrings: Partial<{
    sortBy: string;
    sortOrder: string;
  }> = {
    sortBy: valOrUndefined(queryParams.get('sortBy')),
    sortOrder: valOrUndefined(queryParams.get('sortOrder')),
  };

  // Convert
  // const pagination: number = asNumberOrDefault(paginationString, 1);
  // const pageSize: number = asNumberOrDefault(pageSizeString, 25);
  const filterOptions: AuditTrailEventFilter = {
    // map all strings as is
    ...filterOptionStrings,
    // but override integers and custom types
    eventOutcome:
      filterOptionStrings.eventOutcome &&
      ['success', 'failed'].includes(filterOptionStrings.eventOutcome)
        ? (filterOptionStrings.eventOutcome as 'success' | 'failed')
        : undefined,
    timestampStart: asNumberOrDefault(filterOptionStrings.timestampStart, 0),
    timestampEnd: asNumberOrDefault(
      filterOptionStrings.timestampEnd,
      Date.now()
    ),
  };

  const sortModel: SortModel = {
    sortBy: sortModelStrings.sortBy,
    sortOrder:
      sortModelStrings.sortOrder &&
      ['success', 'failed'].includes(sortModelStrings.sortOrder)
        ? (sortModelStrings.sortOrder as 'asc' | 'desc')
        : undefined,
  };

  return { /*pagination, pageSize,*/ filterOptions, sortModel };
}

import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridSortItem,
  GridValueFormatterParams,
  GridRowParams,
  GridRowData,
} from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';
import { RouteNames } from '../../routing/RouteNames';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';

const useColumns = (navigateToDetailPage: (rowParam: GridRowData) => void) => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('ID'), width: 70, hide: true },
    {
      field: 'timestamp',
      headerName: t('DATE'),
      type: 'string',
      valueFormatter: (params: GridValueFormatterParams) => {
        const timestamp: number = params
          .getValue(params.id, 'timestamp')
          ?.valueOf() as number;
        const dateObj = new Date(timestamp);
        const prettyDate = dateObj.toLocaleString();
        return prettyDate;
      },
      width: 160,
    },
    { field: 'eventType', headerName: t('EVENT_TYPE'), width: 200 },
    { field: 'eventOutcome', headerName: t('EVENT_OUTCOME'), width: 100 },
    { field: 'message', headerName: t('MESSAGE'), width: 500 },
    {
      field: 'userId',
      headerName: t('USER_ID'),
      description: 'The User that initiated this event.',
      width: 220,
    },
    {
      field: 'comment',
      headerName: t('COMMENT'),
      description: 'A comment on the reason for this event.',
      width: 400,
    },
    {
      field: '_details_', // putting '_details_' in there because if the field is empty it becomes grey...
      description: 'Open Details of this Audit Trail Event.',
      width: 220,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <span>{t('DETAILS')}</span>;
      },

      renderCell: (params) => {
        return (
          <Link component='a' onClick={() => navigateToDetailPage(params.row)}>
            {t('MORE')}...
          </Link>
        );
      },
    },
  ];

  return columns;
};

interface AuditTrailTableProps {
  totalSize: number;
  auditTrailEvents: AuditTrailEvent[] | null;
  handleSort: (sortModel: SortModel) => void;
  handlePagination: ({
    pagination,
    pageSize,
  }: {
    pagination?: number;
    pageSize?: number;
  }) => void;
}

const AuditTrailTable: React.FC<AuditTrailTableProps> = ({
  totalSize,
  auditTrailEvents,
  handleSort,
  handlePagination,
}) => {
  const history = useHistory();
  const navigateToDetailPage = (row: GridRowData) =>
    history.push([RouteNames.AUDIT_TRAIL, row._id].join('/'));

  const columns = useColumns(navigateToDetailPage);

  const dataGridRows = auditTrailEvents
    ? auditTrailEvents.map((auditTrailEvent, index) => ({
        id: index, // each element of DatGrid needs a unique 'id'
        ...auditTrailEvent,
      }))
    : [];

  // Note: when auditTrailEvents is null it means still loading
  const waitingForData = auditTrailEvents === null;

  const handlePaginationChange = ({
    pagination,
    pageSize,
  }: {
    pagination?: number;
    pageSize?: number;
  }) =>
    handlePagination({
      pagination,
      pageSize,
    });

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const { field, sort } = (sortModel &&
      sortModel.length > 0 &&
      sortModel[0]) as GridSortItem;

    const sortBy = field;
    const sortOrder = (
      sort && ['asc', 'desc'].includes(sort.toString()) ? sort : undefined
    ) as 'asc' | 'desc' | undefined;

    handleSort({ sortBy, sortOrder });
  };

  return (
    <DataGrid
      rows={dataGridRows}
      columns={columns}
      autoHeight
      disableColumnMenu
      onSortModelChange={handleSortModelChange}
      sortingMode='server'
      onRowDoubleClick={(rowParam: GridRowParams) =>
        navigateToDetailPage(rowParam.row)
      }
      rowCount={totalSize}
      pageSize={Math.min(dataGridRows.length, 25)} // initial pageSize (25) will be changed by onPageSizeChange
      paginationMode='server'
      onPageChange={(pagination: number) =>
        handlePaginationChange({ pagination })
      }
      onPageSizeChange={(pageSize: number) =>
        handlePaginationChange({ pageSize })
      }
      loading={waitingForData}
    />
  );
};

export default AuditTrailTable;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Button,
} from '@material-ui/core';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { updateUserAccess } from '../../api';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import Stack from '../../shared/layouts/Stack';
import { updateUser } from '../../redux/actionCreators';
import UserRoleDropDown from './UserRoleDropDown';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface UserActionsProps {
  user: UserProfile;
}

const UserActions: React.FC<UserActionsProps> = ({ user }) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState<string>();
  const [isBlocked, setIsBlocked] = useState(user.isBlocked);
  const [isPremiumUser, setIsPremiumUser] = useState(user.isPremium);

  const [, isCertosAdmin] = useAuth('certos_admin');
  const [userRole, setUserRole] = useState(user.role);

  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateUserAccess, {
      token,
      userId: user._id,
      isBlocked,
      isPremium: isPremiumUser,
      passwordReset: password,
      userRole,
    });

  const handleChange = makeSubmitHandler({
    onSuccess: (dispatch) => {
      const updateUserAction = updateUser(user._id, {
        isBlocked,
        isPremium: isPremiumUser,
        role: userRole,
      });
      dispatch(updateUserAction);
      setFeedback({
        message: t('USER_ACCESS_INFORMATION_UPDATED'),
        msgType: 'success',
      });
    },
  });

  return (
    <Stack>
      <FormGroup aria-label='position'>
        <FormControl component='fieldset'>
          <FormControlLabel
            value='start'
            control={
              <Switch
                color='primary'
                checked={isPremiumUser}
                onChange={() => setIsPremiumUser(!isPremiumUser)}
              />
            }
            label={t('PREMIUM_USER')}
            labelPlacement='end'
            disabled={isBlocked}
          />
        </FormControl>
        <FormControl component='fieldset'>
          <FormControlLabel
            value='start'
            control={
              <Switch
                color='primary'
                checked={isBlocked}
                onChange={() => setIsBlocked(!isBlocked)}
              />
            }
            label={t('BLOCKED')}
            labelPlacement='end'
          />
        </FormControl>
      </FormGroup>

      {isCertosAdmin && (
        <UserRoleDropDown
          selectedRole={userRole}
          onChange={(selection) => setUserRole(selection)}
        />
      )}

      <FormGroup aria-label='position'>
        <TextField
          fullWidth
          label={t('SET_A_NEW_PASSWORD')}
          variant='standard'
          color='secondary'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormGroup>
      <Button
        variant='contained'
        color='primary'
        onClick={handleChange}
        disabled={loading}
      >
        {t('SAVE_CHANGES')}
      </Button>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
    </Stack>
  );
};

export default UserActions;

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';

interface IconCardProps {
  icon: React.ReactElement;
  title: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 260,
    height: 160,
  },
  iconBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& svg': {
      width: 60,
      height: 100,
    },
  },
}));

const IconCard: React.FC<IconCardProps> = ({ icon, title, onClick }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} onClick={onClick}>
      <CardContent>
        <Box className={classes.iconBox}>{icon}</Box>
        <Typography variant='h6' align='center'>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default IconCard;

import { Typography, DialogContent, Box } from '@material-ui/core';
import React from 'react';
import { fileSizeAsHumanReadable } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { DialogInfoText } from '../../../shared/components/DialogInfoText';

interface CsvPreviewSectionProps {
  fileName: string;
  fileSize: number;
  recordCount: number;
  csvPreview: string;
}

const CsvPreviewSection: React.FC<CsvPreviewSectionProps> = ({
  fileName,
  fileSize,
  recordCount,
  csvPreview,
}) => {
  const { t } = useTranslation();

  return (
    <Box marginTop='1rem'>
      <Typography variant='h6'>{t('STEP_2_PREVIEW_DEVICE_DATA')}</Typography>
      <DialogContent>
        {fileName && (
          <DialogInfoText infoName={t('FILE_NAME')} infoValue={fileName} />
        )}
        {fileSize && (
          <DialogInfoText
            infoName='File size'
            infoValue={fileSizeAsHumanReadable(fileSize)}
          />
        )}
        {recordCount >= 0 && (
          <DialogInfoText
            infoName={t('TOTAL_RECORD_COUNT')}
            infoValue={`${recordCount} records`}
          />
        )}
      </DialogContent>
      <DialogInfoText
        infoName={t('PREVIEW_OF_FIRST_10_LINES')}
        infoValue=''
        variant='body1'
      />
      <DialogContent>
        {/* Note: The <pre>{csvPreview}</pre> is wrapped in a sub-DialogContent 
          because in case of text-overflow the horizontal scroll bar affects only this section. */}
        <pre style={{ margin: 0 }}>{csvPreview}</pre>
      </DialogContent>
    </Box>
  );
};

export default CsvPreviewSection;

import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

const TwoColumnResponsiveGrid: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.grid}>{children}</div>;
};

export default TwoColumnResponsiveGrid;

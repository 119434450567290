import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

interface NotificationProps {
  notification: UserNotification;
}

const NotificationsListItem: React.FC<NotificationProps> = ({
  notification,
}) => {
  return (
    <ListItem dense alignItems='center' button divider>
      <ListItemText
        primary={notification.header}
        secondary={notification.body}
      />
    </ListItem>
  );
};

export default NotificationsListItem;

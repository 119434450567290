import React, { useEffect, useState } from 'react';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import { getAuditTrail } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import AuditTrailTable from './AuditTrailTable';
import AuditTrailFilterControls from './AuditTrailFilterControls';
import Stack from '../../shared/layouts/Stack';
import { Box, Button } from '@material-ui/core';
import { useAuditTrailFilterQuery } from '../../hooks/useAuditTrailFilterQuery';
import { useHistory } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import { objectToQueryStringParams } from '../../utils';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import SelectedFiltersChipView from './SelectedFiltersChipView';
import { useTranslation } from 'react-i18next';
import { setAuditTrailInfo } from '../../redux/actionCreators';
import CsvExportButton from './CsvExportButton';

const AuditTrailPage: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;

  const history = useHistory();
  const query = useAuditTrailFilterQuery();

  const [userHasPerformedFilter, setUserHasPerformedFilter] = useState(false);

  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterOptions, setFilterOptions] = useState<AuditTrailEventFilter>(
    query.filterOptions
  );

  const [auditTrailSortModel, setAuditTrailSortModel] = useState<SortModel>(
    query.sortModel
  );

  const { auditTrailEvents, totalSize } = useSelector(
    (state: State) => state.auditTrailInfo
  );

  const [dialogOpened, setDialogOpened] = useState(false);
  const closeDialog = () => setDialogOpened(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: implement loading & progress effect! // setLoading(true)
    getAuditTrail({
      token,
      pagination,
      pageSize,
      auditTrailEventFilter: filterOptions,
      auditTrailSortModel,
    })
      .then(({ auditTrailEvents, totalSize }) => {
        // setAuditTrailEvents(auditTrailEvents);
        // setTotalSize(totalSize);
        dispatch(setAuditTrailInfo({ auditTrailEvents, totalSize }));
      })
      .catch((error) => console.log(error));
  }, [
    token,
    pagination,
    pageSize,
    filterOptions,
    auditTrailSortModel,
    dispatch,
  ]);

  const handlePagination = ({
    pagination,
    pageSize,
  }: {
    pagination?: number;
    pageSize?: number;
  }) => {
    pagination && setPagination(pagination);
    pageSize && setPageSize(pageSize);
  };

  const updateSharableUrl = (
    filter: AuditTrailEventFilter,
    sortModel: SortModel
  ) => {
    // set the new url, in case the user want's to share it...
    const filterAndSortOptionsAsQueryString = objectToQueryStringParams({
      ...filter,
      ...sortModel,
    });
    history.replace({
      pathname: RouteNames.AUDIT_TRAIL,
      search: filterAndSortOptionsAsQueryString,
    });
  };

  const handleSort = (sortModel: SortModel) => {
    setAuditTrailSortModel(sortModel);
    updateSharableUrl(filterOptions, sortModel);
  };

  const handleFilterChange = (filter: AuditTrailEventFilter) => {
    setFilterOptions(filter);
    setUserHasPerformedFilter(true); // set to true so the chips are shown because of intentional filters
    updateSharableUrl(filter, auditTrailSortModel);
  };

  const resetOneFilterOption = (filterOptionName: string) => {
    let newFilterOptions = { ...filterOptions };
    delete newFilterOptions[
      filterOptionName as
        | 'userId'
        | 'eventType'
        | 'eventOutcome'
        | 'message'
        | 'timestampStart'
        | 'timestampEnd'
    ];
    handleFilterChange(newFilterOptions);
  };
  const resetAllFilterOptions = () => {
    handleFilterChange({});
  };
  return (
    <DefaultPageLayout title={t('AUDIT_TRAIL')}>
      <Stack>
        <Box display='flex' justifyContent='space-between'>
          <Button
            startIcon={<TuneOutlinedIcon />}
            onClick={() => setDialogOpened(true)}
          >
            {t('FILTERS')}
          </Button>
          <CsvExportButton />
        </Box>
        <SelectedFiltersChipView
          filterOptions={filterOptions}
          userHasPerformedFilter={userHasPerformedFilter}
          resetOneFilterOption={resetOneFilterOption}
          resetAllFilterOptions={resetAllFilterOptions}
        />
        <AuditTrailTable
          auditTrailEvents={auditTrailEvents}
          totalSize={totalSize}
          handleSort={handleSort}
          handlePagination={handlePagination}
        />
      </Stack>
      <AuditTrailFilterControls
        filterOptions={filterOptions}
        handleFilterChange={handleFilterChange}
        dialogOpened={dialogOpened}
        closeDialog={closeDialog}
      />
    </DefaultPageLayout>
  );
};

export default AuditTrailPage;

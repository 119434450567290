import { Button } from '@material-ui/core';
import React from 'react';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { csvExportAuditTrail } from '../../api';
import { useSelector } from 'react-redux';
import { downloadFile } from '../../utils';
import { useTranslation } from 'react-i18next';

const CsvExportButton: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const { loading, feedback, makeSubmitHandler } = useAsyncFormSubmit(
    csvExportAuditTrail,
    { token }
  );

  const exportCsvSubmitHandler = makeSubmitHandler({
    onSuccess: (_, data: Blob) => {
      const exportFileName = `Audit_Trail_${Date.now()}.csv`;
      downloadFile(exportFileName, data);
    },
  });
  return (
    <>
      <Button
        variant='text'
        color='default'
        onClick={exportCsvSubmitHandler}
        startIcon={<SystemUpdateAltOutlinedIcon />}
      >
        {t('EXPORT_CSV')}
      </Button>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
    </>
  );
};

export default CsvExportButton;

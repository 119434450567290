import { Button, Typography, Box, DialogContentText } from '@material-ui/core';
import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import { useTranslation } from 'react-i18next';

interface OpenCsvFileSectionProps {
  file?: File;
  fileFeedback?: Feedback;
  handleCsvFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OpenCsvFileSection: React.FC<OpenCsvFileSectionProps> = ({
  file,
  fileFeedback,
  handleCsvFileSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant='h6'>{t('STEP_1_OPEN_CSV_FILE')}</Typography>
      <DialogContentText>{t('CHOOSE_A_VALID_CSV_FILE')}</DialogContentText>

      {fileFeedback && (
        <FeedbackMessage
          type={fileFeedback.msgType}
          message={fileFeedback.message}
        />
      )}
      <Button
        component='label'
        variant='contained'
        startIcon={<InsertDriveFileOutlinedIcon />}
      >
        {file ? t('CHOOSE_ANOTHER_FILE') : t('OPEN_CSV_FILE')}
        <input
          type='file'
          accept='.csv'
          hidden
          onChange={handleCsvFileSelected}
        />
      </Button>
    </Box>
  );
};

export default OpenCsvFileSection;

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Section from './Section';

const useStyles = makeStyles((theme: Theme) => ({
  sectionContent: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));

const IconCardSection: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Section title={title}>
      <Box className={classes.sectionContent}>{children}</Box>
    </Section>
  );
};

export default IconCardSection;

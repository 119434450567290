import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Bar,
  Line,
  ComposedChart,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { samplePagesData } from './sampleData';

const ComposedChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='Composed Chart'>
        <ResponsiveContainer width='100%' height={400}>
          <ComposedChart width={730} height={250} data={samplePagesData}>
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke='#f5f5f5' />
            <Area
              type='monotone'
              dataKey='amt'
              fill='#8884d8'
              stroke='#8884d8'
            />
            <Bar dataKey='pv' barSize={20} fill='#413ea0' />
            <Line type='monotone' dataKey='uv' stroke='#ff7300' />
          </ComposedChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default ComposedChartSamples;

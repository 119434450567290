import { useInputValidation } from './useInputValidation';

const tooShort = (password: string) => password.length < 8;
const noCapitalLetters = (password: string) => !password.match(/[A-Z]/g);
const noNumbers = (password: string) => !password.match(/\d/g);
//const noSymbols = (password: string) => !password.match(/[^\w]/g);

const passWordValidations = [
  {
    errorCase: tooShort,
    helperText: 'Password must be at least 8 characters.',
  },
  {
    errorCase: noCapitalLetters,
    helperText: 'Password must contain capital letters.',
  },
  {
    errorCase: noNumbers,
    helperText: 'Password must contain a digit.',
  },
  /* {
    errorCase: noSymbols,
    helperText: 'Password must contain symbols.',
  },*/
];

export const usePasswordInputValidation = (password: string) => {
  return useInputValidation<string>(password, passWordValidations);
};

import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { samplePagesData } from './sampleData';

const AreaChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='AreaChart'>
        <ResponsiveContainer width='100%' height={400}>
          <AreaChart
            data={samplePagesData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey='name' />
            <YAxis />
            <CartesianGrid strokeDasharray='3 3' />
            <Legend verticalAlign='top' height={36} />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='uv'
              stroke='#8884d8'
              fillOpacity={1}
              fill='url(#colorUv)'
            />
            <Area
              type='monotone'
              dataKey='pv'
              stroke='#82ca9d'
              fillOpacity={1}
              fill='url(#colorPv)'
            />
          </AreaChart>
        </ResponsiveContainer>
      </TitledCard>

      <TitledCard title='Synced AreaChart'>
        <p>Step Graph</p>
        <ResponsiveContainer width='100%' height={200}>
          <AreaChart
            width={500}
            height={200}
            data={samplePagesData}
            syncId='anyId'
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Area type='step' dataKey='uv' stroke='#8884d8' fill='#000C6F' />
          </AreaChart>
        </ResponsiveContainer>
        <p>Maybe some other content</p>
        <ResponsiveContainer width='100%' height={200}>
          <AreaChart
            width={500}
            height={200}
            data={samplePagesData}
            syncId='anyId'
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='pv'
              stroke='#82ca9d'
              fill='#82ca9d'
            />
          </AreaChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default AreaChartSamples;

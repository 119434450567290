import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import DeviceList from '../../shared/components/DeviceList';
import { Box, Button, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { useFilter } from '../../hooks/useFilter';
import SearchBox from '../../shared/components/SearchBox';
import DevicesGridView from '../../shared/components/DevicesGridView';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import Stack from '../../shared/layouts/Stack';
import { ReactComponent as NoDevices } from '../../shared/static/placeholders/NoDeviceMan.svg';
import NoDataPlaceholder from '../../shared/layouts/NoDataPlaceholder';
import { useTranslation } from 'react-i18next';

const Devices: React.FC = () => {
  const { t } = useTranslation();

  const [view, setView] = useState<'grid' | 'list'>('grid');

  const [filter, setFilter] = useState('');
  const devices = useSelector((state: State) => state.devices);
  const filteredDevices = useFilter(devices, filter);

  const [myOwn, setMyOwn] = useState(false);
  const userProfile = useSelector((state: State) => state.userProfile);
  const myOwnDevices = filteredDevices.filter((device) =>
    userProfile.devices.includes((device as Device)._id)
  );

  const devicesToShow: Device[] = myOwn ? myOwnDevices : filteredDevices;

  const onSearchBoxChange = (e: any) => setFilter(e.target.value);
  return (
    <DefaultPageLayout title={t('DEVICES')}>
      <NoDataPlaceholder
        noData={!devices || devices.length === 0}
        placeholder={{
          image: <NoDevices />,
          textOverlay: {
            title: t('NO_DEVICES_REGISTERED_YET'),
            subTitle: t('NO_DEVICES_CTA'),
          },
        }}
      >
        <Stack spacing={1}>
          <Grid container justifyContent='space-between' spacing={1}>
            <Grid item xs>
              <Box hidden={userProfile.role === 'default_user'}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={myOwn}
                      onChange={() => setMyOwn(!myOwn)}
                      color='primary'
                    />
                  }
                  label={t('SHOW_ONLY_MY_DEVICES')}
                />
              </Box>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
                startIcon={
                  view === 'list' ? (
                    <GridOnOutlinedIcon />
                  ) : (
                    <ListAltOutlinedIcon />
                  )
                }
              >
                {view === 'list' ? t('GRID_VIEW') : t('LIST_VIEW')}
              </Button>
            </Grid>
          </Grid>

          <SearchBox
            value={filter}
            placeholder={t('SEARCH_DEVICES')}
            onChange={onSearchBoxChange}
          />

          {view === 'grid' ? (
            <DevicesGridView devices={devicesToShow} />
          ) : (
            <DeviceList devices={devicesToShow} />
          )}
        </Stack>
      </NoDataPlaceholder>
    </DefaultPageLayout>
  );
};

export default Devices;

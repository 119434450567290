import React from 'react';
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconSize: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    imgSize: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    bordered: {
      border: `1px solid ${theme.palette.divider}`,
    },
    rounded: {
      borderRadius: theme.shape.borderRadius,
    },
    circle: {
      borderRadius: '50%',
    },
  })
);

interface LargeAvatarProps {
  src: string;
  variant?: 'circular' | 'rounded' | 'square';
  bordered?: boolean;
  profileAvatar?: boolean; // used to display profile pic if img was not found, else uses a broken image
}

const LargeAvatar: React.FC<LargeAvatarProps> = ({
  src,
  variant = 'rounded',
  bordered = false,
  profileAvatar = false,
}) => {
  const classes = useStyles();
  return (
    <Avatar
      src={src}
      variant={variant}
      className={[classes.imgSize, bordered && classes.bordered].join(' ')}
    >
      {profileAvatar ? (
        <PersonOutlineOutlinedIcon className={classes.iconSize} />
      ) : (
        <BrokenImageOutlinedIcon className={classes.iconSize} />
      )}
    </Avatar>
  );
};

export default LargeAvatar;

const toFlatStringArray = (object: any): any =>
  object
    ? Object.values(object)
        .map((value) => {
          if (typeof value !== 'object') {
            // return a primitive value as a string
            return (value as object).toString();
          } else if (Array.isArray(value)) {
            // map each value of array
            return value.map(toFlatStringArray);
          } else {
            // perform recursively on subdocument
            return toFlatStringArray(value);
          }
        })
        .filter((e) => e !== null)
        .flat(Infinity)
    : [];

const search = (object: any, filter: string) =>
  toFlatStringArray(object).some((e: any) =>
    typeof e === 'string'
      ? (e as string).toLowerCase().includes(filter.toLowerCase())
      : false
  );

export const useFilter = (data: any[], filterString: string) => {
  return data.filter((d) => search(d, filterString));
};

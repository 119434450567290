import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import ThemeSettings from './ThemeSettings';
import CompanyInfoSettings from './CompanyInfoSettings';
import SecuritySettingsForm from './SecuritySettingsForm';

import LogoUploader from './LogoUploader';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultPageLayout title={t('SETTINGS')}>
      <Stack>
        <Stack fluid>
          <LogoUploader />
          <ThemeSettings />
        </Stack>
        <Stack fluid>
          <SecuritySettingsForm />
          <CompanyInfoSettings />
        </Stack>
      </Stack>
    </DefaultPageLayout>
  );
};

export default SettingsPage;

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import { HeaderBar } from './HeaderBar';
import SideBar from './SideBar';

interface GenericPageProps {
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar, // necessary for content to be below app bar
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        left: 240,
        right: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  })
);

const DefaultPageLayout: React.FC<GenericPageProps> = (props) => {
  const classes = useStyles();
  const [mobileSideBarOpen, setMobileSideBarOpen] = useState(false);

  const handleDrawerToggle = () => setMobileSideBarOpen(!mobileSideBarOpen);

  return (
    <Grid container direction='row'>
      <HeaderBar handleDrawerToggle={handleDrawerToggle} />
      <SideBar
        openOnMobile={mobileSideBarOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Breadcrumbs />
        <Typography variant='h4' component='h1' className={classes.title}>
          {props.title || ''}
        </Typography>
        {props.children}
      </div>
    </Grid>
  );
};

export default DefaultPageLayout;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useInactivityCountdownTimer = (countDownMinutes: number) => {
  const INITIAL_SECONDS = countDownMinutes * 60;
  const { countdown, resetCountdown } = useCountdownSeconds(INITIAL_SECONDS);
  const displayTime = useMemoizedDisplayTime(countdown);
  const lastMouseEvent = useTrackMouseMoves();
  const isInactive = !useMouseIsActive(lastMouseEvent!);

  useEffect(() => {
    resetCountdown();
  }, [lastMouseEvent, resetCountdown]);
  return { isInactive, countdown, displayTime };
};

const useCountdownSeconds = (countdownStart: number) => {
  const [countdown, setCountdown] = useState(countdownStart);

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, []);

  const resetCountdown = useCallback(() => {
    setCountdown(countdownStart);
  }, [countdownStart]);

  return { countdown, resetCountdown };
};

const useMemoizedDisplayTime = (timeLeftSeconds: number) => {
  // Note: via useMemo, displayTime is only re-calculated if the timeLeftSeconds actually changes...
  const displayTime = useMemo(() => {
    if (timeLeftSeconds > 0) {
      const wholeMinutesLeft = Math.floor(timeLeftSeconds / 60);
      const secondsLeft = timeLeftSeconds % 60;

      // turn a number into it's a two digit format
      // Note: 0 -> 00 and 59 -> 59 and 591 -> 91
      const $$ = (num: number) => ('0' + num).slice(-2);

      return `${$$(wholeMinutesLeft)}:${$$(secondsLeft)}`;
    } else return '00:00';
  }, [timeLeftSeconds]);

  return displayTime;
};

const useTrackMouseMoves = (): MouseEvent | undefined => {
  const [lastMouseEvent, setLastMouseEvent] = useState<MouseEvent>();

  useEffect(() => {
    const mouseOverEventListener = (mouseEvent: MouseEvent) => {
      setLastMouseEvent(mouseEvent);
    };

    document.addEventListener('mouseover', mouseOverEventListener);

    return () =>
      document.removeEventListener('mouseover', mouseOverEventListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lastMouseEvent;
};

const useMouseIsActive = (lastMouseMove: MouseEvent) => {
  // assume user is inactive, until the mouse moves
  const [isActive, setIsActive] = useState(false);

  const lastActivityTimeout = useRef<NodeJS.Timeout>();
  const setInactiveInASecond = () => {
    // remove previous timeout and re-start counting two seconds
    clearTimeout(lastActivityTimeout.current!);
    lastActivityTimeout.current = setTimeout(() => {
      setIsActive(false);
    }, 1000);
  };

  useEffect(() => {
    // user is active because mouse just moved
    setIsActive(true);
    setInactiveInASecond();
  }, [lastMouseMove]);

  // Clear timeout when component unmounts
  // Note: this CANNOT be used in the useEffect above
  // because the return callback is executed on every useEffect execution!
  useEffect(() => {
    return () => clearTimeout(lastActivityTimeout.current!);
  }, []);

  return isActive;
};

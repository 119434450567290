import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RouteNames } from '../../routing/RouteNames';
import { logoutUser } from '../../api';
import { removeAuthToken } from '../../redux/actionCreators';
import { useTranslation } from 'react-i18next';

const LogoutButton: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    logoutUser().then(() => {
      dispatch(removeAuthToken());
      history.push(RouteNames.LOGIN);
    });
  };
  return (
    <ListItem button onClick={logoutHandler}>
      <ListItemIcon>
        <ExitToAppOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={t('LOGOUT')} />
    </ListItem>
  );
};

export default LogoutButton;

import { Typography, DialogContent, Box } from '@material-ui/core';
import React from 'react';
import { fileSizeAsHumanReadable } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { DialogInfoText } from '../../../shared/components/DialogInfoText';

interface JsonPreviewSectionProps {
  fileName: string;
  fileSize: number;
  recordCount: number;
}

const JsonPreviewSection: React.FC<JsonPreviewSectionProps> = ({
  fileName,
  fileSize,
  recordCount,
}) => {
  const { t } = useTranslation();

  return (
    <Box marginTop='1rem'>
      <Typography variant='h6'>{t('STEP_2_PREVIEW_DEVICE_DATA')}</Typography>
      <DialogContent>
        {fileName && (
          <DialogInfoText infoName={t('FILE_NAME')} infoValue={fileName} />
        )}
        {fileSize && (
          <DialogInfoText
            infoName='File size'
            infoValue={fileSizeAsHumanReadable(fileSize)}
          />
        )}
        {recordCount >= 0 && (
          <DialogInfoText
            infoName={t('TOTAL_RECORD_COUNT')}
            infoValue={`${recordCount} records`}
          />
        )}
      </DialogContent>
    </Box>
  );
};

export default JsonPreviewSection;

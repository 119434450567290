import { Menu, MenuItem, MenuList } from '@material-ui/core';
import React, { MouseEventHandler, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import { useTranslation } from 'react-i18next';
import getTranslation from '../../utils/getSchemaTranslation';

interface SubmenuesMenuProps {
  device: Device;
  type: 'list' | 'menu';
  anchorEl?: null | HTMLElement;
  handleClose?: () => void; // sets the anchorEl to null (eg: when clicked outsite the menu)
}

const SubmenuesMenu: React.FC<SubmenuesMenuProps> = ({
  device,
  type,
  anchorEl,
  handleClose,
}) => {
  if (type === 'menu') {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <SubmenueItems device={device} />
      </Menu>
    );
  } else {
    return (
      <MenuList>
        <SubmenueItems device={device} />
      </MenuList>
    );
  }
};

interface SubmenueItemsProps {
  device: Device;
}

const SubmenueItems: React.FC<SubmenueItemsProps> = ({ device }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const deviceKey = device.key;
  const submenueNames = Object.keys(device.device_submenues || {});
  const submenuePagePath = (submenueName: string) =>
    RouteNames.DEVICES + `/${deviceKey}/${submenueName}`;

  const onClickRouteTo = (pathName: string) => {
    const handleClick: MouseEventHandler = (event: MouseEvent) => {
      history.push(pathName);
    };
    return handleClick;
  };

  return (
    <>
      {submenueNames.length === 0 ? (
        <MenuItem disabled>{t('NO_SUBMENUS')}</MenuItem>
      ) : (
        submenueNames.map((submenueName) => (
          <MenuItem
            key={submenueName}
            onClick={onClickRouteTo(submenuePagePath(submenueName))}
          >
            {getTranslation(
              device.device_submenues![submenueName],
              'title',
              submenueName
            )}
          </MenuItem>
        ))
      )}
    </>
  );
};

export default SubmenuesMenu;

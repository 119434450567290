import React from 'react';
import { Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from 'react-i18next';

interface StatusInfo {
  statusMessage: string;
  Icon: JSX.Element;
  buttonDisabled: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const StatusIndicatorButton: React.FC<StatusInfo> = ({
  statusMessage,
  Icon,
  buttonDisabled,
  onClick,
}) => {
  return (
    <Button
      variant='outlined'
      color='default'
      startIcon={Icon}
      disabled={buttonDisabled}
      onClick={onClick}
    >
      {statusMessage}
    </Button>
  );
};

interface RemoteAccessButtonProps {
  isOnline: boolean;
  onClickIfOnline: React.MouseEventHandler<HTMLButtonElement>;
}

const RemoteAccessButton: React.FC<RemoteAccessButtonProps> = ({
  isOnline,
  onClickIfOnline,
}) => {
  const { t } = useTranslation();

  const Online: StatusInfo = {
    statusMessage: t('CONNECTED'),
    Icon: <FiberManualRecordIcon style={{ color: 'lime' }} />,
    buttonDisabled: false,
  };
  const Offline: StatusInfo = {
    statusMessage: t('DEVICE_CURRENTLY_OFFLINE'),
    Icon: <FiberManualRecordIcon style={{ color: 'grey' }} />,
    buttonDisabled: false,
  };
  return isOnline ? (
    <StatusIndicatorButton {...Online} onClick={onClickIfOnline} />
  ) : (
    <StatusIndicatorButton {...Offline} />
  );
};

export default RemoteAccessButton;

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  messageColor: ({ type }: { type: FeedbackMsgType }) => ({
    color: theme.palette[type].contrastText,
    background: theme.palette[type].dark,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }),
}));

interface FeedbackMessageProps {
  type: FeedbackMsgType;
  message: FeedbackMsg;
}

const FeedbackMessage: React.FC<FeedbackMessageProps> = ({ message, type }) => {
  const classes = useStyles({ type });
  const messageString: string =
    typeof message === 'string' ? message : message.message;

  console.log('inside FeedbackMessage:', message, type);

  const feedbackMessageContent = (
    <Typography
      variant='body1'
      className={classes.messageColor}
      data-test-id={type}
    >
      {messageString.split('\n').map((line, index) => {
        return (
          <span key={index}>
            {line}
            <br />
          </span>
        );
      })}
    </Typography>
  );

  const validationErrorsContent = (
    <ul>
      {typeof message !== 'string' &&
        message.validationErrors &&
        message.validationErrors
          .map((ve) =>
            ve.constraints
              ? (Object.values(ve.constraints) as string[])
              : // Note: if the errors belong to a nested object, then the constraints are in the children...
                Object.values(
                  ve.children
                    .map((veChild) => Object.values(veChild.constraints))
                    .flat()
                )
          )
          .flat()
          .map((constraint, index) => (
            <li key={index}>
              <Typography variant='body2' className={classes.messageColor}>
                {constraint as string}
              </Typography>
            </li>
          ))}
    </ul>
  );

  return (
    <>
      {feedbackMessageContent}
      {validationErrorsContent}
    </>
  );
};

export default FeedbackMessage;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { listOnlineDevices } from '../api';

export const useDeviceIsOnline = (deviceKey: string) => {
  const token = useSelector((state: State) => state.token)!;
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    listOnlineDevices({ token })
      .then((onlineDevicesKeys) => {
        const foundInOnlineDevices = onlineDevicesKeys.includes(deviceKey);
        setIsOnline(foundInOnlineDevices);
      })
      .catch((error: any) => console.log(error));
  }, [token, deviceKey]);

  return isOnline;
};

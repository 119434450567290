import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function usePasswordExpInfo() {
  const { passwordLastChange } = useSelector(
    (state: State) => state.userProfile
  );

  const { passwordExpActive, passwordLifespanDays, passwordExpWarningDays } =
    useSelector((state: State) => state.settings.securitySettings);

  return useMemo(() => {
    const daysToMs = (days: number) => days * 24 * 60 * 60 * 1000;
    const msToDays = (milliseconds: number) =>
      Math.floor(milliseconds / (24 * 60 * 60 * 1000));
    const passwordLifeSpan = daysToMs(
      passwordExpActive ? passwordLifespanDays : 365
    );

    const currentPasswordUsageTimeMs = Date.now() - passwordLastChange;
    const passwordExpiresInMs = passwordLifeSpan - currentPasswordUsageTimeMs;
    const passwordExpiresInDays = msToDays(passwordExpiresInMs);

    const shouldWarnUser =
      passwordExpActive && passwordExpiresInDays <= passwordExpWarningDays;

    return { passwordExpActive, passwordExpiresInDays, shouldWarnUser };
  }, [
    passwordLastChange,
    passwordExpActive,
    passwordLifespanDays,
    passwordExpWarningDays,
  ]);
}

import { Button, Typography, Box, DialogContentText } from '@material-ui/core';
import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import { useTranslation } from 'react-i18next';

interface OpenJsonFileSectionProps {
  file?: File;
  fileFeedback?: Feedback;
  handleJsonFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OpenCsvFileSection: React.FC<OpenJsonFileSectionProps> = ({
  file,
  fileFeedback,
  handleJsonFileSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant='h6'>{t('UPLOAD_JSON_FILE')}</Typography>
      <DialogContentText>{t('CHOOSE_A_VALID_JSON_FILE')}</DialogContentText>

      {fileFeedback && (
        <FeedbackMessage
          type={fileFeedback.msgType}
          message={fileFeedback.message}
        />
      )}
      <Button
        component='label'
        variant='contained'
        startIcon={<InsertDriveFileOutlinedIcon />}
      >
        {file ? t('CHOOSE_ANOTHER_FILE') : t('UPLOAD_JSON_FILE')}
        <input
          type='file'
          accept='.json'
          hidden
          onChange={handleJsonFileSelected}
        />
      </Button>
    </Box>
  );
};

export default OpenCsvFileSection;

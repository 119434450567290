import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../routing/RouteNames';
import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { forgotPassword } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { useEmailInputValidation } from '../../hooks/useEmailInputValidation';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  const [emailError, emailHelperText, validateAsEmail] =
    useEmailInputValidation(email);

  const invalidInputs: boolean = email === '' || emailError;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(forgotPassword, { email });

  const handleFromSubmit = makeSubmitHandler({
    onSuccess: () => {
      setFeedback({
        message: t('PLEASE_CHECK_YOUR_EMAIL'),
        msgType: 'success',
      });
    },
  });

  return (
    <form noValidate onSubmit={handleFromSubmit} autoComplete='off'>
      <Stack>
        <TextField
          fullWidth
          label={t('EMAIL')}
          variant='standard'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateAsEmail}
          error={emailError}
          helperText={emailHelperText}
        />
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleFromSubmit}
          endIcon={loading && <LoopOutlinedIcon />}
          disabled={loading || invalidInputs}
        >
          {t('SEND')}
        </Button>
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
        <Link to={RouteNames.LOGIN}>{t('LOGIN')}</Link>
      </Stack>
    </form>
  );
};

export default ForgotPasswordForm;

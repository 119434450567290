import React from 'react';
import { List } from '@material-ui/core';
import UserListItem from './UserListItem';

interface UserListProps {
  users: UserProfile[];
}

const UserList: React.FC<UserListProps> = ({ users }) => {
  return (
    <List>
      {users.map((user) => (
        <UserListItem key={user.email} user={user} />
      ))}
    </List>
  );
};

export default UserList;

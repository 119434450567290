import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import TitledCard from '../../../shared/components/TitledCard';

const data = [
  {
    id: 1,
    name: 'Juana H. Phu',
    birthday: 'September 1, 1996',
    email: 'JuanaHPhu@email.com',
  },
  {
    id: 2,
    name: 'Alex N. Booth',
    birthday: 'January 10, 1992',
    email: 'hello@alexnbooth.com',
  },
  {
    id: 3,
    name: 'Jami R. Wilson',
    birthday: 'July 26, 1988',
    email: 'jami.r.wilson@gmail.com',
  },
  {
    id: 4,
    name: 'Paul P. Tyson',
    birthday: 'July 21, 1993',
    email: 'paultyson@gmail.com',
  },
  {
    id: 5,
    name: 'Joyce W. Sprague',
    birthday: 'December 24, 1938',
    email: 'jws@jwslabs.com',
  },
];

const TableSample: React.FC = () => {
  return (
    <TitledCard title='Table'>
      <DataGrid
        rows={data}
        columns={[
          { field: 'name', headerName: 'Name', width: 150 },
          { field: 'birthday', headerName: 'Birthday', width: 150 },
          { field: 'email', headerName: 'Email Address', width: 150 },
        ]}
        autoHeight
        disableColumnMenu
        rowCount={data.length}
        pageSize={data.length} // initial pageSize (25) will be changed by onPageSizeChange
      />
    </TitledCard>
  );
};

export default TableSample;

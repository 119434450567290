import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface UserRoleDropDownProps {
  selectedRole: UserRole;
  onChange: (selection: UserRole) => void;
}

const UserRoleDropDown: React.FC<UserRoleDropDownProps> = ({
  selectedRole,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel>{t('USER_ROLE')}</InputLabel>
      <Select
        value={selectedRole}
        label={t('USER_ROLE')}
        onChange={(event) => onChange(event.target.value as UserRole)}
      >
        <MenuItem value={'default_user'}>{t('DEFAULT_USER')}</MenuItem>
        <MenuItem value={'manufacturer'}>{t('MANUFACTURER')}</MenuItem>
        <MenuItem value={'certos_admin'}>{t('CERTOS_ADMIN')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default UserRoleDropDown;

import { IconButton } from '@material-ui/core';
import React from 'react';
import ImageCard from './ImageCard';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import { useHistory } from 'react-router-dom';
import SubmenuesMenu from './SubmenuesMenu';

interface DeviceGridItemProps {
  device: Device;
}

const DeviceGridItem: React.FC<DeviceGridItemProps> = ({ device }) => {
  const history = useHistory();

  const [subMenuAnchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleClickOpenSubMenue = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSubMenue = () => {
    setAnchorEl(null);
  };

  return (
    <ImageCard
      imageSrc={device.imgSrc}
      title={device.name}
      subTitle={device.serial}
      isOnline={device.isOnline}
      deviceType={device.type}
      hasAdditionalInfo={true}
      onClick={() => history.push('/devices/' + device.key)}
      key={device.key}
    >
      <IconButton
        aria-label='open submenues'
        color='primary'
        onClick={handleClickOpenSubMenue}
      >
        <WidgetsOutlinedIcon />
      </IconButton>
      <SubmenuesMenu
        device={device}
        type='menu'
        anchorEl={subMenuAnchorEl}
        handleClose={handleCloseSubMenue}
      />
    </ImageCard>
  );
};

export default DeviceGridItem;

import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function useAsyncFormSubmit<P, R>(
  apiFunction: (params: P) => Promise<ApiResult<R>>,
  params: P // Note, token must be included too.
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback>(null);

  const dispatch = useDispatch();

  // makeSubmitHandler is a thunk
  // makeSubmitHandler({ your callbacks and checks }) gives you a () => void submitHandler
  const makeSubmitHandler =
    ({
      preChecker,
      onSuccess,
      paramsToSubmitHandler,
    }: {
      preChecker?: () => boolean;
      onSuccess: UseCaseExtensionCallback<R>;
      paramsToSubmitHandler?: Partial<P>; // Note: this can be used to manually trigger the submitHandler on immediate data...
    }) =>
    () => {
      if (loading) {
        return;
      }

      const startOperation = preChecker ? preChecker() : true;
      if (!startOperation) {
        return;
      }

      setLoading(true);

      apiFunction({ ...params, ...paramsToSubmitHandler })
        .then((data) => {
          setLoading(false);
          setFeedback(null);

          if (data.error) {
            return setFeedback({ message: data.error, msgType: 'error' });
          }

          onSuccess(dispatch, data);
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({ message: error, msgType: 'error' });
        });
    };

  return {
    makeSubmitHandler,
    loading,
    feedback,
    setFeedback,
  };
}

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Stack from '../../../shared/layouts/Stack';
import CreateSubMenuRecordOptionMenu, {
  CreateSubMenuRecordOption,
} from './CreateSubMenuRecordOptionMenu';
import CreateSubMenuRecordForm from './CreateSubMenuRecordForm';
import CreateSubMenuRecordJson from './CreateSubMenuRecordJson';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import { addDeviceData } from '../../../redux/actionCreators';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import { postDeviceData } from '../../../api';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface CreateSubMenuRecordProps {
  submenueName: string;
  subMenuDefinition: SchemaObject;
  deviceKey: string;
}

const CreateSubMenuRecord: React.FC<CreateSubMenuRecordProps> = ({
  submenueName,
  subMenuDefinition,
  deviceKey,
}) => {
  const { t } = useTranslation();

  const [hideForm, setHideForm] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] =
    useState<CreateSubMenuRecordOption>('form');

  const [subMenuRecord, setSubMenuRecord] = useState<any>({});
  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(postDeviceData, {
      token,
      dataPayload: [{ ...subMenuRecord }], // Note: api always expects array of records
      deviceKey,
      submenueName,
    });

  const handleCreateRecordSubmit = makeSubmitHandler({
    preChecker: () => {
      const noDataFilled = Object.keys(subMenuRecord).length === 0;
      if (noDataFilled) {
        setFeedback({
          message: t('NO_NEW_DATA_IN_RECORD'),
          msgType: 'info',
        });
        return false;
      }

      return true;
    },
    onSuccess: (dispatch, addedRecords) => {
      dispatch(addDeviceData(addedRecords));
      setHideForm(true);
    },
  });

  const createRecordIsEnabled =
    subMenuDefinition.allowedUserActions &&
    subMenuDefinition.allowedUserActions.createRecord === true;

  // return hideForm ? openFormButton : createRecordForm;
  return (
    <>
      <Button // used to open the form
        variant='contained'
        color='primary'
        onClick={() => setHideForm(false)}
        disabled={!createRecordIsEnabled}
      >
        {createRecordIsEnabled ? t('NEW_RECORD') : t('CREATE_RECORD_DISABLED')}
      </Button>

      <Dialog disableBackdropClick disableEscapeKeyDown open={!hideForm}>
        <DialogTitle>{t('CREATE_A_NEW_RECORD')}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {t('USE_FORM_OR_CODE_EDITOR_HINT')}
          </DialogContentText>
          <Stack>
            <CreateSubMenuRecordOptionMenu
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            {selectedOption === 'form' ? (
              <CreateSubMenuRecordForm
                subMenuDefinition={subMenuDefinition}
                subMenuRecord={subMenuRecord}
                setSubMenuRecord={setSubMenuRecord}
                setFeedback={setFeedback}
              />
            ) : (
              <CreateSubMenuRecordJson
                subMenuDefinition={subMenuDefinition}
                setSubMenuRecord={setSubMenuRecord}
              />
            )}
            {!loading && feedback && (
              <FeedbackMessage
                type={feedback.msgType}
                message={feedback.message}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHideForm(true)}>Cancel</Button>

          <Button // used to submit the form (so it's stored on backend)
            variant='contained'
            color='secondary'
            onClick={handleCreateRecordSubmit}
          >
            {t('CREATE_RECORD')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateSubMenuRecord;

import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import TitledCard from '../../../shared/components/TitledCard';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import SearchBox from '../../../shared/components/SearchBox';
import { useTranslation } from 'react-i18next';
import Stack from '../../../shared/layouts/Stack';
import DeviceList from '../../../shared/components/DeviceList';
import { useSelector } from 'react-redux';
import { useFilter } from '../../../hooks/useFilter';
import { UploadImage } from './UploadImage';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import { postRegisterDevice } from '../../../api';
import Editor from '@monaco-editor/react';
import FormSubmitButton from '../../../shared/components/FormSubmitButton';
import useSyncedState from '../../../hooks/useSyncedState';

// used for monaco editor
const FAKE_FILE_PATH = 'user://input.json';

export const CreateDevice: React.FC = () => {
  const { t } = useTranslation();

  const [deviceSearch, setDeviceSearch] = useState('');
  const devices = useSelector((state: State) => state.devices);
  const filteredDevices = useFilter(devices, deviceSearch);

  const [chosenDevice, setChosenDevice] = useState<Device>();
  const chooseDevice = (device: Device) => {
    setChosenDevice(device);
    setDeviceSearch('');
  };

  const [uploadedImgSrc, setUploadedImgSrc] = useState<string>();
  const onUploadDone = (imgSrc: string) => setUploadedImgSrc(imgSrc);

  const deviceTemplate = useMemo(
    () => ({
      key: '',
      secret: '',
      name: chosenDevice?.name || '',
      type: chosenDevice?.type || '',
      serial: chosenDevice?.serial || '',
      imgSrc: uploadedImgSrc || chosenDevice?.imgSrc || '',
      device_attributes: chosenDevice?.device_attributes || {},
      device_settings: chosenDevice?.device_settings || {},
      device_submenues: chosenDevice?.device_submenues || {},
    }),
    [chosenDevice, uploadedImgSrc]
  );

  const [deviceToBeCreated, setDeviceToBeCreated] =
    useSyncedState(deviceTemplate);

  const [jsonString, setJsonString] = useSyncedState(
    JSON.stringify(deviceToBeCreated, null, 2)
  );
  const [invalidJson, setInvalidJson] = useState(false);

  const handleEditorModelChange = (newValue: string | undefined) => {
    if (typeof newValue === 'string') {
      setJsonString(newValue);
    }
  };
  useEffect(() => {
    if (uploadedImgSrc) {
      setDeviceToBeCreated((prev) => {
        let copy = { ...prev };
        copy.imgSrc = uploadedImgSrc;
        return copy;
      });
    }
  }, [uploadedImgSrc, setDeviceToBeCreated]);

  useEffect(() => {
    try {
      const device = JSON.parse(jsonString);
      setDeviceToBeCreated(device);
      setInvalidJson(false);
      return;
    } catch (err) {
      setInvalidJson(true);
      console.log('jsonString currently not correct...');
    }
  }, [jsonString, setDeviceToBeCreated]);

  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, feedback, loading, setFeedback } =
    useAsyncFormSubmit(postRegisterDevice, {
      token,
      ...deviceToBeCreated,
    });

  const handleSubmit = makeSubmitHandler({
    onSuccess: (_, data) => {
      setFeedback({
        msgType: 'success',
        message: 'Successfully created device...',
      });
    },
  });

  return (
    <TitledCard title={t('CREATE_DEVICE')} iconAvatar={<AddOutlinedIcon />}>
      <Stack>
        <SearchBox
          placeholder={t('SEARCH_DEVICES')}
          value={deviceSearch}
          onChange={(e: any) => setDeviceSearch(e.target.value)}
        />

        {deviceSearch && (
          <DeviceList
            devices={filteredDevices}
            onItemClicked={chooseDevice}
          ></DeviceList>
        )}

        <CDGrid>
          <UploadImage
            onUploadDone={onUploadDone}
            previewSrc={
              deviceToBeCreated?.imgSrc || 'borken image will show on fail'
            }
          />

          <Editor
            height='50vh'
            defaultLanguage='json'
            value={jsonString}
            options={{ wordWrap: 'on' }}
            onChange={handleEditorModelChange}
            defaultPath={FAKE_FILE_PATH}
          />
        </CDGrid>

        <FormSubmitButton
          btnName={t('CREATE_DEVICE')}
          handleSubmit={handleSubmit}
          invalidInputs={invalidJson}
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
export const CDGrid: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.grid}>{children}</div>;
};

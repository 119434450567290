import { useCallback, useEffect, useState } from 'react';

interface Validation {
  errorCase: (inputValue: any) => boolean;
  helperText: string;
}

// NOTE: The useInputValidation hook is for generic use and specific re-use.
export function useInputValidation<T>(
  inputValue: T,
  validations: Validation[]
) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const validate: any = useCallback(() => {
    // Try to find an error by the specified validations.
    const foundErrorCase = validations.find((validation) =>
      validation.errorCase(inputValue)
    );

    setError(foundErrorCase ? true : false);
    setHelperText(foundErrorCase ? foundErrorCase.helperText : '');
  }, [inputValue, validations]);

  // validate anytime the inputValue changes
  useEffect(() => {
    if (inputValue) {
      validate();
    }
  }, [inputValue, validate]);

  // Note that the validate callback is passed too to be invoked manually if needed.
  return [error, helperText, validate];
}

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import OpenCsvFileSection from './OpenCsvFileSection';
import CsvPreviewSection from './CsvPreviewSection';
import { useSelector } from 'react-redux';
import { csvImportDeviceData } from '../../../api';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import ImportOptionsSection from './ImportOptionsSection';
import { useTranslation } from 'react-i18next';

interface CsvImportDialogProps {
  deviceKey: string;
  subMenuDefinition: SchemaObject;
  subMenuName: string;
}

const CsvImportDialog: React.FC<CsvImportDialogProps> = ({
  deviceKey,
  subMenuDefinition,
  subMenuName,
}) => {
  const { t } = useTranslation();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [importDone, setImportDone] = useState(false);

  const [file, setFile] = useState<File>();
  const fileName = file?.name;
  const fileSize = file?.size;

  const [fileFeedback, setFileFeedback] = useState<Feedback>();

  const [csvFileText, setCsvFileText] = useState<string>();
  const csvTextLines: string[] = csvFileText
    ? csvFileText.split('\n').filter((line) => line !== '')
    : [];
  const recordCount = csvTextLines.length - 1;
  const csvPreview = csvTextLines.splice(0, 10).join('\n');

  const [shouldAddOrUpdate, setShouldAddOrUpdate] = useState(false);

  const token = useSelector((state: State) => state.token)!;
  const { loading, feedback, makeSubmitHandler, setFeedback } =
    useAsyncFormSubmit(csvImportDeviceData, {
      token,
      csvData: csvFileText!,
      csvFileName: fileName!,
      shouldUpdateDuplicateRecords: shouldAddOrUpdate,
      deviceKey,
      subMenuName,
    });

  useEffect(() => {
    if (file) {
      file
        .text()
        .then((textValue: string) => setCsvFileText(textValue))
        .catch((error) => {
          console.log(error);
          setFileFeedback({
            msgType: 'error',
            message: t('ERROR_READING_FILE'),
          });
        });
    }
  }, [file, t]);

  const resetFileRelatedState = () => {
    setFile(undefined);
    setCsvFileText(undefined);
    setFileFeedback(undefined);

    setFeedback(null);
    setImportDone(false);
    setShouldAddOrUpdate(false);
  };

  const handleCsvFileSelected = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // reset File Feedback from any previous tries
    resetFileRelatedState();

    if (!event.target.files) {
      setFileFeedback({
        msgType: 'info',
        message: t('NOTHING_SELECTED'),
      });
      return;
    }

    const file = event.target.files[0];
    if (file && !file.name.includes('.csv')) {
      setFileFeedback({
        msgType: 'error',
        message: t('FILE_NOT_A_CSV'),
      });
      return;
    }

    setFile(file);
  };

  const importCsvSubmitHandler = makeSubmitHandler({
    onSuccess: (_, data: Array<any> | any) => {
      setImportDone(true);

      console.log(data);
      const allRecordsWereImported = Array.isArray(data);
      if (allRecordsWereImported) {
        return setFeedback({
          msgType: 'success',
          message: t('IMPORTED_RECORDS_SUCCESSFULLY', {
            importedRecordsCount: data.length,
          }),
        });
      }

      const isBulkWriteError = data.name && data.name === 'BulkWriteError';
      if (isBulkWriteError) {
        return handleBulkWriteErrors(data);
      }

      const isResolvedBulkWriteError =
        data.bulkWriteErrors && data.bulkWriteResult;

      if (isResolvedBulkWriteError) {
        return handleResolvedBulkWriteErrors(data);
      }

      // unknown
      return setFeedback({
        msgType: 'error',
        message: t('UNKNOWN_ERROR_CSV_FORMAT'),
      });
    },
  });

  const handleBulkWriteErrors = (data: any) => {
    // BulkWriteErrors
    const totalRecords = recordCount;
    const insertedRecords = data.result.nInserted;
    if (insertedRecords === 0) {
      return setFeedback({
        msgType: 'info',
        message: t('NOTHING_INSERTED_DUPLICATE_KEYS'),
      });
    } else {
      return setFeedback({
        msgType: 'info',
        message: t('ONLY_NUMBER_INSERTED_DUPLICATE_KEYS', {
          insertedRecords,
          totalRecords,
        }),
      });
    }
  };

  const handleResolvedBulkWriteErrors = (data: any) => {
    console.log(data);
    const { bulkWriteErrors, bulkWriteResult } = data;

    const bulkWriteUpdateWasSuccessful =
      bulkWriteResult.ok === 1 &&
      bulkWriteResult.nMatched === bulkWriteErrors.writeErrors.length;
    console.log(bulkWriteUpdateWasSuccessful);

    const nWriteErrors = bulkWriteErrors.writeErrors.length;
    const nMatched = bulkWriteResult.nMatched;
    const nModified = bulkWriteResult.nModified;

    const nInsertedProperly = recordCount - nWriteErrors;

    // noRecordChanged if the bulkWrite was okay yet nothing was inserted or updated (aka the csv data is up to date)
    const noRecordChanged =
      bulkWriteResult.ok === 1 && nInsertedProperly === 0 && nModified === 0;
    if (noRecordChanged) {
      return setFeedback({
        msgType: 'info',
        message: t('IT_TURNED_OUT_THERE_WAS_NO_NEW_DATA'),
      });
    }

    // final case: some records were inserted (or maybe not), some were updated (or maybe not)
    return setFeedback({
      msgType: 'success',
      message: t('IMPORT_SUMMARY', {
        recordCount,
        nInsertedProperly,
        nMatched,
        nModified,
      }),
    });
  };

  const openImportDialog = () => setDialogOpened(true);
  const closeImportDialog = () => {
    setDialogOpened(false);
    resetFileRelatedState();
  };

  const createRecordIsEnabled =
    subMenuDefinition.allowedUserActions &&
    subMenuDefinition.allowedUserActions.createRecord === true;

  return (
    <>
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={openImportDialog}
        disabled={!createRecordIsEnabled}
      >
        {t('IMPORT_CSV')}
      </Button>
      <Dialog
        open={dialogOpened}
        // onBackdropClick={closeImportDialog}
        // onEscapeKeyDown={closeImportDialog}
        onClose={closeImportDialog}
      >
        <DialogTitle>
          {t('IMPORT_CSV_DEVICE_DATA_SUBMENUNAME', { subMenuName })}
        </DialogTitle>

        <DialogContent>
          <OpenCsvFileSection
            file={file}
            fileFeedback={fileFeedback}
            handleCsvFileSelected={handleCsvFileSelected}
          />
          {csvPreview && (
            <>
              <CsvPreviewSection
                fileName={fileName!}
                fileSize={fileSize!}
                recordCount={recordCount}
                csvPreview={csvPreview}
              />
              <ImportOptionsSection
                shouldAddOrUpdate={shouldAddOrUpdate}
                setShouldAddOrUpdate={setShouldAddOrUpdate}
                disableCheckbox={importDone}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImportDialog}>{t('CANCEL')}</Button>
          <Button
            color='primary'
            variant='contained'
            disabled={!csvPreview || loading}
            startIcon={loading && <LoopOutlinedIcon />}
            onClick={importDone ? closeImportDialog : importCsvSubmitHandler}
          >
            {importDone ? t('DONE') : t('IMPORT')}
          </Button>
        </DialogActions>
        <DialogContent>
          {!loading && feedback && (
            <FeedbackMessage
              type={feedback.msgType}
              message={feedback.message}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CsvImportDialog;

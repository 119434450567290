import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Box,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useCompanyInfo } from '../../hooks/useCompanyInfo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      padding: theme.spacing(3),
    },
  })
);

const ImprintPage: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const companyInfo = useCompanyInfo();
  return (
    <Container className={classes.page}>
      <Grid container direction='column' spacing={3}>
        <Typography variant='h3' component='h1'>
          {t('IMPRINT')}
        </Typography>
        <Grid item>
          <ImprintText label={t('COMPANY_NAME')}>
            {companyInfo.companyName}
          </ImprintText>
          <ImprintText label={t('ADDRESS')}>{companyInfo.address}</ImprintText>
          <ImprintText>{companyInfo.address2}</ImprintText>
          <ImprintText label={t('MORE')}>{companyInfo.moreInfo}</ImprintText>
        </Grid>
        <Grid item>
          <ImprintText label={t('CEO')}>{companyInfo.ceoName}</ImprintText>
          <ImprintText label={t('EMAIL')}>{companyInfo.email}</ImprintText>
          <ImprintText label={t('PHONE')}>{companyInfo.phone}</ImprintText>
          <ImprintText label={t('FAX')}>{companyInfo.fax}</ImprintText>
        </Grid>
      </Grid>
    </Container>
  );
};

interface ImprintTextProps {
  label?: string;
}
const ImprintText: React.FC<ImprintTextProps> = (props) => {
  return (
    <>
      <Box hidden={!props.label}>
        <Typography variant='caption'>
          <Box fontWeight='fontWeightBold' marginTop={1}>
            {props.label}
          </Box>
        </Typography>
      </Box>
      <Typography variant='body1'>{props.children}</Typography>
    </>
  );
};

export default ImprintPage;

import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/*
 A wrapper around useState that works as usual useState...
 However it syncs the defaultValue to the state any time the default value changes...

 Usage Example:
 ImageContainer = ({ defaultImage }) => {
    // image will change whenever setImage is called, or when defaultImage changes too!...    
    const [image, setImage] = useSyncedState(defaultImage)
    ...
}

*/
export default function useSyncedState<T>(
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [val, setVal] = useState<T>(defaultValue);

  // Note: this useEffect keeps the val and defaultValue in sync
  // otherwise the useState will only use defaultValue on first render
  useEffect(() => setVal(defaultValue), [defaultValue]);

  // make it accessible exactly like usual useState
  return [val, setVal];
}

import React from 'react';
import i18next from 'i18next';
import { IconButton, Menu, MenuItem, useTheme } from '@material-ui/core';
import 'flag-icon-css/css/flag-icon.min.css';

const supportedLanguages = [
  {
    code: 'en',
    name: 'English',
    flagCode: 'us',
  },
  {
    code: 'de',
    name: 'Deutsch',
    flagCode: 'de',
  },
  {
    code: 'es',
    name: 'Español',
    flagCode: 'es',
  },
];

const Flag: React.FC<{ code: string }> = ({ code }) => {
  return <span className={`flag-icon flag-icon-${code}`}></span>;
};

const LanguagePicker: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);
  const changeLanguageAndCloseMenu = (code: string) => {
    i18next.changeLanguage(code);
    closeMenu();
  };

  const theme = useTheme();

  const resolvedLanguage = i18next.resolvedLanguage;
  const currentLanguageFlagCode =
    supportedLanguages.find((lang) => lang.code === resolvedLanguage)
      ?.flagCode || 'gb';
  return (
    <>
      <IconButton size='small' onClick={openMenu}>
        <Flag code={currentLanguageFlagCode} />
      </IconButton>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={0}
      >
        {supportedLanguages.map(({ code, name, flagCode }) => (
          <MenuItem
            key={code}
            onClick={() => changeLanguageAndCloseMenu(code)}
            style={{ gap: theme.spacing(1) }}
            disabled={code === resolvedLanguage}
          >
            <Flag code={flagCode} /> {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguagePicker;

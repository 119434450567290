import { useEffect, useState } from 'react';
import { sendConsoleCmdRealTime } from '../api';

const GET_COMMANDS_NAME = 'GET_COMMANDS';

export const useDeviceCommands = ({
  token,
  deviceKey,
}: {
  token: string;
  deviceKey: string;
}) => {
  const [deviceCommands, setDeviceCommands] = useState<string[]>([]);

  useEffect(() => {
    sendConsoleCmdRealTime({
      token,
      deviceKey,
      commandString: GET_COMMANDS_NAME,
    })
      .then((res) => setDeviceCommands(res.allowedCommands))
      .catch((err) => console.log(err));

    return () => {
      setDeviceCommands([]);
    };
  }, [deviceKey, token]);

  return deviceCommands;
};

import React from 'react';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import { getRuleEngineUrl } from '../../utils';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { Typography } from '@material-ui/core';

const RuleEngine: React.FC = () => {
  return (
    <DefaultPageLayout title=''>
      <Typography variant='body1' style={{ margin: 8 }}>
        <AccountTreeOutlinedIcon /> Please login with your regular certos
        username and password. Please do not forget to logout from the Rule
        Chain Window.
      </Typography>

      <iframe
        title='node-red-embed'
        src={getRuleEngineUrl()}
        width='100%'
        height='700px'
        frameBorder='0'
      />
    </DefaultPageLayout>
  );
};

export default RuleEngine;

import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';

interface LabeledSwitchProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <FormControl component='fieldset'>
      <FormControlLabel
        value='start'
        control={
          <Switch
            color='primary'
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={label}
        labelPlacement='end'
      />
    </FormControl>
  );
};

export default LabeledSwitch;

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Grow,
} from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface indexProps {
  imageSrc: string;
  title: string;
  subTitle: string;
  isOnline: boolean | undefined;
  deviceType: string | undefined;
  hasAdditionalInfo: boolean;
  profilePlaceHolder?: boolean;
  onClick: () => void;
}

const cardWidth = 260;

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    width: '100%',
    /*[theme.breakpoints.up('sm')]: {
      width: cardWidth,
    },*/
  },
  fullHeight: {
    /* 
      sometimes a card stretches all it's siblings on the same row,
      the CardActionArea is then not he same height as the entire card
      thus the height is set to 100% to account for this
    */
    height: '100%',
  },
  cardTitleAndSubTitle: {
    width: '70%',
    overflow: 'hidden',
    lineBreak: 'strict',
  },
  cardMedia: {
    margin: '0 auto',
    width: '100%',
    height: cardWidth,
    color: theme.palette.grey[400],
  },
  rightSidedAction: {
    justifyContent: 'flex-end',
  },
}));

const ImageCard: React.FC<indexProps> = ({
  imageSrc,
  title,
  subTitle,
  isOnline,
  hasAdditionalInfo,
  deviceType,
  onClick,
  profilePlaceHolder,
  children,
}) => {
  const { t } = useTranslation();

  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  useEffect(() => {
    if (typeof imageSrc === 'string' && imageSrc.length > 0) {
      const image = new Image();
      image.onerror = () => {
        setLoadFailed(true);
        image.onerror = null; // remove callback
      };
      image.src = imageSrc; // Trigger the loading
    } else {
      // Note: if the passed imageSrc isn't even a valid string
      // then do not wait for the loading because it will fail for sure...
      // this improves performance
      setLoadFailed(true);
    }
  }, [imageSrc]);

  const secondaryAction = (event: any) => {
    event.stopPropagation();
  };
  const classes = useStyles();
  return (
    <Grow in={true}>
      <Card className={classes.cardRoot}>
        <CardActionArea
          component='div'
          className={classes.fullHeight}
          onClick={onClick}
        >
          {loadFailed ? (
            profilePlaceHolder ? (
              <PersonOutlineOutlinedIcon className={classes.cardMedia} />
            ) : (
              <BrokenImageIcon className={classes.cardMedia} />
            )
          ) : (
            <CardMedia
              component='img'
              title={t('PICTURE_OF_DEVICE_TITLE', { title })}
              image={imageSrc}
              className={classes.cardMedia}
            />
          )}
          <CardContent>
            <Grid container justifyContent='space-between'>
              <Grid item className={classes.cardTitleAndSubTitle}>
                {/* <LargeAvatar src={imageSrc} />*/}
                <Typography variant='body2'>{title}</Typography>
                <Typography variant='subtitle2'>{subTitle}</Typography>
                {hasAdditionalInfo && (
                  <div>
                    <Typography variant='subtitle2'>{deviceType}</Typography>
                    <Typography variant='subtitle2'>
                      {isOnline ? 'Online' : 'Offline'}
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid
                item
                onClick={secondaryAction}
                onMouseOver={secondaryAction}
                onMouseOverCapture={secondaryAction}
                onMouseDown={secondaryAction}
                onMouseDownCapture={secondaryAction}
                onTouchStart={secondaryAction}
                onTouchStartCapture={secondaryAction}
              >
                {children}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        {/*<CardActions className={classes.rightSidedAction}>{children}</CardActions>*/}
      </Card>
    </Grow>
  );
};

export default ImageCard;

import React, { useState } from 'react';
import { Button, Chip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { removeDeviceData } from '../../../api';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import { RouteNames } from '../../../routing/RouteNames';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import RemoveSubMenuRecordDialog from './RemoveSubMenuRecordDialog';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { useTranslation } from 'react-i18next';

interface RemoveSubMenuRecordProps {
  deviceKey: string;
  subMenuName: string;
  subMenuDefinition: SchemaObject;
  subMenuRecord: any;
}

const RemoveSubMenuRecord: React.FC<RemoveSubMenuRecordProps> = ({
  deviceKey,
  subMenuName,
  subMenuDefinition,
  subMenuRecord,
}) => {
  const { t } = useTranslation();

  const [dialogIsOpen, setDialogOpen] = useState(false);

  const history = useHistory();
  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(removeDeviceData, {
      token,
      deviceKey,
      subMenuName,
      recordId: subMenuRecord._id,
    });

  const navigateToSubMenuTablePage = () =>
    history.push([RouteNames.DEVICES, deviceKey, subMenuName].join('/'));

  const handleRemoveDeviceDataConfirmed = makeSubmitHandler({
    onSuccess: () => {
      setFeedback({
        message: t('SUBMENU_DATA_RECORD_WAS_REMOVED'),
        msgType: 'success',
      });
      navigateToSubMenuTablePage();
    },
  });

  const deletingIsAllowed = subMenuDefinition.allowedUserActions?.deleteRecord;
  if (!deletingIsAllowed) {
    return (
      <Chip
        icon={<InfoOutlinedIcon />}
        color='default'
        label={t('DELETING_OF_RECORD_IS_DISABLED_HERE')}
      />
    );
  }

  return (
    <>
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => setDialogOpen(true)}
        endIcon={loading && <LoopOutlinedIcon />}
        disabled={loading || subMenuRecord.wasRemoved}
      >
        {subMenuRecord.wasRemoved
          ? t('RECORD_WAS_REMOVED')
          : t('REMOVE_RECORD')}
      </Button>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
      <RemoveSubMenuRecordDialog
        isOpen={dialogIsOpen}
        close={() => {
          setDialogOpen(false);
        }}
        confirm={() => {
          setDialogOpen(false);
          handleRemoveDeviceDataConfirmed();
        }}
      />
    </>
  );
};

export default RemoveSubMenuRecord;

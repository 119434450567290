import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    gap: theme.spacing(2),

    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    marginTop: theme.spacing(1),
  },
}));

const WidgetGird: React.FC = (props) => {
  const classes = useStyles();
  return <div className={classes.grid}>{props.children}</div>;
};

export default WidgetGird;

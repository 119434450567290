import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteNames } from '../../routing/RouteNames';
import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { login } from '../../api';
import { setAuthToken } from '../../redux/actionCreators';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { useEmailInputValidation } from '../../hooks/useEmailInputValidation';
import { usePasswordInputValidation } from '../../hooks/usePasswordInputValidation';
import { useTranslation } from 'react-i18next';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailError, emailHelperText, validateAsEmail] =
    useEmailInputValidation(email);

  const [pwError, pwHelperText, validateAsPassword] =
    usePasswordInputValidation(password);

  const invalidInputs: boolean =
    email === '' || password === '' || emailError || pwError;

  const history = useHistory();
  const from = history.location.state?.from || { from: { pathname: '/' } };

  const token = useSelector((state: State) => state.token);

  const { makeSubmitHandler, loading, feedback } = useAsyncFormSubmit(login, {
    email,
    password,
  });

  const handleLoginSubmit = makeSubmitHandler({
    onSuccess: (dispatch, data) => {
      dispatch(setAuthToken(data.token));
    },
  });

  const { t } = useTranslation();

  return token ? (
    <Redirect to={{ pathname: RouteNames.DASHBOARD, state: { from } }} />
  ) : (
    <form
      className={'login-form'}
      onSubmit={handleLoginSubmit}
      noValidate
      autoComplete='off'
    >
      <Stack>
        <TextField
          fullWidth
          label={t('EMAIL')}
          variant='standard'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          data-test-id='email-input'
          onBlur={validateAsEmail}
          error={emailError}
          helperText={emailHelperText}
        />
        <TextField
          fullWidth
          label={t('PASSWORD')}
          variant='standard'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          data-test-id='password-input'
          onBlur={validateAsPassword}
          error={pwError}
          helperText={pwHelperText}
        />
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleLoginSubmit}
          endIcon={loading && <LoopOutlinedIcon />}
          disabled={loading || invalidInputs}
          data-test-id='login'
        >
          {t('LOGIN')}
          {/* Note: might want to split Login Title from Login Button,
              as the button is more of an intent/verb than just a title */}
        </Button>
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
        <Link to={RouteNames.REGISTER} data-test-id='create-account'>
          {t('CREATE_ACCOUNT')}
        </Link>
        <Link to={RouteNames.FORGOT_PASSWORD} data-test-id='forgot-password'>
          {t('FORGOT_PASSWORD')}
        </Link>
      </Stack>
    </form>
  );
};

export default LoginForm;

import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Paper, Avatar } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';

import { getAuditTrail } from '../../api';
import { useSelector } from 'react-redux';

interface LatestEventsTimelineProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LatestEventsTimeline: React.FC<LatestEventsTimelineProps> = () => {
  const token = useSelector((state: State) => state.token);
  const users = useSelector((state: State) => state.users);

  const [latestAuditTrailEvents, setLatestAuditTrailEvents] =
    useState<AuditTrailEvent[]>();

  useEffect(() => {
    getAuditTrail({
      token: token!,
      pagination: 1,
      pageSize: 5,
      auditTrailEventFilter: {},
      auditTrailSortModel: {},
    })
      .then(({ auditTrailEvents, totalSize }) => {
        setLatestAuditTrailEvents(auditTrailEvents);
      })
      .catch((error) => console.log(error));
  }, [token]);

  const classes = useStyles();

  const generateTimeLineItem = (
    event: AuditTrailEvent,
    index: number,
    array: AuditTrailEvent[]
  ) => {
    const tsAsDate = new Date(event.timestamp);
    const time =
      tsAsDate.toLocaleDateString() + ', ' + tsAsDate.toLocaleTimeString();

    const user = users.find((u) => u._id === event.userId);
    const userInitials = user
      ? user.firstname.charAt(0) + '' + user.lastname.charAt(0)
      : '?';

    return (
      <TimelineItem key={event._id}>
        <TimelineOppositeContent style={{ display: 'none' }} />

        <TimelineSeparator>
          <TimelineDot variant='default'>
            <Avatar src={user?.imgSrc} variant='circular' style={{}}>
              {event.userId === 'SYSTEM' ? 'SYS' : userInitials}
            </Avatar>
          </TimelineDot>
          {index < array.length - 1 && <TimelineConnector />}
        </TimelineSeparator>

        <TimelineContent>
          <Typography variant='body2' color='textSecondary'>
            {time}
          </Typography>
          <Typography
            variant='button'
            style={{
              color: event.eventOutcome === 'success' ? 'green' : 'red',
            }}
          >
            {event.eventOutcome}
          </Typography>
          <Paper elevation={3} className={classes.paper}>
            <Typography
              variant='body1'
              style={{ fontWeight: 900, wordBreak: 'break-all' }}
            >
              {event.eventType}
            </Typography>
            <Typography>{event.message}</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const TimeLine = latestAuditTrailEvents ? (
    <Timeline align='left' style={{ padding: 0 }}>
      {latestAuditTrailEvents.map(generateTimeLineItem)}
    </Timeline>
  ) : (
    <Typography>No events to show..</Typography>
  );

  return TimeLine;
};

export default LatestEventsTimeline;

import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import AreaChartSamples from './library/AreaChartSamples';
import BarChartSamples from './library/BarChartSamples';
import CandleStickChartCustomExample from './library/CandleStickChartCustomExample';
import ComposedChartSamples from './library/ComposedChartSamples';
import LineChartSamples from './library/LineChartSamples';
import PieChartSamples from './library/PieChartSamples';
import RadarChartSamples from './library/RadarChartSamples';
import RadialBarChartSamples from './library/RadialBarChartSamples';
import ScatterChartSamples from './library/ScatterChartSamples';
import TableSample from './library/TableSample';
import WorldMapSample from './library/WorldMapSample';
import WorldMapSampleOld from './library/WorldMapSampleOld';
import WidgetGird from './WidgetGrid';
import UpdatableChartExample from './library/UpdatableChartExample';

const Widgets: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultPageLayout title={t('WIDGETS_LIBRARY')}>
      <WidgetGird>
        <UpdatableChartExample />
        <AreaChartSamples />
        <LineChartSamples />
        <BarChartSamples />
        <ComposedChartSamples />
        <RadialBarChartSamples />
        <RadarChartSamples />
        <ScatterChartSamples />
        <PieChartSamples />
        <WorldMapSampleOld />
        <WorldMapSample />
        <TableSample />
        <CandleStickChartCustomExample />
      </WidgetGird>
    </DefaultPageLayout>
  );
};

export default Widgets;

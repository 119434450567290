import React from 'react';
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  Tooltip,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { sampleRadarChartData } from './sampleData';

const RadarChartSamples: React.FC = () => {
  return (
    <TitledCard title='RadarChart'>
      <ResponsiveContainer width='100%' height={400}>
        <RadarChart
          cx='50%'
          cy='50%'
          outerRadius='80%'
          data={sampleRadarChartData}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey='subject' />
          <PolarRadiusAxis angle={30} domain={[0, 150]} />
          <Radar
            name='Mike'
            dataKey='A'
            stroke='#8884d8'
            fill='#8884d8'
            fillOpacity={0.6}
          />
          <Radar
            name='Lily'
            dataKey='B'
            stroke='#82ca9d'
            fill='#82ca9d'
            fillOpacity={0.6}
          />
          <Legend />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </TitledCard>
  );
};

export default RadarChartSamples;

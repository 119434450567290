import React from 'react';
import { useTranslation } from 'react-i18next';
import CenteredFormLayout from '../../shared/layouts/CenteredFormLayout';
import LoginForm from './LoginForm';

const Login: React.FC = () => {
  const { t } = useTranslation();
  return <CenteredFormLayout title={t('LOGIN')} form={LoginForm} />;
};

export default Login;

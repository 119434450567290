import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { samplePagesData } from './sampleData';

const BarChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='BarChart'>
        <ResponsiveContainer width='100%' height={400}>
          <BarChart width={730} height={250} data={samplePagesData}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='pv' fill='#8884d8' />
            <Bar dataKey='uv' fill='#82ca9d' />
          </BarChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default BarChartSamples;

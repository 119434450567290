import { Generate } from '@jsonforms/core';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Button, Chip } from '@material-ui/core';
import React, { useState } from 'react';
import Stack from '../../shared/layouts/Stack';
import { excludeProps, schemaWithPrimitivePropsOnly } from '../../utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { editDeviceData } from '../../api';
import { useSelector } from 'react-redux';
import { updateDeviceData } from '../../redux/actionCreators';
import TitledCard from '../../shared/components/TitledCard';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useTranslation } from 'react-i18next';

interface EditSubMenuRecordFormProps {
  deviceKey: string;
  subMenuName: string;
  subMenuDefinition: SchemaObject;
  subMenuRecord: any;
}

const EditSubMenuRecordForm: React.FC<EditSubMenuRecordFormProps> = ({
  deviceKey,
  subMenuName,
  subMenuDefinition,
  subMenuRecord,
}) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const [updatedSubMenuRecord, setUpdatedSubMenuRecord] =
    useState(subMenuRecord);

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(editDeviceData, {
      token,
      recordId: subMenuRecord._id,
      recordEditInfo: excludeProps(updatedSubMenuRecord, ['_id']),
      deviceKey,
      subMenuName,
    });

  const handleFormDataChange = ({ data, _errors }: any) =>
    _errors
      ? setFeedback({
          message: t('FORM_VALIDATION_ERRORS'),
          msgType: 'error',
        })
      : setUpdatedSubMenuRecord(data);

  const handleEditRecordSubmit = makeSubmitHandler({
    onSuccess: (dispatch, updatedRecord) => {
      console.log('updatedRecord', updatedRecord);
      dispatch(updateDeviceData(updatedRecord));
      setFeedback({
        message: t('EDITING_OF_THIS_RECORD_WAS_SUCCESSFUL'),
        msgType: 'success',
      });
    },
  });

  const editIsAllowed = subMenuDefinition.allowedUserActions?.updateRecord;
  if (!editIsAllowed) {
    return (
      <Chip
        icon={<InfoOutlinedIcon />}
        color='default'
        label={t('EDITING_OF_RECORD_IS_DISABLED_HERE')}
      />
    );
  }

  const subMenuDefinitionPrimitivesOnly =
    schemaWithPrimitivePropsOnly(subMenuDefinition);

  return (
    <TitledCard title={t('EDIT_RECORD')} iconAvatar={<EditOutlinedIcon />}>
      <Stack>
        <JsonForms
          schema={subMenuDefinitionPrimitivesOnly}
          uischema={Generate.uiSchema(subMenuDefinitionPrimitivesOnly)}
          renderers={materialRenderers}
          data={updatedSubMenuRecord}
          cells={materialCells}
          onChange={handleFormDataChange}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleEditRecordSubmit}
        >
          {t('EDIT_RECORD')}
        </Button>
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
      </Stack>
    </TitledCard>
  );
};

export default EditSubMenuRecordForm;

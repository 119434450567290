import { Button, Chip, Typography } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import RemoteAccessButton from './RemoteAccess/RemoteAccessButton';
import SubmenuesMenu from '../../shared/components/SubmenuesMenu';
import UserList from '../../shared/components/UserList';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import ErrorPage from '../ErrorPage';
import LargeAvatar from '../../shared/components/LargeAvatar';
import Stack from '../../shared/layouts/Stack';
import { useAuth } from '../../hooks/useAuth';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { removeDevice } from '../../api';
import { deviceWasRemoved } from '../../redux/actionCreators';
import { RouteNames } from '../../routing/RouteNames';
import RemoveDeviceDialog from './RemoveDeviceDialog';
import TitledCard from '../../shared/components/TitledCard';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import DetailViewGrid from '../../shared/layouts/DetailViewGrid';
import FlatJsonView from '../../shared/components/FlatJsonView';
import { useTranslation } from 'react-i18next';
import { useDeviceIsOnline } from '../../hooks/useDeviceIsOnline';
import RemoteAccessPage from './RemoteAccess/RemoteAccessPage';

const DeviceDetailView: React.FC = () => {
  const { deviceKey } = useParams<{ deviceKey: string }>();

  // Note: If deviceKey has a '?' charachter, it conflicts with the url path because '?' is a reserved symbol in urls.
  const device = useSelector((state: State) =>
    state.devices.find((d) => d.key === deviceKey)
  );
  const users = useSelector((state: State) => state.users);

  if (!device) {
    return <ErrorPage />;
  }

  const usersForThisDevice = users.filter((u) =>
    u.devices.includes(device._id)
  );

  return (
    <DefaultPageLayout>
      <DeviceDetailViewContent device={device} users={usersForThisDevice} />
    </DefaultPageLayout>
  );
};

interface DeviceDetailViewContentProps {
  device: Device;
  users: UserProfile[];
}

const DeviceDetailViewContent: React.FC<DeviceDetailViewContentProps> = ({
  device,
  users,
}) => {
  const { t } = useTranslation();

  const [dialogIsOpen, setDialogOpen] = useState(false);

  const [, isAuthorized] = useAuth('manufacturer');
  const deviceDetailsObject = {
    // this will be displayed by the JSONView
    key: device.key,
    name: device.name,
    serial: device.serial,
    type: device.type,
    ...device.device_attributes,
  };

  const history = useHistory();
  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(removeDevice, {
      token,
      deviceId: device._id,
    });

  const handleRemoveDeviceConfirmed = makeSubmitHandler({
    onSuccess: (dispatch, data) => {
      setFeedback({
        message: t('SEEMS_LIKE_DEVICE_WAS_REMOVED'),
        msgType: 'success',
      });
      dispatch(deviceWasRemoved(device));
      history.push(RouteNames.DEVICES);
    },
  });

  const deviceIsOnline = useDeviceIsOnline(device.key);

  // const navigateToRemoteAccessPage = () =>
  //  history.push(`${RouteNames.DEVICES}/${device.key}/remoteAccess`);
  const onClickPlaceHolderUntilBecomesSimpleIndicator = () => {};

  const cardHeight = 300;
  return (
    <Stack>
      <Typography variant='h4'>{device.name}</Typography>

      <DetailViewGrid>
        <TitledCard title='' centeredContent fixedHeight={cardHeight}>
          <LargeAvatar src={device.imgSrc} />
          {device.wasRemoved ? (
            <Chip
              icon={<ErrorOutlineOutlinedIcon />}
              color='default'
              label={t('DEVICE_WAS_REMOVED')}
            />
          ) : (
            <RemoteAccessButton
              isOnline={deviceIsOnline}
              onClickIfOnline={onClickPlaceHolderUntilBecomesSimpleIndicator}
            />
          )}
        </TitledCard>

        <TitledCard
          title={t('DETAILS')}
          iconAvatar={<ListOutlinedIcon />}
          fixedHeight={cardHeight}
        >
          <FlatJsonView jsonObj={deviceDetailsObject} />
        </TitledCard>

        {isAuthorized && (
          <TitledCard
            title={t('USERS')}
            iconAvatar={<GroupOutlinedIcon />}
            fixedHeight={cardHeight}
          >
            <UserList users={users} />
          </TitledCard>
        )}

        <TitledCard
          title={t('FEATURES')}
          iconAvatar={<WidgetsOutlinedIcon />}
          fixedHeight={cardHeight}
        >
          <SubmenuesMenu device={device} type='list' />
        </TitledCard>
      </DetailViewGrid>

      {deviceIsOnline && <RemoteAccessPage device={device} />}

      <Button
        variant='outlined'
        color='secondary'
        onClick={() => setDialogOpen(true)}
        endIcon={loading && <LoopOutlinedIcon />}
        disabled={loading || device.wasRemoved}
      >
        {device.wasRemoved ? t('DEVICE_WAS_REMOVED') : t('REMOVE_DEVICE')}
      </Button>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
      <RemoveDeviceDialog
        isOpen={dialogIsOpen}
        close={() => {
          setDialogOpen(false);
        }}
        confirm={() => {
          setDialogOpen(false);
          handleRemoveDeviceConfirmed();
        }}
        deviceInfo={device}
      />
    </Stack>
  );
};

export default DeviceDetailView;

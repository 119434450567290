import React, { useState } from 'react';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import { TextField } from '@material-ui/core';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import TitledCard from '../../shared/components/TitledCard';
import Stack from '../../shared/layouts/Stack';
import { useInputValidation } from '../../hooks/useInputValidation';
import { useTranslation } from 'react-i18next';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { contactSupport } from '../../api';
import { useSelector } from 'react-redux';

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token);
  const [subject, setSubject] = useState<string>();
  const [message, setMessage] = useState<string>();

  const [subjectHasError, subjectHelperText, validateAsSubjectLine] =
    useInputValidation(subject, [
      {
        errorCase: (inputValue) => !inputValue || inputValue.length < 2,
        helperText: t('PLEASE_PROVIDE_SUBJECT'),
      },
    ]);

  const [messageHasError, messageHelperText, validateAsCorrectMessage] =
    useInputValidation(message, [
      {
        errorCase: (inputValue) => !inputValue || inputValue.length < 10,
        helperText: t('PLEASE_PROVIDE_MESSAGE'),
      },
    ]);

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(contactSupport, {
      token: token!,
      subject: subject!,
      message: message!,
    });

  const contactSupportSubmit = makeSubmitHandler({
    onSuccess: (_, supportTicketInfo) => {
      console.log('supportTicketInfo', supportTicketInfo);
      setFeedback({
        msgType: 'success',
        message: `Ticket #${supportTicketInfo.supportTicketNumber} was opened. Please check your email for confirmation.`,
      });
    },
  });

  return (
    <TitledCard
      title={t('CONTACT_SUPPORT')}
      iconAvatar={<LiveHelpOutlinedIcon />}
    >
      <Stack>
        <TextField
          fullWidth
          label={t('SUBJECT')}
          variant='standard'
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          error={subjectHasError}
          helperText={subjectHelperText}
          onBlur={validateAsSubjectLine}
        />
        <TextField
          fullWidth
          label={t('MESSAGE')}
          variant='standard'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          error={messageHasError}
          helperText={messageHelperText}
          onBlur={validateAsCorrectMessage}
          multiline
          rows={4}
        />

        <FormSubmitButton
          btnName={t('SEND_MESSAGE')}
          handleSubmit={contactSupportSubmit}
          invalidInputs={subjectHasError || messageHasError}
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

export default ContactForm;

import React from 'react';
import { useSelector } from 'react-redux';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import ChangePasswordForm from './ChangePasswordForm';
import ProfileInfoForm from './ProfileInfoForm';
import ProfilePictureUploader from './ProfilePictureUploader';
import ProfileInfo from './ProfileInfo';
import { useTranslation } from 'react-i18next';
import NotificationsList from '../../shared/components/NotificationsList';
import TitledCard from '../../shared/components/TitledCard';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { Typography } from '@material-ui/core';

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();

  const userProfile = useSelector((state: State) => state.userProfile);
  let notifications: UserNotification[] = useSelector(
    (state: State) => state.notifications
  );

  const isSteri24User = userProfile.role === 'steri24_user';
  if (!isSteri24User && notifications.length > 0) {
    notifications = notifications.filter(
      (notification) => notification.userId === userProfile._id
    );
  }

  return (
    <DefaultPageLayout title={t('PROFILE')}>
      <Stack>
        <Stack fluid>
          <ProfilePictureUploader profile={userProfile} />
          <ProfileInfo profile={userProfile} />
        </Stack>

        <Stack fluid>
          <ProfileInfoForm profile={userProfile} />
          <ChangePasswordForm />
        </Stack>

        <Stack fluid>
          {isSteri24User && (
            <TitledCard
              title={t('NOTIFICATIONS')}
              fixedHeight={390}
              iconAvatar={<NotificationsOutlinedIcon />}
            >
              <Stack>
                {notifications.length > 0 ? (
                  <NotificationsList notifications={notifications} />
                ) : (
                  <Typography>{t('NO_NOTIFICATIONS')}</Typography>
                )}
              </Stack>
            </TitledCard>
          )}
        </Stack>
      </Stack>
    </DefaultPageLayout>
  );
};

export default ProfilePage;

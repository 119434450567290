import { Link, makeStyles } from '@material-ui/core';
import React from 'react';
import IconCard from '../../shared/components/IconCard';

interface IconCardLinkProps {
  icon: React.ReactElement;
  title: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  href: string;
}

const useStyles = makeStyles({
  noHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

const IconCardLink: React.FC<IconCardLinkProps> = ({
  icon,
  title,
  onClick,
  href,
}) => {
  const classes = useStyles();

  return (
    <Link target='_blank' href={href} className={classes.noHover}>
      <IconCard title={title} icon={icon} onClick={onClick} />
    </Link>
  );
};

export default IconCardLink;

import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useHistory } from 'react-router';

interface UserListItemProps {
  user: UserProfile;
}

const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
  const history = useHistory();
  const fullName = `${user.firstname} ${user.lastname}`;

  return (
    <ListItem
      dense
      alignItems='center'
      button
      onClick={() => history.push('/users/' + user._id)}
    >
      <ListItemAvatar>
        <Avatar variant='circular' src={user.imgSrc} alt={fullName}>
          {/* Note: Avatar takes the src as default,
                            and the child-icon if src is not defined / not found. */}
          <AccountCircleOutlinedIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={fullName}
        secondary={`${user.email} | ${user.role}`}
      />
    </ListItem>
  );
};

export default UserListItem;

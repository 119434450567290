import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface RemoveDeviceDialogProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  deviceInfo: Device;
}

const RemoveDeviceDialog: React.FC<RemoveDeviceDialogProps> = ({
  isOpen,
  close,
  confirm,
  deviceInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={isOpen}
    >
      <DialogTitle id='confirmation-dialog-title'>
        {t('CONFIRM_DEVICE_REMOVAL')}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE', {
            deviceName: deviceInfo.name,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color='primary'>
          {t('YES')}
        </Button>
        <Button autoFocus onClick={close} color='primary'>
          {t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDeviceDialog;

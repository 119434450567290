import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { updateCertosSettings } from '../../api';
import { setCertosSettings } from '../../redux/actionCreators';
import Stack from '../../shared/layouts/Stack';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import {
  ColorPickerLabels,
  createUpdateColorObject,
  pickerLabelToPropName,
} from '../../utils/themeSettings';
import TitledCard from '../../shared/components/TitledCard';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import useSyncedState from '../../hooks/useSyncedState';
import { useTranslation } from 'react-i18next';

const useThemeSettingsStyles = makeStyles((theme) => ({
  themeColorGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // 2 columns
    gap: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr', // 3 columns on larger screen
    },
  },
}));

interface ThemeSettingsProps {}

/* For more info on colors and dark mode, refer to https://material-ui.com/customization/palette/ */
const ThemeSettings: React.FC<ThemeSettingsProps> = () => {
  const { t } = useTranslation();

  const classes = useThemeSettingsStyles();

  const token = useSelector((state: State) => state.token)!;
  const certosSettings = useSelector((state: State) => state.settings);

  const [themeSettings, setThemeSettings] = useSyncedState(
    certosSettings.themeColors
  );

  const {
    primaryColorMain,
    secondaryColorMain,
    errorColorMain,
    warningColorMain,
    infoColorMain,
    successColorMain,
  } = themeSettings;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateCertosSettings, {
      token,
      ...certosSettings,
      themeColors: themeSettings,
    });

  const handleSubmit = makeSubmitHandler({
    onSuccess: (dispatch, data) => {
      dispatch(setCertosSettings(data));
      setFeedback({
        message: t('THEME_UPDATED'),
        msgType: 'success',
      });
    },
  });

  const handleColorChange = (color: ColorResult, label: ColorPickerLabels) => {
    const updateColor = createUpdateColorObject(color, label);
    setThemeSettings({ ...themeSettings, ...updateColor });
  };

  return (
    <TitledCard title={t('THEME_COLORS')} iconAvatar={<PaletteOutlinedIcon />}>
      <Stack>
        <div className={classes.themeColorGrid}>
          <ColorPicker
            label={ColorPickerLabels.PRIMARY}
            defaultColor={primaryColorMain}
            handleColorChange={handleColorChange}
          />
          <ColorPicker
            label={ColorPickerLabels.SECONDARY}
            defaultColor={secondaryColorMain}
            handleColorChange={handleColorChange}
          />
          <ColorPicker
            label={ColorPickerLabels.ERROR}
            defaultColor={errorColorMain}
            handleColorChange={handleColorChange}
          />
          <ColorPicker
            label={ColorPickerLabels.WARNING}
            defaultColor={warningColorMain}
            handleColorChange={handleColorChange}
          />
          <ColorPicker
            label={ColorPickerLabels.INFO}
            defaultColor={infoColorMain}
            handleColorChange={handleColorChange}
          />
          <ColorPicker
            label={ColorPickerLabels.SUCCESS}
            defaultColor={successColorMain}
            handleColorChange={handleColorChange}
          />
        </div>

        <FormSubmitButton
          btnName={t('UPDATE_THEME')}
          handleSubmit={handleSubmit}
          invalidInputs={false} // manually set to false if there are no required fields
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

const useColorFieldStyles = makeStyles((theme) => ({
  colorField: (props: {
    palettePropName: PaletteColorProp;
    color: string;
  }) => ({
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: props.color,
    color: theme.palette[props.palettePropName].contrastText,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(0.3),
    marginBottom: theme.spacing(1),
  }),
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    // used for closing the colorPicker when clicking out
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

interface ColorPickerProps {
  label: ColorPickerLabels;
  defaultColor: string;
  handleColorChange: (color: ColorResult, label: ColorPickerLabels) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  defaultColor,
  handleColorChange,
}) => {
  const { t } = useTranslation();

  const [color, setColor] = useState(`${defaultColor}`);
  const [visible, setVisible] = useState(false);

  // sync color state with defaultColor
  useEffect(() => setColor(defaultColor), [defaultColor]);

  const togglePicker = () => setVisible(!visible);
  const closeColorPicker = () => setVisible(false);

  const palettePropName: PaletteColorProp = pickerLabelToPropName(label);
  const classes = useColorFieldStyles({ palettePropName, color });
  return (
    <div>
      <div className={classes.colorField} onClick={togglePicker}>
        <Typography>{t('A')}</Typography>
      </div>
      {visible ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={closeColorPicker} />
          <SketchPicker
            color={color}
            onChange={(color) => {
              setColor(color.hex);
              handleColorChange(color, label);
            }}
          />
        </div>
      ) : null}
      <Typography>{label}</Typography>
    </div>
  );
};

export default ThemeSettings;

import { createStore, applyMiddleware, Store } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

const store: Store<State, Action> & {
  dispatch: DispatchType;
} = createStore(rootReducer, applyMiddleware(thunk));

store.subscribe(() => console.log('REDUX STATE:', store.getState()));

export default store;

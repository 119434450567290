import React, { useState } from 'react';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { useTranslation } from 'react-i18next';
import TitledCard from '../../shared/components/TitledCard';
import { TextField } from '@material-ui/core';
import { postCommentOnAuditTrailEvent } from '../../api';
import { useSelector } from 'react-redux';
import Stack from '../../shared/layouts/Stack';

interface CommentFormProps {
  event: AuditTrailEvent;
  updateComment: (comment: string) => void;
}

export const CommentForm: React.FC<CommentFormProps> = ({
  event,
  updateComment,
}) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');
  const isValidComment = typeof comment === 'string' && comment.length > 0;

  const token = useSelector((state: State) => state.token)!;
  const { feedback, loading, makeSubmitHandler, setFeedback } =
    useAsyncFormSubmit(postCommentOnAuditTrailEvent, {
      token,
      eventId: event._id,
      comment,
    });

  const handleSubmit = makeSubmitHandler({
    onSuccess: () => {
      setFeedback({
        msgType: 'success',
        message: t('COMMNENT_ADDED'),
      });
      updateComment(comment);
    },
  });

  return (
    <TitledCard
      title={t('COMMENT_ON_THIS_EVENT')}
      iconAvatar={<ChatOutlinedIcon />}
    >
      <Stack>
        <TextField
          label={t('COMMENT')}
          variant='standard'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
        />
        <FormSubmitButton
          btnName={t('SEND')}
          handleSubmit={handleSubmit}
          invalidInputs={!isValidComment}
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

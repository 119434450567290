import { Dispatch, SetStateAction, useState } from 'react';

export function useStackedState<S>(
  initialValue: S[]
): [
  S[],
  (additionalValues: S | S[]) => void,
  () => void,
  Dispatch<SetStateAction<S[]>>
] {
  const [state, setState] = useState(initialValue);

  const stackState = (additionalValues: S | S[]) => {
    const addition = Array.isArray(additionalValues)
      ? additionalValues
      : [additionalValues];
    const newState = state.concat(addition);
    setState(newState);
  };

  const emptyStack = () => setState([]);

  return [state, stackState, emptyStack, setState];
}

import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface FromSectionProps {
  helpText?: string;
  showHelpText?: boolean;
}

const FormSection: React.FC<FromSectionProps> = ({
  helpText,
  showHelpText = true,
  children,
}) => {
  return (
    <Box
      style={{
        padding: '8px 16px',
        margin: '8px 0px',
        borderRadius: 6,
        border: '1px solid #ddd',
      }}
    >
      <div>{children}</div>
      {showHelpText && helpText && (
        <Typography variant='caption'>{helpText}</Typography>
      )}
    </Box>
  );
};

export default FormSection;

import React, { ReactElement } from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  Theme,
  CardHeader,
} from '@material-ui/core';

interface TitledCardProps {
  title: string;
  iconAvatar?: ReactElement;
  centeredContent?: boolean;
  fixedHeight?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: ({ fixedHeight }: { fixedHeight?: number }) => ({
    height: fixedHeight,
    padding: theme.spacing(2),

    /* fixing icon and header sizes */
    '& .MuiCardHeader-title': {
      ...theme.typography.h5,
      marginTop: -theme.spacing(1), // shift the title up by a bit to align with icon
    },
    '& .MuiCardHeader-avatar svg': {
      width: '1.2em',
      height: '1.2em',
    },

    /* scrollbar styling */
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'grey',
      borderRadius: theme.shape.borderRadius,
    },
  }),
  centeredContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const TitledCard: React.FC<TitledCardProps> = ({
  title,
  iconAvatar,
  centeredContent,
  fixedHeight,
  children,
}) => {
  const classes = useStyles({ fixedHeight });
  return (
    <Card className={classes.card}>
      {title && <CardHeader title={title} avatar={iconAvatar} />}
      <CardContent className={centeredContent ? classes.centeredContent : ''}>
        {children}
      </CardContent>
    </Card>
  );
};

export default TitledCard;

import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { MinusSquare, PlusSquare } from '../static/icons';
import {
  fade,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';

interface JSONTreeViewProps {
  name: string; // root name of the object
  jsonObj: any; // Plain Json Object to display
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      '&:before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        width: 16,
        left: -18,
        top: 12,
        borderBottom: (props: any) =>
          // only display if the TreeItem is not root node
          props.nodeId !== 'root' &&
          // only display if the TreeItem has any child nodes
          `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
      },
    },
    iconContainer: {
      // remove icon container of leaf items to remove space to horizontal line
      display: (props) => (props.children ? 'inherit' : 'none'),
    },
    content: {
      position: 'relative',
      '&:before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        width: 25,
        height: 12,
        left: -20,
        top: 13,
        background: (props: any) => {
          props.label === '10' && console.log(props);
          return props.children === false && props.isLastNode
            ? theme.palette.background.default
            : 'auto';
        },
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: TreeItemProps) => <TreeItem {...props} />);

const renderTreeFromPlainObject = (
  key: string,
  node: any,
  nodeId: string,
  isLastNode: boolean = false
) => {
  const isPrimitiveNode = typeof node !== 'object';
  const label = isPrimitiveNode ? key + ' - ' + node : key; // TODO: Investigate/Discuss how to make the key pop out. Bolding with <b> for example doesn't work and multiple spaces also don't work.

  return (
    <StyledTreeItem
      key={nodeId}
      nodeId={nodeId}
      label={label}
      isLastNode={isLastNode}
    >
      {!isPrimitiveNode &&
        // this will map objects and arrays (aka objects with 'indexed' keys)
        Object.keys(node || {}).map((subNodeKey, index, array) => {
          const isLastNode = array.length - 1 === index;
          return renderTreeFromPlainObject(
            subNodeKey,
            node[subNodeKey],
            `${nodeId}.${key}.${index}`, // nodeId of subnode, lastNode has nodeId of '<parentNode>.#'
            isLastNode
          );
        })}
    </StyledTreeItem>
  );
};

const JSONTreeView: React.FC<JSONTreeViewProps> = ({ name, jsonObj }) => {
  return (
    <TreeView
      defaultExpanded={['root']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
    >
      {renderTreeFromPlainObject(name, jsonObj, 'root')}
    </TreeView>
  );
};

export default JSONTreeView;

import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

/*
Note: This components state is managed wherever it's called, changes are passed up using 'handleChange'
Usage:
1) If menuItems is just a string array then each element is used as both 'value' and 'displayText'
   if the SelectDefinition is used then the appropriate field values are used
2) Setting defaultIsNone to true will add a first Element with value undefined and Text 'None'
*/

type SelectDefinition = { value: string; display: string };

interface StringSelectProps {
  label: string;
  menuItems: string[] | SelectDefinition[];
  currentValue?: string;
  defaultIsNone?: true;
  handleChange: (selectedValue: string) => void;
}

const StringSelect: React.FC<StringSelectProps> = ({
  label,
  menuItems,
  currentValue,
  handleChange,
  defaultIsNone,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // split up the values from display texts because then it's easier to reference and no need to type check everywhere
  const menuItemsValues: string[] =
    typeof menuItems[0] === 'string'
      ? (menuItems as string[])
      : (menuItems as SelectDefinition[]).map((item) => item.value);

  const menuItemsDisplays: string[] =
    typeof menuItems[0] === 'string'
      ? (menuItems as string[])
      : (menuItems as SelectDefinition[]).map((item) => item.display);

  const defaultValue = defaultIsNone ? undefined : menuItemsValues[0];

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        value={currentValue || defaultValue}
        onChange={(e) => handleChange(e.target.value as string)}
        displayEmpty
      >
        {defaultIsNone && <MenuItem value={undefined}>{t('NONE')}</MenuItem>}
        {menuItemsValues.map((menuItemValue, index) => (
          <MenuItem value={menuItemValue} key={index}>
            {menuItemsDisplays[index]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StringSelect;

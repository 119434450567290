import React from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import logo from '../static/logos/Certos-Landscape-White-Logo-Small.png';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import LanguagePicker from '../components/LanguagePicker';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignContent: 'space-between', // used to push footer down
  },
  cardWrapper: {
    height: 'inherit', // inherits same size as root (puts footer exactly below visible screen)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundVideo: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    zIndex: -10000,
  },
  card: {
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
  },
  logo: {
    backgroundColor: theme.palette.primary.main,
    height: 0,
    paddingTop: '26%',
  },
  cardContent: {
    padding: theme.spacing(3),
    paddingTop: 40,
    paddingBottom: '50px !important',
  },
  title: {
    marginBottom: 25,
  },
}));

interface CenteredFormLayoutProps {
  title: string;
  form: React.ElementType;
}

const CenteredFormLayout: React.FC<CenteredFormLayoutProps> = ({
  title,
  form,
}) => {
  const certosSettings = useSelector((state: State) => state.settings);

  const classes = useStyles();

  const FormElement = form;
  return (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      className={classes.root}
    >
      <video autoPlay muted loop className={classes.backgroundVideo}>
        <source src='/videos/clouds_background_test.mp4' type='video/mp4' />
      </video>

      <Box className={classes.cardWrapper}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.logo}
            image={certosSettings.logoImgSrc ?? logo}
            title='Certos Logo'
          />

          <CardContent className={classes.cardContent}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                gutterBottom
                variant='h5'
                component='h2'
                className={classes.title}
              >
                {title}
              </Typography>
              <LanguagePicker />
            </Box>
            <FormElement />
          </CardContent>
        </Card>
      </Box>
      <Footer />
    </Grid>
  );
};

export default CenteredFormLayout;

import { Button } from '@material-ui/core';
import React from 'react';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { jsonExportDeviceData } from '../../api';
import { useSelector } from 'react-redux';
import { downloadFile } from '../../utils';
import { useTranslation } from 'react-i18next';

interface JsonExportButtonProps {
  deviceKey: string;
  subMenuName: string;
}

const JsonExportButton: React.FC<JsonExportButtonProps> = ({
  deviceKey,
  subMenuName,
}) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const { loading, feedback, makeSubmitHandler } = useAsyncFormSubmit(
    jsonExportDeviceData,
    {
      token,
      deviceKey,
      subMenuName,
    }
  );

  const exportJsonSubmitHandler = makeSubmitHandler({
    onSuccess: (_, data: Blob) => {
      const exportFileName = `Export_SubMenuData_${deviceKey}_${subMenuName}_${Date.now()}.json`;
      downloadFile(exportFileName, data);
    },
  });
  return (
    <>
      <Button // used to submit the form (so it's stored on backend)
        variant='text'
        color='default'
        onClick={exportJsonSubmitHandler}
        startIcon={<SystemUpdateAltOutlinedIcon />}
      >
        {t('EXPORT_JSON')}
      </Button>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
    </>
  );
};

export default JsonExportButton;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import { CreateDevice } from './CreateDevice/CreateDevice';
import { PdfValidation } from './PdfValidation';

const ToolsPage: React.FC = () => {
  const { t } = useTranslation();

  const [, isAuthorized] = useAuth('certos_admin');

  return (
    <DefaultPageLayout title={t('TOOLS')}>
      <Stack>
        <PdfValidation />
        {isAuthorized && <CreateDevice />}
      </Stack>
    </DefaultPageLayout>
  );
};

export default ToolsPage;

import React, { useEffect, useState } from 'react';
import Routes from './routing/Routes';

import {
  createTheme,
  CssBaseline,
  Theme,
  ThemeProvider,
} from '@material-ui/core';

import { Provider } from 'react-redux';
import store from './redux/store';

import initLanguageSupport from './lang';

// this will initialize i18n (loading resources, the current lang and so on...)
initLanguageSupport();

// overriding the theme...
// see https://material-ui.com/customization/theming/ for all option
// this can / will be used for whitelabel option
const theme = createTheme({
  palette: {
    primary: {
      main: '#0c263d',
    },
  },
});

function App() {
  const [muiTheme, setMuiTheme] = useState<Theme>(theme);

  useEffect(() => {
    document.title = 'Certos';
  });

  useEffect(() => {
    store.subscribe(() => {
      const newThemeColors = store.getState().settings.themeColors;
      setMuiTheme(
        createTheme({
          palette: {
            primary: { main: newThemeColors.primaryColorMain },
            secondary: { main: newThemeColors.secondaryColorMain },
            error: { main: newThemeColors.errorColorMain },
            warning: { main: newThemeColors.warningColorMain },
            info: { main: newThemeColors.infoColorMain },
            success: { main: newThemeColors.successColorMain },
          },
        })
      );
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

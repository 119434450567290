import React from 'react';
import { useHistory } from 'react-router-dom';
import ImageCard from '../../../shared/components/ImageCard';

interface UserGridItemProps {
  user: UserProfile;
}

const UserGridItem: React.FC<UserGridItemProps> = ({ user }) => {
  const history = useHistory();
  const fullName = `${user.firstname} ${user.lastname}`;
  return (
    <ImageCard
      imageSrc={user.imgSrc!}
      title={fullName}
      subTitle={user.role.toUpperCase()}
      hasAdditionalInfo={false}
      isOnline={undefined}
      deviceType={undefined}
      onClick={() => history.push('/users/' + user._id)}
      key={user._id}
      profilePlaceHolder
    />
  );
};

export default UserGridItem;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Terminal, { ColorMode, LineType } from 'react-terminal-ui';
import { sendConsoleCmdRealTime } from '../../../api';
import { useDeviceCommands } from '../../../hooks/useDeviceCommands';
import { useStackedState } from '../../../hooks/useStackedState';
import TitledCard from '../../../shared/components/TitledCard';

interface LiveConsoleProps {
  device: Device;
}

const LiveConsole: React.FC<LiveConsoleProps> = ({ device }) => {
  const token = useSelector((state: State) => state.token)!;
  const deviceKey = device.key;

  const [terminalLineData, stackTerminalLineData, resetTerminal] =
    useStackedState([
      { type: LineType.Output, value: 'Welcome to the Certos Live Console ©!' },
      { type: LineType.Output, value: 'type "help" for more info' },
    ]);

  const deviceCommandStrings = useDeviceCommands({ token, deviceKey });
  const deviceCommandsHelpers = [
    {
      type: LineType.Output,
      value: '',
    },
    {
      type: LineType.Output,
      value: `Commands from ${device.name} (${device.key}):`,
    },
    ...deviceCommandStrings.map((commandStr: string) => ({
      type: LineType.Output,
      value: `\t${commandStr}`,
    })),
  ];

  const basePrompt = '$';
  const waitingPrompt = "Loading... (to terminate type 'exit')"; // will be used as a flag for async calls too
  const [promptVal, setPromptVal] = useState(basePrompt);
  const [colorMode, setColorMode] = useState(ColorMode.Dark);

  const sendCommandToDevice = (command: string) => {
    setPromptVal(waitingPrompt);
    stackTerminalLineData({ type: LineType.Input, value: command });

    sendConsoleCmdRealTime({ token, deviceKey, commandString: command })
      .then((res) => {
        console.log('response from device: ', res);
        setPromptVal(basePrompt);
        stackTerminalLineData([
          { type: LineType.Input, value: command },
          {
            type: LineType.Output,
            value: res.error
              ? res.error
              : typeof res === 'string'
              ? res
              : JSON.stringify(res),
          },
        ]);
        return;
      })
      .catch((err) => console.log(err));
    return;
  };

  const localConsoleCommand = (terminalInput: string) => {
    switch (terminalInput) {
      case '':
        return stackTerminalLineData({
          type: LineType.Input,
          value: terminalInput,
        }); // just make a new line...
      case 'clear':
        return resetTerminal();
      case 'help':
        return stackTerminalLineData([
          { type: LineType.Input, value: terminalInput }, // help command
          { type: LineType.Output, value: 'Native Commands:' },
          { type: LineType.Output, value: '\thelp: to show this help info' },
          { type: LineType.Output, value: '\tclear: to clear the terminal' },
          { type: LineType.Output, value: '\tlight: to use light color mode' },
          {
            type: LineType.Output,
            value: '\tdark: to use dark color mode (default)',
          },
          {
            type: LineType.Output,
            value: '\texit: to exit a running command',
          },
          ...deviceCommandsHelpers,
        ]);
      case 'exit':
        const shouldTerminate = promptVal === waitingPrompt;
        setPromptVal(basePrompt);
        return stackTerminalLineData({
          type: LineType.Output,
          value: shouldTerminate ? 'Command terminated!' : 'Nothing to exit.',
        });
      case 'light':
        stackTerminalLineData({ type: LineType.Input, value: terminalInput });
        return setColorMode(ColorMode.Light);
      case 'dark':
        stackTerminalLineData({ type: LineType.Input, value: terminalInput });
        return setColorMode(ColorMode.Dark);
      // a few hidden commands just for fun...
      case 'wow':
      case 'omg':
      case 'nice':
      case 'cool':
      case 'sick':
      case 'awesome':
      case 'amazing':
      case 'love it':
        return stackTerminalLineData([
          { type: LineType.Input, value: terminalInput },
          { type: LineType.Output, value: 'thanks 😘 enjoy!' },
        ]);
      case 'hello':
        return stackTerminalLineData([
          { type: LineType.Input, value: terminalInput },
          { type: LineType.Output, value: 'hi!' },
        ]);
      default:
        return stackTerminalLineData([
          { type: LineType.Input, value: terminalInput },
          {
            type: LineType.Output,
            value: `Unknown command "${terminalInput}"`,
          },
          { type: LineType.Output, value: 'type "help" for more info' },
        ]);
    }
  };

  const handleTerminalInput = (terminalInput: string) => {
    // console.log('terminalInput', terminalInput);
    const isCapitalLetteredCommand = terminalInput.match(/^[A-Z0-9_]+/g);
    if (isCapitalLetteredCommand) {
      return sendCommandToDevice(terminalInput);
    } else {
      return localConsoleCommand(terminalInput);
    }
  };

  return (
    <TitledCard title='Console' iconAvatar={<TerminalIconOutlined />}>
      <Terminal
        name={`Live Console to ${device.name} (${device.key})`}
        colorMode={colorMode}
        lineData={terminalLineData}
        onInput={handleTerminalInput}
        prompt={promptVal}
      />
    </TitledCard>
  );
};

function TerminalIconOutlined() {
  return (
    // Note: svg from https://www.svgrepo.com/svg/354896/console and modified
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='red'
      className='MuiSvgIcon-root'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7ZM7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17ZM8.70709 7.29288C8.31656 6.90236 7.6834 6.90238 7.29288 7.29291C6.90236 7.68344 6.90238 8.3166 7.29291 8.70712L9.58588 11L7.29291 13.2929C6.90238 13.6834 6.90236 14.3166 7.29288 14.7071C7.6834 15.0976 8.31656 15.0976 8.70709 14.7071L11.7072 11.7071C11.8948 11.5196 12.0001 11.2652 12.0001 11C12.0001 10.7348 11.8948 10.4804 11.7072 10.2929L8.70709 7.29288Z'
        fill='black'
      />
    </svg>
  );
}

export default LiveConsole;

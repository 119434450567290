import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { RouteNames } from './RouteNames';
import { useLoadInitialData } from '../hooks/useLoadInitialData';
import LoadingWrapper from './LoadingWrapper';

const ProtectedRoute = ({
  minAuthRole = 'steri24_user',
  component,
  ...rest
}: any) => {
  const [
    isAuthenticated,
    isAuthorized,
    loadingRefreshToken,
    authenticationFailed,
  ] = useAuth(minAuthRole) as boolean[];
  const dataLoading = useLoadInitialData();

  const location = useLocation();

  if (loadingRefreshToken) {
    console.log('still authenticating....');
  }

  if (dataLoading) {
    console.log('still loading data');
  }

  const RedirectToLogin = (props: any) => (
    <Redirect
      to={{
        pathname: RouteNames.LOGIN,
        state: { from: props.location },
      }}
    />
  );

  const RedirectToDashboard = () => (
    <Redirect
      to={{
        pathname: RouteNames.DASHBOARD,
        state: { from: location },
      }}
    />
  );

  const routeComponent = (props: any) => (
    <LoadingWrapper
      loadingFailed={authenticationFailed} // basically, if Authentication fails, no need to wait for anything
      renderOnLoadingFailed={() => RedirectToLogin(props)}
      loading={loadingRefreshToken || dataLoading}
      renderAfterLoading={() =>
        isAuthenticated
          ? isAuthorized
            ? React.createElement(component, props) // create the element of the protected route this will be used below
            : RedirectToDashboard() // send user to dashboard if authenticated but not authorized
          : RedirectToLogin(props)
      }
    />
  );

  return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;

import React from 'react';
import GridView from '../../../shared/layouts/GridView';
import UserGridItem from './UserGridItem';

interface UsersGridViewProps {
  users: UserProfile[];
}

const UsersGridView: React.FC<UsersGridViewProps> = ({ users }) => {
  return (
    <GridView>
      {users.map((user) => (
        <UserGridItem user={user} />
      ))}
    </GridView>
  );
};

export default UsersGridView;

import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DeviceList from '../../shared/components/DeviceList';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import LargeAvatar from '../../shared/components/LargeAvatar';
import ErrorPage from '../ErrorPage';
import UserActions from './UserActions';
import { getDevicesOfUser } from '../../api';
import { Chip } from '@material-ui/core';
import TitledCard from '../../shared/components/TitledCard';
import DetailViewGrid from '../../shared/layouts/DetailViewGrid';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import FlatJsonView from '../../shared/components/FlatJsonView';
import { useTranslation } from 'react-i18next';

const UsersDetailView: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const user = useSelector((state: State) =>
    state.users.find((user) => user._id === userId)
  );

  if (!user) {
    return <ErrorPage />;
  }

  return (
    <DefaultPageLayout>
      <UsersDetailViewContent user={user} />
    </DefaultPageLayout>
  );
};

interface UsersDetailViewContentProps {
  user: UserProfile;
}

const UsersDetailViewContent: React.FC<UsersDetailViewContentProps> = ({
  user,
}) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const [userDevices, setUserDevices] = useState<Device[]>([]);

  useEffect(() => {
    getDevicesOfUser({ token, userId: user._id }).then((devices) => {
      setUserDevices(devices);
    });
  }, [token, user._id]);

  const userFullName = user.firstname + ' ' + user.lastname;
  const cardHeight = 360;
  return (
    <DetailViewGrid>
      <TitledCard title='' centeredContent>
        <LargeAvatar src={user.imgSrc!} variant='circular' profileAvatar />
        <Typography variant='h4'>{userFullName}</Typography>
        <Chip size='small' label={user.role?.toUpperCase()} />
      </TitledCard>

      <TitledCard
        title={t('MANAGE_USER')}
        iconAvatar={<PeopleAltOutlinedIcon />}
      >
        <UserActions user={user} />
      </TitledCard>

      <TitledCard
        title={t('DETAILS')}
        iconAvatar={<ListOutlinedIcon />}
        fixedHeight={cardHeight}
      >
        <FlatJsonView
          jsonObj={{ ...user, ...{ devices: user.devices.length } }}
        />
      </TitledCard>

      {userDevices && userDevices.length > 0 && (
        <TitledCard
          title={t('DEVICES')}
          iconAvatar={<DeviceHubIcon />}
          fixedHeight={cardHeight}
        >
          <DeviceList devices={userDevices} />
        </TitledCard>
      )}
    </DetailViewGrid>
  );
};

export default UsersDetailView;

import React from 'react';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { samplePieChartData } from './sampleData';

const PieChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='PieChart'>
        <ResponsiveContainer width='100%' height={400}>
          <PieChart width={400} height={400}>
            <Pie
              dataKey='value'
              isAnimationActive={false}
              data={samplePieChartData.data01}
              outerRadius={80}
              fill='#8884d8'
              label
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </TitledCard>

      <TitledCard title='Overlapped PieCharts'>
        <ResponsiveContainer width='100%' height={400}>
          <PieChart width={400} height={400}>
            <Pie
              dataKey='value'
              isAnimationActive={false}
              data={samplePieChartData.data01}
              cx='30%'
              cy='30%'
              outerRadius={80}
              fill='#8884d8'
              label
            />
            <Pie
              dataKey='value'
              data={samplePieChartData.data02}
              cx='60%'
              cy='60%'
              innerRadius={40}
              outerRadius={80}
              fill='#82ca9d'
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default PieChartSamples;

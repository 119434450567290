import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface RemoveSubMenuDataRecordDialogProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
}

const RemoveSubMenuDataRecordDialog: React.FC<
  RemoveSubMenuDataRecordDialogProps
> = ({ isOpen, close, confirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={isOpen}
    >
      <DialogTitle id='confirmation-dialog-title'>
        {t('CONFIRM_DATA_REMOVAL')}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t('DELETE_SUBMENU_CONFIRMATION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color='primary'>
          {t('YES')}
        </Button>
        <Button autoFocus onClick={close} color='primary'>
          {t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveSubMenuDataRecordDialog;

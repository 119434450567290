import { ColorResult } from 'react-color';

export enum ColorPickerLabels {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  ERROR = 'Error',
  WARNING = 'Warning',
  INFO = 'Info',
  SUCCESS = 'Success',
}

export const pickerLabelToPropName = (
  label: ColorPickerLabels
): PaletteColorProp => {
  return label.split(' ')[0].toLowerCase() as PaletteColorProp;
};

export const createUpdateColorObject = (
  color: ColorResult,
  label: ColorPickerLabels
) => {
  switch (label) {
    case ColorPickerLabels.PRIMARY:
      return { primaryColorMain: color.hex };
    case ColorPickerLabels.SECONDARY:
      return { secondaryColorMain: color.hex };
    case ColorPickerLabels.ERROR:
      return { errorColorMain: color.hex };
    case ColorPickerLabels.WARNING:
      return { warningColorMain: color.hex };
    case ColorPickerLabels.INFO:
      return { infoColorMain: color.hex };
    case ColorPickerLabels.SUCCESS:
      return { successColorMain: color.hex };
    default:
      return {};
  }
};

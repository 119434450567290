import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  deviceDetailsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

const DetailViewGrid: React.FC = (props) => {
  const classes = useStyles();

  return <div className={classes.deviceDetailsGrid}>{props.children}</div>;
};

export default DetailViewGrid;

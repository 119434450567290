import React from 'react';
import { useSelector } from 'react-redux';
import ImageUploader from '../../shared/components/ImageUploader';
import Logo from '../../shared/static/logos/Certos-Landscape-Logo-Small.png';
import { postUploadImage, updateCertosSettings } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { setCertosSettings } from '../../redux/actionCreators';
import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import TitledCard from '../../shared/components/TitledCard';
import WallpaperOutlinedIcon from '@material-ui/icons/WallpaperOutlined';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LogoUploader: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const certosSettings = useSelector((state: State) => state.settings);

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateCertosSettings, {
      token,
      ...certosSettings,
    });

  const handleUploadSubmit = (image: File) => {
    // Note postUploadImage will only "save" the image in exchange for an imageLocation (URL) to be used
    // thus after the update a separate call to updateCertosSettings must be done "in the background" which sets the newly uploaded image as certosSettings Logo
    postUploadImage({ token, image })
      .then((result) => {
        if (result && result.imageLocation) {
          // Note: Here the initialization of a ("fake") SubmitHandler via makeSubmitHandler is delayed because of the dependency to result.imageLocation.
          // Why not just use a State? Because the use of a local useState() needed a component update before the triggerUpdateCertosSettings() did it's job with the correct data...
          // Thus makeSubmitHandler was extended with paramsToSubmitHandler property which resolves issues like
          const triggerUpdateCertosSettings = makeSubmitHandler({
            paramsToSubmitHandler: {
              logoImgSrc: result.imageLocation,
            },
            onSuccess: (dispatch, data) => {
              dispatch(setCertosSettings(data));
              setFeedback({
                message: t('LOGO_WAS_UPDATED'),
                msgType: 'success',
              });
            },
          });
          // tigger the update it here..
          triggerUpdateCertosSettings();
        }
      })
      .catch((error) => {
        console.log(error);
        setFeedback({
          message: t('ERROR_UPDATING_IMAGE'),
          msgType: 'error',
        });
      });
  };

  return (
    <TitledCard title={t('LOGO')} iconAvatar={<WallpaperOutlinedIcon />}>
      <Stack>
        <Typography style={{ display: 'block', maxWidth: 540 }}>
          {t('LOGO_UPLOAD_RECOMMENDATION')}
        </Typography>
        <ImageUploader
          defaultPreview={certosSettings.logoImgSrc ?? Logo}
          onUploadSubmit={handleUploadSubmit}
          variant='flexible'
        />
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
      </Stack>
    </TitledCard>
  );
};

export default LogoUploader;

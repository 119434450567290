import React from 'react';
import { Generate } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { schemaWithPrimitivePropsOnly } from '../../../utils';
import Stack from '../../../shared/layouts/Stack';
import { useTranslation } from 'react-i18next';

interface CreateSubMenuRecordFormProps {
  subMenuDefinition: SchemaObject;
  subMenuRecord: any;
  setSubMenuRecord: React.Dispatch<any>;
  setFeedback: React.Dispatch<React.SetStateAction<Feedback>>;
}

const CreateSubMenuRecordForm: React.FC<CreateSubMenuRecordFormProps> = ({
  subMenuDefinition,
  subMenuRecord,
  setSubMenuRecord,
  setFeedback,
}) => {
  const { t } = useTranslation();

  const subMenuDefinitionPrimitivesOnly =
    schemaWithPrimitivePropsOnly(subMenuDefinition);

  const handleFormDataChange = ({ data, _errors }: any) =>
    _errors
      ? setFeedback({
          message: t('FORM_VALIDATION_ERRORS'),
          msgType: 'error',
        })
      : setSubMenuRecord(data);

  return (
    <Stack>
      <JsonForms
        schema={subMenuDefinitionPrimitivesOnly}
        uischema={Generate.uiSchema(subMenuDefinitionPrimitivesOnly)}
        renderers={materialRenderers}
        data={subMenuRecord} // empty form
        cells={materialCells}
        onChange={handleFormDataChange}
      />
    </Stack>
  );
};

export default CreateSubMenuRecordForm;

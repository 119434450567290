import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface FlatJsonViewProps {
  jsonObj: any; // Plain Json Object to display (assuming it's flat)
  style?: React.CSSProperties;
}

function _mapBoolean(
  booleanValue: boolean,
  trueValue = 'TRUE',
  falseValue = 'FALSE'
) {
  return booleanValue === true ? trueValue : falseValue;
}

const useStyles = makeStyles((theme: Theme) => ({
  jsonObj: {
    padding: '0 20px',
    /* borderLeft: '1px solid #999', */
  },
  keyValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  keyContainer: {
    fontWeight: 'bold',
    marginRight: '10px',
    minWidth: 80,
  },
  valueContainer: {
    width: 'auto',
  },
}));

// recursively calls a json Object and displays it's content "jsonOBj = name & jsonObj "
const FlatJsonView: React.FC<FlatJsonViewProps> = (props) => {
  const classes = useStyles();
  const jsonObj = props.jsonObj;
  return (
    <div className={classes.jsonObj} style={props.style}>
      {Object.keys(jsonObj || {})
        .filter((propName) => typeof jsonObj[propName] !== 'object')
        .map((propName) => (
          <div key={`${propName}-root`} className={classes.keyValueContainer}>
            <div key={`${propName}-key`} className={classes.keyContainer}>
              {propName.toUpperCase()}
            </div>
            <div key={`${propName}-val`} className={classes.valueContainer}>
              {typeof jsonObj[propName] === 'boolean'
                ? _mapBoolean(jsonObj[propName])
                : jsonObj[propName]}
            </div>
          </div>
        ))}
    </div>
  );
};

export default FlatJsonView;

import React from 'react';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { useHistory } from 'react-router';
import SubmenuesMenu from './SubmenuesMenu';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import { useTranslation } from 'react-i18next';

interface DeviceProps {
  device: Device;
  onItemClicked?: (device: Device) => void;
}

const DeviceListItem: React.FC<DeviceProps> = ({ device, onItemClicked }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [subMenuAnchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleClickOpenSubMenue = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSubMenue = () => {
    setAnchorEl(null);
  };

  const navigateToDetailPage = () => history.push('/devices/' + device.key);
  const onListItemClicked = onItemClicked
    ? () => onItemClicked(device)
    : navigateToDetailPage;
  return (
    <ListItem
      dense
      alignItems='center'
      button
      divider
      onClick={onListItemClicked}
    >
      <ListItemAvatar>
        <Avatar
          variant='rounded'
          src={device.imgSrc}
          alt={t('DEVICE_DEVICENAME', { deviceName: device.name })}
        >
          {/* Note: Avatar takes the src as default,
                               and the child-icon if src is not defined / not found. */}
          <BrokenImageIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText primary={device.name} secondary={device.serial} />

      <ListItemSecondaryAction>
        {' '}
        {/* Note: ListItemSecondaryAction must be last child if used. */}
        <IconButton
          aria-label='open submenues'
          color='primary'
          onClick={handleClickOpenSubMenue}
        >
          <WidgetsOutlinedIcon />
        </IconButton>
        <SubmenuesMenu
          device={device}
          type='menu'
          anchorEl={subMenuAnchorEl}
          handleClose={handleCloseSubMenue}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DeviceListItem;

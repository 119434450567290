import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDevicesData } from '../../api';
import { setDevicesData } from '../../redux/actionCreators';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import ErrorPage from '../ErrorPage';
import CreateSubMenuRecord from './CreateSubMenuRecord/CreateSubMenuRecord';
import CsvExportButton from './CsvExportButton';
import CsvImportDialog from './CsvImportDialog/CsvImportDialog';
import { ReactComponent as NoData } from '../../shared/static/placeholders/NoDataWoman.svg';
import NoDataPlaceholder from '../../shared/layouts/NoDataPlaceholder';
import { useTranslation } from 'react-i18next';
import getTranslation from '../../utils/getSchemaTranslation';
import JsonExportButton from './JsonExportButton';
import JsonImportDialog from './JsonImportDialog/JsonImportDialog';
import RemoveAllSubMenuDialog from './RemoveSubMenuRecord/RemoveAllSubMenuDialog';
import SubMenuTable from './SubMenuTable';
import CertosAreaChart from '../Widgets/library/CertosAreaChart';
import CertosComposedChart from '../Widgets/library/CertosComposedChart';

const SubMenuPage: React.FC = () => {
  const { t } = useTranslation();

  const { deviceKey, submenueName } = useParams<{
    deviceKey: string;
    submenueName: string;
  }>();

  const token = useSelector((state: State) => state.token);
  const device = useSelector((state: State) =>
    state.devices.find((d) => d.key === deviceKey)
  );
  const deviceData = useSelector((state: State) => state.devicesData);
  const [pagination] = useState(1); // Todo implement a pagination feature on the page, that can change this.

  const dispatch = useDispatch();
  useEffect(() => {
    getDevicesData({
      token: token!,
      deviceKey,
      submenueName,
      pagination,
    })
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          dispatch(setDevicesData(data));
        }
      })
      .catch(console.log);
  }, [dispatch, token, deviceKey, submenueName, pagination]);

  let xAxisValue = 'elementId';
  let yAxisValue = 'value';
  let shouldUseComposedChart = false;

  const subMenuDefinition =
    device && device.device_submenues
      ? device.device_submenues[submenueName]
      : null;

  let data = deviceData;

  const isWidget = subMenuDefinition?.isWidget;
  if (deviceData !== null && deviceData !== undefined) {
    if (deviceData[0].hasOwnProperty('entries')) {
      xAxisValue = 'ts';
      yAxisValue = 'prs';
      shouldUseComposedChart = true;
      data = deviceData[0].entries;
    }
  }

  if (subMenuDefinition === null) return <ErrorPage />;
  else
    return (
      <DefaultPageLayout
        title={getTranslation(
          subMenuDefinition,
          'title',
          submenueName.toUpperCase()
        )}
      >
        <Stack>
          <Grid container justifyContent='space-between'>
            <CreateSubMenuRecord
              submenueName={submenueName}
              subMenuDefinition={subMenuDefinition}
              deviceKey={deviceKey}
            />
            <RemoveAllSubMenuDialog
              deviceKey={deviceKey}
              subMenuName={submenueName}
              subMenuDefinition={subMenuDefinition}
              pagination={pagination}
            />
            <JsonImportDialog
              deviceKey={deviceKey}
              subMenuDefinition={subMenuDefinition}
              subMenuName={submenueName}
              pagination={pagination}
            />
            <JsonExportButton
              deviceKey={deviceKey}
              subMenuName={submenueName}
            />
            <CsvImportDialog
              deviceKey={deviceKey}
              subMenuDefinition={subMenuDefinition}
              subMenuName={submenueName}
            />
            <CsvExportButton deviceKey={deviceKey} subMenuName={submenueName} />
          </Grid>
          <NoDataPlaceholder
            noData={!deviceData || deviceData.length === 0}
            placeholder={{
              image: <NoData />,
              textOverlay: {
                title: t('SUBMENUE_NO_DATA_YET'),
                subTitle: t('SUBMENUE_NO_DATA_YET_CTA'),
              },
            }}
          >
            {shouldUseComposedChart ? (
              <CertosComposedChart
                title='Chart'
                data={data}
                xAxis={xAxisValue}
                yAxis={yAxisValue}
              />
            ) : isWidget ? (
              <CertosAreaChart
                title='Chart'
                data={data}
                xAxis={xAxisValue}
                yAxis={yAxisValue}
              />
            ) : (
              <SubMenuTable
                deviceKey={deviceKey}
                submenueName={submenueName}
                subMenuDefinition={subMenuDefinition}
                subMenuData={deviceData || []}
              />
            )}
          </NoDataPlaceholder>
        </Stack>
      </DefaultPageLayout>
    );
};

export default SubMenuPage;

console.log(process.env);
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:9000/api';

async function apiCall(
  url = '',
  authToken: string | null,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  bodyData?: any
) {
  const isMultiPartFormData = bodyData && bodyData instanceof FormData;
  const headers: any = { token: `JWT ${authToken}` };
  if (!isMultiPartFormData) {
    headers['Content-Type'] = 'application/json';
  }
  const body = isMultiPartFormData ? bodyData : JSON.stringify(bodyData);

  const response = await fetch(url, {
    method: method,
    mode: 'cors',
    headers,
    body,
    credentials: 'include',
  });
  console.log(response.headers.get('Content-Type'));

  return response.headers.get('Content-Type')?.includes('application/json')
    ? response.json()
    : response.blob();
}

export async function refreshToken() {
  const apiUrl = apiBaseUrl + '/users/refreshtoken';
  type RefreshTokenResult = LoginResultType;
  const data: RefreshTokenResult = await apiCall(apiUrl, null, 'POST');
  return data;
}

export async function login({ email, password }: LoginUserCommand) {
  const apiUrl = apiBaseUrl + '/users/login';
  const data: LoginResultType = await apiCall(apiUrl, null, 'POST', {
    email,
    password,
  });
  return data;
}

export async function logoutUser() {
  const apiUrl = apiBaseUrl + '/users/logout';
  const data: LoginResultType = await apiCall(apiUrl, null, 'POST');
  return data;
}

export async function register({
  firstname,
  lastname,
  email,
  password,
  isSteri24User,
}: RegisterUserCommand) {
  const apiUrl = apiBaseUrl + '/users/register';
  const data: RegisterResultType = await apiCall(apiUrl, null, 'POST', {
    firstname,
    lastname,
    email,
    password,
    isSteri24User,
  });
  return data;
}

export async function forgotPassword({ email }: ForgotPasswordCommand) {
  const apiUrl = apiBaseUrl + '/users/forgotPassword';
  const data: ApiResult<void> = await apiCall(apiUrl, null, 'POST', {
    email,
  });
  return data;
}

export async function resetPassword({
  token,
  newPassword,
}: ResetPasswordCommand) {
  const apiUrl = apiBaseUrl + '/users/resetPassword';
  const data: ApiResult<void> = await apiCall(apiUrl, token, 'POST', {
    newPassword,
  });
  return data;
}

export async function activateUserAccount({
  token,
}: ActivatUserAccountCommand) {
  const apiUrl = apiBaseUrl + '/users/activateAccount';
  const data: ApiResult<void> = await apiCall(apiUrl, token, 'GET');
  return data;
}

export async function getDevices(token: string) {
  const apiUrl = apiBaseUrl + '/devices';
  const devices: ApiResult<Device[]> = await apiCall(apiUrl, token, 'GET');
  return devices;
}

export async function removeDevice({ token, deviceId }: RemoveDeviceCommand) {
  const apiUrl = apiBaseUrl + '/devices/' + deviceId;
  const devices: ApiResult<Device[]> = await apiCall(apiUrl, token, 'DELETE');
  return devices;
}

export async function getDevicesData({
  token,
  submenueName,
  deviceKey,
  pagination,
}: GetDeviceDataCommand) {
  const apiUrl =
    apiBaseUrl +
    '/devices/data/' +
    submenueName +
    '/' +
    deviceKey +
    '/' +
    pagination;
  const result: ApiResult<DevicesData> = await apiCall(apiUrl, token, 'GET');
  return result;
}
export async function csvExportDeviceData({
  token,
  subMenuName,
  deviceKey,
}: CsvExportSubMenuDataCommand) {
  const apiUrl =
    apiBaseUrl + '/devices/data/csvExport/' + subMenuName + '/' + deviceKey;
  const result: ApiResult<Blob> = await apiCall(apiUrl, token, 'GET');
  return result;
}

export async function jsonExportDeviceData({
  token,
  subMenuName,
  deviceKey,
}: JsonExportSubMenuDataCommand) {
  const apiUrl =
    apiBaseUrl + '/devices/data/jsonExport/' + subMenuName + '/' + deviceKey;
  const result: ApiResult<Blob> = await apiCall(apiUrl, token, 'GET');
  return result;
}

export async function csvImportDeviceData({
  token,
  csvData,
  csvFileName,
  shouldUpdateDuplicateRecords,
  subMenuName,
  deviceKey,
}: CsvImportSubMenuDataCommand) {
  const apiUrl =
    apiBaseUrl + '/devices/data/csvImport/' + subMenuName + '/' + deviceKey;
  const result: ApiResult<DevicesData> = await apiCall(apiUrl, token, 'POST', {
    csvData,
    csvFileName,
    shouldUpdateDuplicateRecords,
  });
  return result;
}

export async function jsonImportDeviceData({
  token,
  jsonData,
  jsonFileName,
  shouldUpdateDuplicateRecords,
  subMenuName,
  deviceKey,
}: JsonImportSubMenuDataCommand) {
  const apiUrl =
    apiBaseUrl + '/devices/data/jsonImport/' + subMenuName + '/' + deviceKey;
  const result: ApiResult<DevicesData> = await apiCall(apiUrl, token, 'POST', {
    jsonData,
    jsonFileName,
    shouldUpdateDuplicateRecords,
  });
  return result;
}

export async function postDeviceData({
  token,
  submenueName,
  deviceKey,
  dataPayload,
}: AddDeviceDataCommand) {
  const apiUrl = apiBaseUrl + '/devices/data/';
  const result: ApiResult<DevicesData> = await apiCall(apiUrl, token, 'POST', {
    submenueName,
    deviceKey,
    dataPayload,
  });
  return result;
}

export async function editDeviceData({
  token,
  subMenuName,
  deviceKey,
  recordId,
  recordEditInfo,
}: EditDeviceDataCommand) {
  const apiUrl = apiBaseUrl + '/devices/data/';
  const result: ApiResult<unknown> = await apiCall(apiUrl, token, 'PUT', {
    subMenuName,
    deviceKey,
    recordId,
    recordEditInfo,
  });
  return result;
}

//removes a single record from a submenu
export async function removeDeviceData({
  token,
  subMenuName,
  deviceKey,
  recordId,
}: DeleteDeviceDataCommand) {
  const apiUrl = apiBaseUrl + '/devices/data/';
  const result: ApiResult<unknown> = await apiCall(apiUrl, token, 'DELETE', {
    subMenuName,
    deviceKey,
    recordId,
  });
  return result;
}

//removes all records from a submenu
export async function removeAllDeviceDataFromSubmenu({
  token,
  subMenuName,
  deviceKey,
}: DeleteAllDeviceDataFromSubmenuCommand) {
  const apiUrl = apiBaseUrl + '/devices/data/submenu';
  const result: ApiResult<unknown> = await apiCall(apiUrl, token, 'DELETE', {
    subMenuName,
    deviceKey,
  });
  return result;
}

export async function getUserProfile(token: string) {
  const apiUrl = apiBaseUrl + '/users/profile';
  const userProfile: ApiResult<UserProfile> = await apiCall(
    apiUrl,
    token,
    'GET'
  );
  return userProfile;
}

export async function updateUserProfile({
  token,
  firstname,
  lastname,
  email,
  imgSrc,
}: UpdateProfileCommand) {
  const apiUrl = apiBaseUrl + '/users/profile';
  const updateInfo = { firstname, lastname, email, imgSrc };
  const result: ApiResult<{
    oldProfile: UserProfile;
    updatedProfile: UserProfile;
  }> = await apiCall(apiUrl, token, 'PUT', updateInfo);

  return result;
}

export async function changeUserPassword({
  token,
  oldPassword,
  newPassword,
}: ChangePasswordCommand) {
  const apiUrl = apiBaseUrl + '/users/changePassword';
  const updateInfo = { oldPassword, newPassword };
  const result: ApiResult<{
    oldProfile: UserProfile;
    updatedProfile: UserProfile;
  }> = await apiCall(apiUrl, token, 'PUT', updateInfo);

  return result;
}

export async function fetchCertosSettings() {
  const apiUrl = apiBaseUrl + '/certos-settings';
  const result: ApiResult<CertosSettings> = await apiCall(apiUrl, null, 'GET');

  return result;
}

export async function updateCertosSettings(
  certosSettings: UpdateCertosSettingsCommand
) {
  const apiUrl = apiBaseUrl + '/certos-settings';
  const result: ApiResult<CertosSettings> = await apiCall(
    apiUrl,
    certosSettings.token,
    'POST',
    certosSettings
  );

  return result;
}

export async function getAllUsers(token: string) {
  const apiUrl = apiBaseUrl + '/users';
  const result: ApiResult<{ userList: UserProfile[] }> = await apiCall(
    apiUrl,
    token,
    'GET'
  );

  return result;
}

export async function updateUserAccess(
  userAccessInfo: UpdateUserAccessCommand
) {
  const apiUrl = apiBaseUrl + '/users/access';
  const result: ApiResult<UpdateUserAccessCommand> = await apiCall(
    apiUrl,
    userAccessInfo.token,
    'PUT',
    userAccessInfo
  );

  return result;
}

export async function getDevicesOfUser(listDevicesInfo: ListDevicesCommand) {
  const apiUrl = apiBaseUrl + '/devices/' + listDevicesInfo.userId;
  const result: ApiResult<Device[]> = await apiCall(
    apiUrl,
    listDevicesInfo.token,
    'GET'
  );

  return result;
}

export async function getAuditTrail(listAuditTrail: ListAuditTrailCommand) {
  const apiUrl = apiBaseUrl + '/auditTrail';
  const queryStringUrl = new URL(apiUrl);
  const params: any = {
    pagination: listAuditTrail.pagination,
    pageSize: listAuditTrail.pageSize,
    ...listAuditTrail.auditTrailEventFilter,
    ...listAuditTrail.auditTrailSortModel,
  };

  Object.keys(params).forEach((key: string) => {
    const value = params[key];
    key && value && queryStringUrl.searchParams.append(key, value);
  });

  console.log(queryStringUrl.toString());

  const result: ApiResult<AuditTrailEventsAndMeta> = await apiCall(
    queryStringUrl.toString(),
    listAuditTrail.token,
    'GET'
  );

  return result;
}

export async function csvExportAuditTrail({ token }: { token: string }) {
  const apiUrl = apiBaseUrl + '/auditTrail/csvExport';
  const result: ApiResult<Blob> = await apiCall(apiUrl, token, 'GET');
  return result;
}

export async function postCommentOnAuditTrailEvent({
  token,
  eventId,
  comment,
}: {
  token: string;
  eventId: string;
  comment: string;
}) {
  const apiUrl = apiBaseUrl + '/auditTrail/comment';
  const result: ApiResult<void> = await apiCall(apiUrl, token, 'POST', {
    eventId,
    comment,
  });
  return result;
}

export async function listOnlineDevices({ token }: AuthRequiredCommand) {
  const apiUrl = apiBaseUrl + '/real-time/devices/online';
  const result: ApiResult<string[]> = await apiCall(apiUrl, token, 'GET');
  return result;
}

export async function getDeviceConfigurationSchema({
  token,
  deviceKey,
}: GetDeviceConfigurationSchema) {
  const apiUrl = apiBaseUrl + '/real-time/devices/configSchema/' + deviceKey;
  const result: ApiResult<SchemaObject> = await apiCall(apiUrl, token, 'GET');
  return result;
}

export async function configureDeviceRealTime({
  token,
  deviceKey,
  config,
}: ConfigureDeviceRealTimeCommand) {
  const apiUrl = apiBaseUrl + '/real-time/devices/configure';
  const result: ApiResult<void> = await apiCall(apiUrl, token, 'POST', {
    deviceKey,
    config,
  });
  return result;
}

export async function sendConsoleCmdRealTime({
  token,
  deviceKey,
  commandString,
}: SendConsoleCmdRealTimeCommand) {
  const apiUrl = apiBaseUrl + '/real-time/devices/sendConsoleCmd';
  const result: ApiResult<any> = await apiCall(apiUrl, token, 'POST', {
    deviceKey,
    commandString,
  });
  return result;
}

export async function contactSupport({
  token,
  subject,
  message,
}: ContactSupportCommand) {
  const apiUrl = apiBaseUrl + '/contactSupport';

  interface SupportTicketResponse {
    supportTicketNumber: string;
    subject: string;
    message: string;
  }

  console.log(subject, message);

  const result: ApiResult<SupportTicketResponse> = await apiCall(
    apiUrl,
    token,
    'POST',
    { subject, message }
  );
  return result;
}

export async function postUploadImage({
  token,
  image,
}: {
  token: string;
  image: File;
}) {
  const apiUrl = apiBaseUrl + '/uploadImage';

  const imageAsFormData = new FormData();
  imageAsFormData.append('image', image, image.name);

  const result: ApiResult<{ imageLocation: string }> = await apiCall(
    apiUrl,
    token,
    'POST',
    imageAsFormData
  );
  return result;
}

export async function postExportSubMenuDataAsPdf({
  token,
  deviceKey,
  subMenuName,
  recordId,
  password,
}: ExportSubMenuVerifiablePdfCommand) {
  const apiUrl = `${apiBaseUrl}/devices/data/pdfExport`;
  const result: ApiResult<Blob> = await apiCall(apiUrl, token, 'POST', {
    subMenuName,
    deviceKey,
    recordId,
    password,
  });
  return result;
}

export async function postVerifyPdf({ token, pdfFile }: VerifyPdfCommand) {
  const apiUrl = `${apiBaseUrl}/devices/data/verifyPdf`;

  const pdfAsFormDataBody = new FormData();
  pdfAsFormDataBody.append('pdf', pdfFile, pdfFile.name);
  const result: ApiResult<void> = await apiCall(
    apiUrl,
    token,
    'POST',
    pdfAsFormDataBody
  );
  return result;
}

export async function postRegisterDevice({
  token,
  ...deviceInfo
}: RegisterDeviceCommand) {
  const apiUrl = `${apiBaseUrl}/devices`;
  const result: ApiResult<Device> = await apiCall(
    apiUrl,
    token,
    'POST',
    deviceInfo
  );
  return result;
}

export async function getUserNotifications(token: string) {
  const apiUrl = apiBaseUrl + '/users/notifications';
  const notifications: ApiResult<UserNotification[]> = await apiCall(
    apiUrl,
    token,
    'GET'
  );
  return notifications;
}

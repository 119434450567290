import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Login from '../Pages/Login';
import Register from '../Pages/Register';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import ActivateAccount from '../Pages/ActivateAccount';

import Dashboard from '../Pages/Dashboard';
import Devices from '../Pages/Devices';
import DeviceDetailView from '../Pages/Devices/DeviceDetailView';
import RemoteAccessPage from '../Pages/Devices/RemoteAccess/RemoteAccessPage';
import SubMenuPage from '../Pages/SubMenu';
import SubMenuDetailPage from '../Pages/SubMenu/SubMenuDetailPage';
import AuditTrailPage from '../Pages/AuditTrail';
import AuditTrailDetailPage from '../Pages/AuditTrail/AuditTrailDetailPage';
import UsersPage from '../Pages/Users';
import UsersDetailView from '../Pages/Users/UsersDetailView';
import ToolsPage from '../Pages/Tools';
import SettingsPage from '../Pages/Settings';
import RuleEngine from '../Pages/RuleEngine';
import ProfilePage from '../Pages/Profile';
import WidgetsPage from '../Pages/Widgets';
import SupportPage from '../Pages/Support';

import ImprintPage from '../Pages/Imprint';

import ErrorPage from '../Pages/ErrorPage';

import { RouteNames } from './RouteNames';
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Redirect exact from='/' to={RouteNames.DASHBOARD} />

      <Route path={RouteNames.LOGIN} component={Login} />
      <Route path={RouteNames.REGISTER} component={Register} />
      <Route path={RouteNames.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route
        path={RouteNames.RESET_PASSWORD + '/:passwordResetToken'}
        component={ResetPassword}
      />
      <Route
        path={RouteNames.ACTIVATE_ACCOUNT + '/:accountActivationToken'}
        component={ActivateAccount}
      />

      <ProtectedRoute
        path={RouteNames.DASHBOARD}
        component={Dashboard}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.DEVICES}
        component={Devices}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.DEVICES + '/:deviceKey'}
        component={DeviceDetailView}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.DEVICES + '/:deviceKey/remoteAccess'}
        component={RemoteAccessPage}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.DEVICES + '/:deviceKey/:submenueName'}
        component={SubMenuPage}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.DEVICES + '/:deviceKey/:submenueName/:subMenuDataId'}
        component={SubMenuDetailPage}
        exact={true}
      />
      <ProtectedRoute
        minAuthRole='manufacturer'
        path={RouteNames.USERS_MANAGEMENT}
        component={UsersPage}
        exact={true}
      />
      <ProtectedRoute
        minAuthRole='manufacturer'
        path={RouteNames.USERS_MANAGEMENT + '/:userId'}
        component={UsersDetailView}
      />
      <ProtectedRoute path={RouteNames.TOOLS} component={ToolsPage} />
      <ProtectedRoute
        path={RouteNames.AUDIT_TRAIL}
        component={AuditTrailPage}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.AUDIT_TRAIL + '/:eventId'}
        component={AuditTrailDetailPage}
        exact={true}
      />
      <ProtectedRoute
        minAuthRole='manufacturer'
        path={RouteNames.SETTINGS}
        component={SettingsPage}
        exact={true}
      />
      <ProtectedRoute
        minAuthRole='manufacturer'
        path={RouteNames.RULE_ENGINE}
        component={RuleEngine}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.PROFILE}
        component={ProfilePage}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.WIDGETS_LIBRARY}
        component={WidgetsPage}
        exact={true}
      />
      <ProtectedRoute
        path={RouteNames.SUPPORT}
        component={SupportPage}
        exact={true}
      />

      <Route path={RouteNames.IMPRINT} component={ImprintPage} exact={true} />
      <Route component={ErrorPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;

import React from 'react';
import { useSelector } from 'react-redux';

import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';

import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import DevicesGridView from '../../shared/components/DevicesGridView';
import Stack from '../../shared/layouts/Stack';
import { useTranslation } from 'react-i18next';
import Section from '../../shared/layouts/Section';
import IconCardSection from '../../shared/layouts/IconCardSection';
import IconCardLink from '../../shared/components/IconCardLink';
import LatestEventsTimeline from './LatestEventsTimeline';
import TwoColumnResponsiveGrid from './TwoColumnResponsiveGrid';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { RouteNames } from '../../routing/RouteNames';
import IconCard from '../../shared/components/IconCard';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const devices = useSelector((state: State) => state.devices);
  const devicesToShow = [...devices].reverse().slice(0, 5);

  const history = useHistory();

  return (
    <DefaultPageLayout title={t('DASHBOARD')}>
      <Stack spacing={2}>
        <Section title={t('LATEST_DEVICES')}>
          <DevicesGridView devices={devicesToShow} />
        </Section>

        <TwoColumnResponsiveGrid>
          <Section title={t('LATEST_EVENTS')}>
            <LatestEventsTimeline />
            <Button
              variant='text'
              onClick={() => history.push(RouteNames.AUDIT_TRAIL)}
            >
              {t('Go to AuditTrail')}
            </Button>
          </Section>

          <IconCardSection title={t('LATEST_IN_SUPPORT')}>
            <IconCardLink
              href='https://certos-files.s3.eu-central-1.amazonaws.com/001+Security+%26+Encryption+.pdf'
              title={t('SECURITY_WHITEPAPER')}
              icon={<SecurityOutlinedIcon />}
            />
            <IconCardLink
              href='https://certos-files.s3.eu-central-1.amazonaws.com/002+FDA+Whitepaper.pdf'
              title={t('FDA_WHITEPAPER')}
              icon={<LocalHospitalOutlinedIcon />}
            />
            <IconCardLink
              href='https://certos-core-backend-staging.herokuapp.com/api/docs/'
              title='Cloud Documentation'
              icon={<LayersOutlinedIcon />}
            />
            <div style={{ cursor: 'pointer' }}>
              <IconCard
                title='Go to Support'
                icon={<LiveHelpOutlinedIcon />}
                onClick={() => history.push(RouteNames.SUPPORT)}
              />
            </div>
          </IconCardSection>
        </TwoColumnResponsiveGrid>
      </Stack>
    </DefaultPageLayout>
  );
};

export default Dashboard;

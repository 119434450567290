import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Stack from '../../shared/layouts/Stack';
import { changeUserPassword } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import TitledCard from '../../shared/components/TitledCard';
import { useInputValidation } from '../../hooks/useInputValidation';
import { usePasswordInputValidation } from '../../hooks/usePasswordInputValidation';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { useTranslation } from 'react-i18next';

interface ChangePasswordFormProps {}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = () => {
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');

  const [oldPwError, oldPwHelperText, validateOldPassword] =
    usePasswordInputValidation(oldPassword);

  const [newPwError, newPwHelperText, validateNewPassword] =
    usePasswordInputValidation(newPassword);

  const passwordConfirmationValidations = [
    {
      errorCase: (pwConfirm: string) => pwConfirm !== newPassword,
      helperText: t('PASSWORD_CONFIRMATION_MUST_MATCH'),
    },
  ];
  const [pwMatchError, pwMatchHelperText, validatePasswordMatch] =
    useInputValidation(repeatNewPassword, passwordConfirmationValidations);

  const invalidInputs: boolean =
    oldPassword === '' ||
    newPassword === '' ||
    repeatNewPassword === '' ||
    oldPwError ||
    newPwError ||
    pwMatchError;

  const token = useSelector((state: State) => state.token)!;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(changeUserPassword, {
      token,
      oldPassword: oldPassword!,
      newPassword: newPassword!,
    });

  const handleSaveClick = makeSubmitHandler({
    preChecker: () => {
      const tryingPasswordChange = oldPassword && newPassword;

      if (!tryingPasswordChange) {
        setFeedback({
          message: t('YOU_HAVENT_CHANGED_ANYTHING'),
          msgType: 'info',
        });
        return false;
      }

      if (newPassword!.length < 4) {
        setFeedback({
          message: t('YOUR_PASSWORD_IS_TO_SHORT'),
          msgType: 'error',
        });
        return false;
      } else if (newPassword !== repeatNewPassword) {
        setFeedback({ message: t('PASSWORDS_MUST_MATCH'), msgType: 'error' });
        return false;
      }

      return true;
    },
    onSuccess: () => {
      setFeedback({
        message: t('PASSWORD_SUCCESSFULLY_CHANGED'),
        msgType: 'success',
      });
    },
  });

  return (
    <TitledCard
      title={t('CHANGE_PASSWORD')}
      iconAvatar={<VpnKeyOutlinedIcon />}
    >
      <form>
        <Stack>
          <TextField
            fullWidth
            label={t('CURRENT_PASSWORD')}
            variant='standard'
            type='password'
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            onBlur={validateOldPassword}
            error={oldPwError}
            helperText={oldPwHelperText}
          />
          <TextField
            fullWidth
            label={t('NEW_PASSWORD')}
            variant='standard'
            type='password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onBlur={validateNewPassword}
            error={newPwError}
            helperText={newPwHelperText}
          />
          <TextField
            fullWidth
            label={t('REPEAT_NEW_PASSWORD')}
            variant='standard'
            type='password'
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
            onBlur={validatePasswordMatch}
            error={pwMatchError}
            helperText={pwMatchHelperText}
          />

          <FormSubmitButton
            btnName={t('CHANGE_PASSWORD')}
            handleSubmit={handleSaveClick}
            invalidInputs={invalidInputs}
            loading={loading}
            feedback={feedback}
          />
        </Stack>
      </form>
    </TitledCard>
  );
};

export default ChangePasswordForm;

import React, { useState } from 'react';
import {
  Avatar,
  Button,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import LargeAvatar from './LargeAvatar';
import Stack from '../layouts/Stack';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { fileSizeAsHumanReadable } from '../../utils';
import useSyncedState from '../../hooks/useSyncedState';
import { useTranslation } from 'react-i18next';

interface ImageUploaderProps {
  defaultPreview?: string;
  onUploadSubmit: (file: File) => void;
  filePickerBtnText?: string;
  submitBtnText?: string;
  variant?: 'circular' | 'rounded' | 'square' | 'flexible';
  profileAvatar?: boolean; // used to display profile pic if img was not found, else uses a broken image
  uploadButtonDisabled?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  defaultPreview,
  onUploadSubmit,
  filePickerBtnText,
  submitBtnText,
  variant,
  profileAvatar,
  uploadButtonDisabled = false,
}) => {
  const { t } = useTranslation();

  const [imgPreviewUrl, setImgPreviewUrl] = useSyncedState<string | undefined>(
    defaultPreview
  );
  const [file, setFile] = useState<File>();

  const fileName = file?.name;
  const fileSize = file?.size;

  const previewImage = (file: File) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const imageUrl = event.target?.result as string;
      setImgPreviewUrl(imageUrl);
    };

    reader.readAsDataURL(file); // base64
  };

  const Preview =
    variant === 'flexible' ? (
      <ImagePreviewer src={imgPreviewUrl!} />
    ) : (
      <LargeAvatar
        bordered
        src={imgPreviewUrl!}
        variant={variant}
        profileAvatar={profileAvatar}
      />
    );

  return (
    <Stack>
      <>
        {imgPreviewUrl && Preview}
        {fileName && <Typography>{fileName!}</Typography>}
        {fileSize && (
          <Typography>{fileSizeAsHumanReadable(fileSize!)}</Typography>
        )}
      </>

      <>
        <Button component='label'>
          {filePickerBtnText ?? t('CHOOSE_IMAGE')}
          <input
            type='file'
            hidden
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                setFile(file);
                previewImage(file);
              }
            }}
          />
        </Button>
        {fileName && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => onUploadSubmit(file!)}
            endIcon={<CloudUploadOutlinedIcon />}
            disabled={uploadButtonDisabled}
          >
            {submitBtnText ?? t('UPLOAD')}
          </Button>
        )}
      </>
    </Stack>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgPreview: {
    background: theme.palette.primary.main,
    // setting minWidth and width at the same time makes it a square by default but extendable if necessary
    width: 'fit-content',
    minWidth: theme.spacing(20),
    height: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
}));

interface ImagePreviewerProps {
  src: string;
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({ src }) => {
  const classes = useStyles();
  return (
    <Avatar src={src} className={classes.imgPreview} variant='rounded'></Avatar>
  );
};

export default ImageUploader;

import React from 'react';
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  RadialBarChart,
  RadialBar,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { sampleRadialBarChartData } from './sampleData';

const RadialBarChartSamples: React.FC = () => {
  return (
    <>
      <TitledCard title='RadialBarChart'>
        <ResponsiveContainer width='100%' height={400}>
          <RadialBarChart
            width={730}
            height={250}
            innerRadius='10%'
            outerRadius='100%'
            data={sampleRadialBarChartData}
            startAngle={160}
            endAngle={0}
          >
            <RadialBar
              startAngle={40}
              label={{ fill: '#666', position: 'insideStart' }}
              background
              direction='clock-wise'
              dataKey='uv'
            />
            <Legend
              iconSize={10}
              width={120}
              height={140}
              layout='vertical'
              verticalAlign='bottom'
              align='right'
            />
            <Tooltip />
          </RadialBarChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default RadialBarChartSamples;

export const dummy_devices: Device[] = [
  {
    name: 'AutoClave 1',
    type: 'AUTOCLAVE',
    isOnline: true,
    serial: '0987654678',
    key: '32457689',
    device_submenues: {
      protocols: {
        title: 'Protocols',
        properties: {
          text: { type: 'string' },
          otherText: { type: 'string' },
          numba: { type: 'number' },
          boulaen: { type: 'boolean' },
        },
      },
      conditions: {
        title: 'Conditions',
        properties: {
          conditionText: { type: 'string' },
          conditionOtherText: { type: 'string' },
          conditionNumba: { type: 'number' },
          conditionBoulaen: { type: 'boolean' },
        },
      },
    },
    imgSrc:
      'https://www.letslab.co.uk/pub/media/catalog/product/cache/c687aa7517cf01e65c009f6943c2b1e9/4/0/4001728_1.jpg',
    _id: '12345',
  },
  {
    name: 'AutoClave 2',
    type: 'AUTOCLAVE',
    isOnline: false,
    serial: '0987654678',
    key: '87654833',
    imgSrc:
      'https://www.letslab.co.uk/pub/media/catalog/product/cache/c687aa7517cf01e65c009f6943c2b1e9/4/0/4001728_1.jpg',
    _id: '54321',
  },
];

export const dummy_users: UserProfile[] = [
  {
    _id: '603aadb2f915bf0a8b824ecc',
    firstname: 'Ahmed',
    lastname: 'Nasr',
    email: 'work@ahmednasr.at',
    role: 'certos_admin',
    createdOn: 1234,
    imgSrc:
      'https://www.ahmednasr.at/wp-content/uploads/2020/05/ahmednasr-small-1.jpg',
    devices: dummy_devices.map((d) => d._id),
    isPremium: true,
    isBlocked: false,
    passwordLastChange: 0,
  },
  {
    _id: '603f0a8b824eccaadb2f915b',
    firstname: 'Michael',
    lastname: 'Dirix',
    email: 'michael.dirix@certos.ai',
    role: 'certos_admin',
    createdOn: 1234,
    imgSrc: 'https://certos.b-cdn.net/wp-content/uploads/2021/01/f-1.png',
    devices: dummy_devices.map((d) => d._id),
    isPremium: true,
    isBlocked: false,
    passwordLastChange: 0,
  },
];

export const dummy_profile: UserProfile = dummy_users[0];

export const dummy_settings: CertosSettings = {
  logoImgSrc: '',
  // 'https://certos-pictures.s3.eu-central-1.amazonaws.com/a2bcd945-b5cb-417b-b34f-ff08f71c8e92-Certos-Landscape-Logo-cloud.jpg',
  themeColors: {
    primaryColorMain: '#0c263d',
    secondaryColorMain: '#dc004e',
    errorColorMain: '#f44336',
    warningColorMain: '#ff9800',
    infoColorMain: '#2196f3',
    successColorMain: '#4caf50',
  },
  companyInfo: {
    companyName: 'Certos GmbH',
    email: 'support@certoclav.com',
    phone: '+43 (0) 732 674 278',
    fax: '+43 (0) 732 674 278 – 20',
    address: 'Peintnerstrasse 10',
    address2: '4060 Leonding Austria',
    ceoName: 'Michel Dirix',
    moreInfo: '\n\n\n',
  },
  securitySettings: {
    automaticLogoutActive: false,
    logoutTimeoutMinutes: 5,
    forceReLoginHours: 24,
    maxLoginRetries: 3,
    passwordExpActive: true,
    passwordLifespanDays: 365,
    passwordExpWarningDays: 30,
    electronicSignaturesActive: false,
    auditReasonPromptActive: false,
  },
};

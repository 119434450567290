import React from 'react';
import RegistrationForm from './RegistrationForm';
import CenteredFormLayout from '../../shared/layouts/CenteredFormLayout';
import { useTranslation } from 'react-i18next';

const Register: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredFormLayout title={t('REGISTRATION')} form={RegistrationForm} />
  );
};

export default Register;

import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';

import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../routing/RouteNames';
import { register } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { useEmailInputValidation } from '../../hooks/useEmailInputValidation';
import { usePasswordInputValidation } from '../../hooks/usePasswordInputValidation';
import { useInputValidation } from '../../hooks/useInputValidation';
import { useTranslation } from 'react-i18next';

const RegistrationForm: React.FC = () => {
  const { t } = useTranslation();

  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isSteri24User, setSteri24User] = useState<boolean>(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  const [emailError, emailHelperText, validateAsEmail] =
    useEmailInputValidation(email);

  const [pwError, pwHelperText, validateAsPassword] =
    usePasswordInputValidation(password);

  const passwordConfirmationValidations = [
    {
      errorCase: (pwConfirm: string) => pwConfirm !== password,
      helperText: t('PASSWORD_CONFIRMATION_MUST_MATCH'),
    },
  ];
  const [pwMatchError, pwMatchHelperText, validateAsPasswordMatch] =
    useInputValidation(passwordConfirmation, passwordConfirmationValidations);

  const invalidInputs: boolean =
    firstname === '' ||
    lastname === '' ||
    email === '' ||
    password === '' ||
    passwordConfirmation === '' ||
    emailError ||
    pwError ||
    pwMatchError;

  const [formSubmitDoneSuccess, setFormSubmitDoneSuccess] = useState(false);
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(register, {
      firstname,
      lastname,
      email,
      password,
      isSteri24User,
    });

  useEffect(() => {
    const queryString = window.location.search;

    if (queryString) {
      // Parse the query string to get the parameter
      const urlParam = new URLSearchParams(queryString);
      const isSteri24User = urlParam.get('is_steri24_user');

      if (urlParam) {
        setSteri24User(isSteri24User === 'true');
      }
    }
  }, []);

  const handleRegistrationSubmit = makeSubmitHandler({
    preChecker: () => {
      if (password !== passwordConfirmation) {
        setFeedback({ message: t('PASSWORDS_MUST_MATCH'), msgType: 'error' });
        return false;
      }
      return true;
    },
    onSuccess: () => {
      setFeedback({
        message: t('SUCCESSFULLY_REGISTERED'),
        msgType: 'success',
      });
      setFormSubmitDoneSuccess(true);
    },
  });

  return (
    <form
      className={'register-form'}
      onSubmit={handleRegistrationSubmit}
      autoComplete='off'
    >
      <Stack>
        <TextField
          fullWidth
          label={t('FIRSTNAME')}
          variant='standard'
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <TextField
          fullWidth
          label={t('LASTNAME')}
          variant='standard'
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <TextField
          fullWidth
          label={t('EMAIL')}
          variant='standard'
          type='email' // TODO investiate why form is not checking validity (removed the noValidate flag that was on form)
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateAsEmail}
          error={emailError}
          helperText={emailHelperText}
        />
        <TextField
          fullWidth
          label={t('PASSWORD')}
          variant='standard'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={validateAsPassword}
          error={pwError}
          helperText={pwHelperText}
        />
        <TextField
          fullWidth
          label={t('PASSWORD_CONFIRMATION')}
          variant='standard'
          type='password'
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          onBlur={validateAsPasswordMatch}
          error={pwMatchError}
          helperText={pwMatchHelperText}
        />

        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleRegistrationSubmit}
          endIcon={loading && <LoopOutlinedIcon />}
          disabled={loading || invalidInputs}
        >
          {formSubmitDoneSuccess ? t('RESEND') : t('CREATE_ACCOUNT')}
        </Button>
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
        <>
          {t('ALREADY_HAVE_AN_ACCOUNT')}{' '}
          <Link to={RouteNames.LOGIN}>{t('GO_TO_LOGIN')}</Link>
        </>
      </Stack>
    </form>
  );
};

export default RegistrationForm;

import i18next from 'i18next';

/* 
  Tries to return the value of a translated for a property title.
  
  For the following example....
   def = {
      title: "Age",
      translations: { en: "Age", de: "Alter" }
      // ... more info on age like type, and validation constraints
    }
  
  getTranslatedPropTitle(def, "title", "age") will return "Age" for english or "Alter" for german.
  If the desired languages was not specified (or the entire translation object) then the default title "Age"
  If the title was also not specified, then the propName (age) will be used.
*/
const getSchemaTranslation = (
  propertyDef: any,
  targetPropName: string,
  defaultValue: string
) => {
  // propertyDef should not be falsy value or just a primitive
  // it must be an objet...
  if (typeof propertyDef !== 'object') {
    return defaultValue;
  }

  // the translations object must exist with the target language...
  const targetLang = i18next.resolvedLanguage;
  const { translations } = propertyDef;

  const validTranslationExists =
    typeof translations === 'object' &&
    typeof translations[targetLang] === 'object' &&
    typeof translations[targetLang][targetPropName] === 'string';

  return validTranslationExists
    ? translations[targetLang][targetPropName]
    : propertyDef[targetPropName] || defaultValue;
};

export default getSchemaTranslation;

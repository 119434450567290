import { isEmail } from '../utils';
import { useInputValidation } from './useInputValidation';

const isNotAnEmail = (email: string) => !isEmail(email);

const emailValidations = [
  {
    errorCase: isNotAnEmail,
    helperText: 'Must be a valid email address.',
  },
];

export const useEmailInputValidation = (email: string) => {
  return useInputValidation(email, emailValidations);
};

import React from 'react';
import GridView from '../layouts/GridView';
import DeviceGridItem from './DeviceGridItem';

interface DevicesGridViewProps {
  devices: Device[];
}

const DevicesGridView: React.FC<DevicesGridViewProps> = ({ devices }) => {
  return (
    <GridView>
      {devices.map((device) => (
        <DeviceGridItem device={device} key={device.key} />
      ))}
    </GridView>
  );
};

export default DevicesGridView;

import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Line,
  ComposedChart,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import TitledCard from '../../../shared/components/TitledCard';

const CertosComposedChart: React.FC<CertosChart> = ({
  title,
  data,
  xAxis,
  yAxis,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TitledCard title={title}>
        <ResponsiveContainer width='100%' height={400}>
          <ComposedChart width={730} height={250} data={data}>
            <Tooltip />
            <Legend />
            <XAxis
              dataKey='ts'
              label={{
                value: t('TIME_AXIS_DESCRIPTION'),
                position: 'insideBottomRight',
                offset: -5,
              }}
            />
            <YAxis
              yAxisId='left'
              label={{
                value: t('PRESSURE_AXIS_DESCRIPTION'),
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <YAxis
              yAxisId='right'
              orientation='right'
              label={{
                value: t('TEMPERATURE_AXIS_DESCRIPTION'),
                angle: -90,
                position: 'insideRight',
              }}
            />
            <CartesianGrid stroke='#f5f5f5' />
            <defs>
              <linearGradient
                id='fade-out-gradient'
                x1='0%'
                y1='0%'
                x2='0%'
                y2='100%'
              >
                <stop offset='0%' stopColor='#c4a484' stopOpacity='1' />
                <stop offset='100%' stopColor='#c4a484' stopOpacity='0' />
              </linearGradient>
            </defs>
            <Area
              yAxisId='right'
              type='monotone'
              dataKey='tmp'
              stroke='#c4a484'
              fill='url(#fade-out-gradient)'
              strokeWidth={3}
              dot={{ r: 2 }}
            />
            <Line
              yAxisId='left'
              type='monotone'
              dataKey='prs'
              stroke='#ff7300'
            />
          </ComposedChart>
        </ResponsiveContainer>
      </TitledCard>
    </>
  );
};

export default CertosComposedChart;

import React from 'react';
import CenteredFormLayout from '../../shared/layouts/CenteredFormLayout';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredFormLayout
      title={t('FORGOT_PASSWORD')}
      form={ForgotPasswordForm}
    />
  );
};

export default ForgotPassword;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCertosSettings } from '../api';
import { setCertosSettings } from '../redux/actionCreators';
import { dummy_settings } from '../redux/dummy_state';

export const useCompanyInfo = () => {
  const { companyInfo } = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();

  const alreadyLoadedSettings = companyInfo !== dummy_settings.companyInfo;
  useEffect(() => {
    if (alreadyLoadedSettings) {
      return;
    }

    fetchCertosSettings()
      .then((settings) => dispatch(setCertosSettings(settings)))
      .catch((e) => {
        alert(
          'You are either offline, or the application server is shut down.'
        );
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return companyInfo;
};

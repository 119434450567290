import { Button } from '@material-ui/core';
import React from 'react';
import FeedbackMessage from './FeedbackMessage';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import Stack from '../layouts/Stack';

interface FormSubmitButtonProps {
  btnName: string;
  handleSubmit: () => void;
  invalidInputs: boolean;
  loading: boolean;
  feedback: Feedback;
  startIcon?: JSX.Element;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  btnName,
  handleSubmit,
  invalidInputs,
  loading,
  feedback,
  startIcon,
}) => {
  return (
    <Stack>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        endIcon={loading && <LoopOutlinedIcon />}
        disabled={loading || invalidInputs}
        startIcon={startIcon}
      >
        {btnName}
      </Button>

      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
    </Stack>
  );
};

export default FormSubmitButton;

import React from 'react';
import CenteredFormLayout from '../../shared/layouts/CenteredFormLayout';
import ResetPasswordForm from './ResetPasswordForm';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredFormLayout title={t('RESET_PASSWORD')} form={ResetPasswordForm} />
  );
};

export default ResetPassword;

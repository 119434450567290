import { Grid, makeStyles, Theme } from '@material-ui/core';
import { GridSpacing } from '@material-ui/core/Grid/Grid';
import React from 'react';

/**
 * A Grid container that adds all direct children node into a GridItem with full length.
 *
 */

const useStyles = makeStyles((theme: Theme) => ({
  fluidContainer: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  stack: {
    flexDirection: 'column',
  },
}));

interface StackProps {
  fluid?: boolean;
  spacing?: GridSpacing;
}

const Stack: React.FC<StackProps> = (props) => {
  const classes = useStyles(props);

  const toGridItem = (node: React.ReactNode, index: number) => {
    return (
      <Grid key={index} item xs>
        {node}
      </Grid>
    );
  };

  let StackContent;
  if (!props.children) {
    StackContent = '';
  } else if (Array.isArray(props.children)) {
    StackContent = props.children.map(toGridItem);
  } else {
    // single item
    StackContent = (
      <Grid item xs>
        {props.children}
      </Grid>
    );
  }

  const itemSpacing: GridSpacing =
    typeof props.spacing === 'number' ? props.spacing : 3;

  return (
    <Grid
      container
      className={props.fluid ? classes.fluidContainer : classes.stack}
      spacing={itemSpacing}
    >
      {StackContent}
    </Grid>
  );
};

/*

    {props.children && Array.isArray(props) && props.children.map(child => {
        <Grid item xs>
            child
        </Grid>
    })}

*/
export default Stack;

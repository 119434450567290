import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { postExportSubMenuDataAsPdf } from '../../../api';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import FormSubmitButton from '../../../shared/components/FormSubmitButton';
import { downloadBlobAsFile } from '../../../utils';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { usePasswordInputValidation } from '../../../hooks/usePasswordInputValidation';
import { useTranslation } from 'react-i18next';
import Stack from '../../../shared/layouts/Stack';
interface PdfExportProps {
  deviceKey: string;
  subMenuName: string;
  recordId: string;
}

export const PdfExport: React.FC<PdfExportProps> = ({
  deviceKey,
  subMenuName,
  recordId,
}) => {
  const { t } = useTranslation();

  const [showCard, setShowCard] = useState(false);
  const openDialog = () => setShowCard(true);
  const closeDialog = () => setShowCard(false);

  const token = useSelector((state: State) => state.token!);
  const [password, setPassword] = useState<string>('');
  const [pwError, pwHelperText, validateAsPassword] =
    usePasswordInputValidation(password);

  const { feedback, loading, makeSubmitHandler, setFeedback } =
    useAsyncFormSubmit(postExportSubMenuDataAsPdf, {
      token,
      deviceKey,
      subMenuName,
      recordId,
      password,
    });
  const handleSubmit = makeSubmitHandler({
    onSuccess: (_, data) => {
      setPassword(''); // empty password
      setFeedback({
        msgType: 'success',
        message: t('PDF_EXPORT_SUCCESSFUL'),
      });
      downloadBlobAsFile('Export.pdf', data);
    },
  });
  return (
    <>
      <Button
        onClick={openDialog}
        startIcon={<VerifiedUserOutlinedIcon />}
        variant='contained'
        color='primary'
      >
        {t('EXPORT_SIGNED_PDF')}
      </Button>

      <Dialog open={showCard} onClose={closeDialog} fullWidth>
        <DialogTitle>{t('PLEASE_ENTER_YOUR_PASSWORD_TO_SIGN_PDF')}</DialogTitle>
        <DialogContent>
          <Stack>
            <TextField
              fullWidth
              label={t('PASSWORD')}
              variant='standard'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              data-test-id='password-input'
              onBlur={validateAsPassword}
              error={pwError}
              helperText={pwHelperText}
            />
            <FormSubmitButton
              btnName={t('EXPORT_SIGNED_PDF')}
              loading={loading}
              feedback={feedback}
              handleSubmit={handleSubmit}
              invalidInputs={false}
              startIcon={<VerifiedUserOutlinedIcon />}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

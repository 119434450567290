import React, { useState } from 'react';
import Stack from '../../../shared/layouts/Stack';
import { Typography } from '@material-ui/core';
import Editor from '@monaco-editor/react';
import { useTranslation } from 'react-i18next';

interface CreateSubMenuRecordJsonProps {
  subMenuDefinition: SchemaObject;
  setSubMenuRecord: React.Dispatch<any>;
}

const CreateSubMenuRecordJson: React.FC<CreateSubMenuRecordJsonProps> = ({
  subMenuDefinition,
  setSubMenuRecord,
}) => {
  const { t } = useTranslation();

  const { initialObjectString, schemaString } =
    useCreateEditorDefaultValue(subMenuDefinition);
  const [jsonCode, setJsonCode] = useState<string>(initialObjectString);

  const handleEditorModelChange = (newValue: string | undefined) => {
    if (typeof newValue === 'string') {
      setJsonCode(newValue);
      try {
        const newSubMenuRecord = JSON.parse(newValue);
        setSubMenuRecord(newSubMenuRecord);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Stack>
      <Editor
        height='25vh'
        defaultLanguage='json'
        defaultValue={jsonCode}
        beforeMount={(monaco: any) =>
          configureMonacoToValidateBySchemaDefintion(monaco, subMenuDefinition)
        }
        onChange={handleEditorModelChange}
        defaultPath={FAKE_FILE_PATH}
      />
      <Typography variant='h6'>{t('SCHEMA_READONLY')}</Typography>
      <Editor
        height='25vh'
        defaultLanguage='json'
        value={schemaString}
        options={{ readOnly: true }}
      />
    </Stack>
  );
};

export default CreateSubMenuRecordJson;

/*
  FAKE_FILE_PATH is used to ensure that schema validation and autocompletion work:
  The monaco editors "jsonDefaults" allow you to pass schemas (see below),
  yet in order to apply these schemas it needs to match a model path.
  Thats were FAKE_FILE_PATH comes in place...
  1. It is passed in the Editor as the path of the editors Model.
  2. And passed as fileMatch in the setDiagnosticsOptions of monaco.languages.json.jsonDefaults.
  
  That way both are connected and schema validation actually works!
*/
const FAKE_FILE_PATH = 'user://input.json';
/*
  FAKE_SCHEMA_URI also required for schema validation to work.
*/
const FAKE_SCHEMA_URI = 'http://fake.com/schema.json';

const createInitialJsonBasedOnSchema = (schemaDefinition: any): any => {
  const emptyObject = {};
  if (!schemaDefinition || !schemaDefinition.properties) {
    return emptyObject; // just empty
  }

  const initialObject = Object.keys(schemaDefinition.properties).reduce(
    (target: any, propName: string) => {
      const propDefinition: any = schemaDefinition.properties[propName];
      const propType = propDefinition.type;
      let propValue;
      // assign the prop
      switch (propType) {
        case 'string':
          propValue = '';
          break;
        case 'number':
          propValue = propDefinition.minimum || 0;
          break;
        case 'boolean':
          propValue = false;
          break;
        case 'array':
          propValue = [];
          break;
        case 'object':
          propValue = {};
          break;
        default:
          propValue = '';
          break;
      }
      target[propName] = propValue;
      return target;
    },
    emptyObject
  );

  return initialObject;
};
const useCreateEditorDefaultValue = (schemaDefinition: any): any => {
  const { t } = useTranslation();

  const initialObject = createInitialJsonBasedOnSchema(schemaDefinition);
  const initialObjectString = JSON.stringify(initialObject, null, 2);

  // copy the schema except for allowedUserActions
  const displaySchema = { ...schemaDefinition };
  delete displaySchema.allowedUserActions;
  const schemaString = JSON.stringify(displaySchema, null, 2);
  const commentedOutSchema = t('REFER_TO_SCHEMA_COMMENT', {
    schemaString,
  });
  const defaultValue = `${initialObjectString}\n\n${commentedOutSchema}`;
  return {
    defaultValue,
    initialObjectString,
    commentedOutSchema,
    schemaString,
  };
};

const configureMonacoToValidateBySchemaDefintion = (
  monaco: any,
  subMenuDefinition: any
) => {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    allowComments: true,
    schemaValidation: 'error',
    schemas: [
      {
        uri: FAKE_SCHEMA_URI,
        fileMatch: [FAKE_FILE_PATH],
        schema: subMenuDefinition,
      },
    ],
  });
};

import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

interface LoadingWrapperProps {
  loading: boolean;
  loadingFailed: boolean;
  renderAfterLoading: () => any;
  renderOnLoadingFailed: () => any;
}

const useStyles = makeStyles({
  centeredOnPage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1000,
  },
});

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  loading,
  loadingFailed,
  renderAfterLoading,
  renderOnLoadingFailed,
}) => {
  const classes = useStyles();

  return loadingFailed ? (
    <>{renderOnLoadingFailed()}</>
  ) : loading ? (
    <CircularProgress className={classes.centeredOnPage} />
  ) : (
    <>{renderAfterLoading()}</>
  );
};

export default LoadingWrapper;

import React from 'react';
import { Grid, Box, Typography, Button, makeStyles } from '@material-ui/core';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
  },
});

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      <OfflineBoltOutlinedIcon fontSize='large' />

      <Typography variant='h3' component='h3'>
        {t('404_NOT_FOUND')}
      </Typography>

      <Typography variant='h2' component='h4' align='center'>
        {t('SEEMS_LIKE_THIS_PAGE_DOESNT_EXIST')}
      </Typography>

      <Box padding={5}>
        <Link to={'/devices'} style={{ textDecoration: 'none' }}>
          <Button variant='outlined' color='primary'>
            {t('GO_BACK_TO_MY_DEVICES')}
          </Button>
        </Link>
      </Box>
    </Grid>
  );
};

export default ErrorPage;

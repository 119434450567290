import React from 'react';
import { List } from '@material-ui/core';
import DeviceListItem from './DeviceListItem';

interface DeviceListProps {
  devices: Device[];
  onItemClicked?: (device: Device) => void;
}

const DeviceList: React.FC<DeviceListProps> = ({ devices, onItemClicked }) => {
  return (
    <List data-test-id='device-list'>
      {devices.map((d) => (
        <DeviceListItem key={d.key} device={d} onItemClicked={onItemClicked} />
      ))}
    </List>
  );
};

export default DeviceList;

import React, { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { useDispatch, useSelector } from 'react-redux';
import { getDevicesData } from '../../../api';
import { setDevicesData } from '../../../redux/actionCreators';

interface SavableGraphConfiguration {
  color: string;
  device: string;
  subMenu: string;
  xAxisValue: string;
  yAxisValue: string;
}

class GraphConfiguration implements SavableGraphConfiguration {
  color: string;
  device: string;
  subMenu: string;
  xAxisValue: string;
  yAxisValue: string;

  constructor(
    color: string,
    device: string,
    subMenu: string,
    xAxisValue: string,
    yAxisValue: string
  ) {
    this.color = color;
    this.device = device;
    this.subMenu = subMenu;
    this.xAxisValue = xAxisValue;
    this.yAxisValue = yAxisValue;
  }
}

const UpdatableChartExample: React.FC = () => {
  useEffect(() => {
    const storedGraphConfiguration = loadGraphConfigurationFromLocalStorage();
    if (storedGraphConfiguration) {
      setSelectedColor(storedGraphConfiguration.color);
      setSelectedDevice(storedGraphConfiguration.device);
      setSelectedSubMenu(storedGraphConfiguration.subMenu);
      setSelectedXAxis(storedGraphConfiguration.xAxisValue);
      setSelectedYAxis(storedGraphConfiguration.yAxisValue);
    }
  }, []);

  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const [subMenuOptions, setSubMenuOptions] = useState<string[]>([]);
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [xAxisOptions, setXAxisOptions] = useState<string[]>([]);
  const [yAxisOptions, setYAxisOptions] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState('#82ca9d'); // Initial color

  const devices = useSelector((state: State) => state.devices);
  const token = useSelector((state: State) => state.token);
  const device = useSelector((state: State) =>
    state.devices.find((d) => d.key === selectedDevice)
  );

  const deviceData = useSelector((state: State) => state.devicesData);

  const dispatch = useDispatch();
  useEffect(() => {
    getDevicesData({
      token: token!,
      deviceKey: device?.key ?? 'ST-1',
      submenueName: selectedSubMenu,
      pagination: 1,
    })
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          dispatch(setDevicesData(data));
          let xAndYAxisOptions = Object.keys(data[0]);
          setXAxisOptions(xAndYAxisOptions);
          setYAxisOptions(xAndYAxisOptions);
        }
      })
      .catch(console.log);
  }, [dispatch, token, selectedDevice, selectedSubMenu, device?.key]);

  useEffect(() => {
    const foundDevice = devices.find((d) => d.key === selectedDevice);
    const newSubMenuOptions = foundDevice?.device_submenues
      ? Object.keys(foundDevice.device_submenues)
      : [];
    setSubMenuOptions(newSubMenuOptions);
  }, [devices, selectedDevice]);

  useEffect(() => {}, [subMenuOptions, selectedSubMenu]);

  useEffect(() => {}, [subMenuOptions, selectedSubMenu]);

  const handleSubMenuChange = (selectedOption: string) => {
    setSelectedSubMenu(selectedOption);
  };

  const handleDeviceChange = (device: string) => {
    setSelectedDevice(device);
  };

  const handleXAxisChange = (xAxisValue: string) => {
    setSelectedXAxis(xAxisValue);
  };

  const handleYAxisChange = (yAxisValue: string) => {
    setSelectedYAxis(yAxisValue);
  };

  const handleColorChange = (color: string) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    let data: SavableGraphConfiguration = new GraphConfiguration(
      selectedColor,
      selectedDevice,
      selectedSubMenu,
      selectedXAxis,
      selectedYAxis
    );

    const jsonString = JSON.stringify(data);
    localStorage.setItem('UpdatableChartPreset', jsonString);
    console.log(jsonString);
  }, [
    selectedColor,
    selectedDevice,
    selectedSubMenu,
    selectedXAxis,
    selectedYAxis,
  ]);

  const loadGraphConfigurationFromLocalStorage = () => {
    const storedGraphConfiguration = localStorage.getItem(
      'UpdatableChartPreset'
    );
    if (
      storedGraphConfiguration !== undefined &&
      storedGraphConfiguration !== null
    ) {
      return JSON.parse(storedGraphConfiguration) as SavableGraphConfiguration;
    }
  };

  loadGraphConfigurationFromLocalStorage();

  return (
    <>
      <TitledCard title='Customizable BarChart'>
        <ResponsiveContainer width='100%' height={400}>
          <BarChart width={730} height={250} data={deviceData ?? []}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={selectedXAxis} />
            <YAxis dataKey={selectedYAxis} />
            <Tooltip />
            <Legend />
            <Bar dataKey={selectedYAxis} fill={selectedColor} />
          </BarChart>
        </ResponsiveContainer>
        <div>
          <label htmlFor='colorSelector'>Select Color:</label>
          <select
            id='colorSelector'
            value={selectedColor}
            onChange={(e) => handleColorChange(e.target.value)}
          >
            <option value='#82ca9d'>Green</option>
            <option value='#eb4934'>Red</option>
            <option value='#2675fc'>Blue</option>
            <option value='#000000'>Black</option>
          </select>
        </div>
        <div>
          <div>
            <label htmlFor='deviceSelector'>Select Device:</label>
            <select
              id='deviceSelector'
              value={selectedDevice}
              onChange={(e) => handleDeviceChange(e.target.value)}
            >
              <option value=''>None</option>
              {devices.map((device) => (
                <option key={device._id} value={device.key}>
                  {device.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor='subMenuSelector'>Select Sub-Menu:</label>
            <select
              id='subMenuSelector'
              value={selectedSubMenu}
              onChange={(e) => handleSubMenuChange(e.target.value)}
              disabled={selectedDevice === 'None'}
            >
              <option value=''>Select a sub-menu</option>
              {subMenuOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor='xAxisSelector'>Select X-Axis:</label>
            <select
              id='xAxisSelector'
              value={selectedXAxis} // Add your state and logic for selectedXAxis
              onChange={(e) => handleXAxisChange(e.target.value)} // Add your handler for X-Axis change
              disabled={selectedSubMenu === 'Select a sub-menu'}
            >
              <option value=''>Select X-Axis</option>
              {xAxisOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor='yAxisSelector'>Select Y-Axis:</label>
            <select
              id='yAxisSelector'
              value={selectedYAxis} // Add your state and logic for selectedYAxis
              onChange={(e) => handleYAxisChange(e.target.value)} // Add your handler for Y-Axis change
              disabled={selectedSubMenu === 'Select a sub-menu'}
            >
              <option value=''>Select Y-Axis</option>
              {yAxisOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </TitledCard>
    </>
  );
};

export default UpdatableChartExample;

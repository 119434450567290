import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postUploadImage } from '../../../api';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import ImageUploader from '../../../shared/components/ImageUploader';
import Stack from '../../../shared/layouts/Stack';

interface UploadImageProps {
  onUploadDone: (url: string) => void;
  previewSrc: string;
}

export const UploadImage: React.FC<UploadImageProps> = ({
  onUploadDone,
  previewSrc,
}) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const [feedback, setFeedback] = useState<Feedback>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUploadSubmit = (image: File) => {
    setLoading(true);
    // Note postUploadImage will only "save" the image in exchange for an imageLocation (URL) to be used
    postUploadImage({ token, image })
      .then((result) => {
        if (result?.imageLocation) {
          setFeedback({
            message: result?.imageLocation,
            msgType: 'success',
          });
          return onUploadDone(result.imageLocation);
        }

        if (result?.error) {
          return setFeedback({
            message: result.error,
            msgType: 'error',
          });
        }

        return setFeedback({
          message: t('ERROR_UPDATING_IMAGE'),
          msgType: 'error',
        });
      })
      .catch((error) => {
        console.log(error);
        setFeedback({
          message: t('ERROR_UPDATING_IMAGE'),
          msgType: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack>
      <ImageUploader
        defaultPreview={previewSrc}
        onUploadSubmit={handleUploadSubmit}
        uploadButtonDisabled={loading}
      />
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
    </Stack>
  );
};

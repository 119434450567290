import React from 'react';
import { VectorMap } from '@react-jvectormap/core';
//import { worldMill } from '@react-jvectormap/world';
// for bundling optimization purposes (Recommended!):
import worldMill from '@react-jvectormap/world/dist/worldMill.json';
import TitledCard from '../../../shared/components/TitledCard';

const WorldMapSample: React.FC = () => {
  /*
   Note that for some reason a refresh on the widget page causes the map to disappear...
   However if you reload anywhere else (or change tabs after the reload) and come back to the Widgets Page, then the Map is properly rendered...
  */
  return (
    <>
      <TitledCard title='World Map With Markers'>
        <VectorMap
          map={worldMill}
          backgroundColor='transparent'
          zoomOnScroll={true}
          markersSelectable={true}
          onRegionClick={console.log}
          className='map'
          regionStyle={{
            initial: {
              fill: '#cccccc',
              //'fill-opacity': 0.9,
              fillOpacity: 0.9,
              stroke: 'none',
              //'stroke-width': 0,
              strokeWidth: 0,
              // 'stroke-opacity': 0,
              strokeOpacity: 0,
              cursor: 'pointer',
            },
            hover: {
              //'fill-opacity': 0.8,
              fillOpacity: 0.8,

              cursor: 'pointer',
            },
            selected: {
              fill: '#212121',
              stroke: '#212121',
              //'stroke-width': 1,
              strokeWidth: 1,
            },
            selectedHover: {
              //'fill-opacity': 0.8,
              fillOpacity: 0.8,
            },
          }}
          markers={[
            {
              latLng: [-23.53, -46.62],
              name: 'São Paulo',
              style: {
                fill: 'orangered',
              },
            },
            {
              latLng: [48.2, 16.37],
              name: 'Vienna',
              style: {
                fill: 'orangered',
              },
            },
          ]}
          regionsSelectable={true}
          style={{ height: 400 }}
        />
      </TitledCard>
    </>
  );
};

export default WorldMapSample;

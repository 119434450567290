import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../routing/RouteNames';
import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { activateUserAccount } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { useTranslation } from 'react-i18next';

const ActivateAccountForm: React.FC = () => {
  const { t } = useTranslation();

  const { accountActivationToken } = useParams<{
    accountActivationToken: string;
  }>();

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(activateUserAccount, {
      token: accountActivationToken,
    });

  const tiggerActivation = makeSubmitHandler({
    onSuccess: () => {
      setFeedback({
        message: t('ACTIVATION_SUCCESSFUL'),
        msgType: 'success',
      });
    },
  });

  useEffect(() => {
    tiggerActivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!loading && feedback && (
        <FeedbackMessage type={feedback.msgType} message={feedback.message} />
      )}
      <Link to={RouteNames.LOGIN}>{t('GO_TO_LOGIN')}</Link>
    </Stack>
  );
};

export default ActivateAccountForm;

import React, { useState } from 'react';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import UserList from '../../shared/components/UserList';
import { useSelector } from 'react-redux';
import { useFilter } from '../../hooks/useFilter';
import SearchBox from '../../shared/components/SearchBox';
import UsersGridView from './GridView/UsersGridView';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import { Box, Button } from '@material-ui/core';
import Stack from '../../shared/layouts/Stack';
import { ReactComponent as NoUsers } from '../../shared/static/placeholders/NoUsers.svg';
import NoDataPlaceholder from '../../shared/layouts/NoDataPlaceholder';
import { useTranslation } from 'react-i18next';

const UsersPage: React.FC = () => {
  const { t } = useTranslation();

  const [view, setView] = useState<'grid' | 'list'>('grid');

  const users = useSelector((state: State) => state.users);
  const [filter, setFilter] = useState('');
  const usersToShow = useFilter(users, filter);

  const onSearchBoxChange = (e: any) => setFilter(e.target.value);
  return (
    <DefaultPageLayout title='Users'>
      <NoDataPlaceholder
        noData={!users || users.length === 0}
        placeholder={{
          image: <NoUsers />,
          textOverlay: {
            title: t('NO_USERS_TO_MANAGE_YET'),
            subTitle: t('USERS_WILL_BE_SEEN_HERE'),
          },
        }}
      >
        <Stack spacing={1}>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              startIcon={
                view === 'list' ? (
                  <GridOnOutlinedIcon />
                ) : (
                  <ListAltOutlinedIcon />
                )
              }
            >
              {view === 'list' ? t('GRID_VIEW') : t('LIST_VIEW')}
            </Button>
          </Box>

          <SearchBox
            value={filter}
            placeholder={t('SEARCH_USERS')}
            onChange={onSearchBoxChange}
          />

          {view === 'grid' ? (
            <UsersGridView users={usersToShow} />
          ) : (
            <UserList users={usersToShow} />
          )}
        </Stack>
      </NoDataPlaceholder>
    </DefaultPageLayout>
  );
};

export default UsersPage;

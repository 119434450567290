import { Button, CardContent, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import TitledCard from '../../../shared/components/TitledCard';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import { useTranslation } from 'react-i18next';
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined';
import { io, Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';
import FlatJsonView from '../../../shared/components/FlatJsonView';
import Stack from '../../../shared/layouts/Stack';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';

interface LiveDataProps {
  device: Device;
}

const LiveData: React.FC<LiveDataProps> = ({ device }) => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const socketRef = useRef<Socket>();
  const [isConnected, setIsConnected] = useState(false);
  const [liveData, setLiveData] = useState(null);
  const [feedback, setFeedback] = useState<Feedback>(null);

  const requestLiveData = () => {
    if (!socketRef.current) {
      return;
    }
    socketRef.current.emit('listen to live data', {
      deviceKey: device.key,
      token,
    });
  };

  const connectToDeviceSocketRoom = () => {
    setIsConnected(true);

    const backendURL =
      process.env.REACT_APP_API_URL?.replace(/api\/?$/, '') ||
      'http://localhost:9000/';
    socketRef.current = io(backendURL);
    requestLiveData();

    socketRef.current.on('live data', ({ payload }) => {
      setFeedback(null); // make sure feedback is removed here...
      setLiveData(payload);
    });

    socketRef.current.on('disconnect', () => {
      setIsConnected(false);
    });

    socketRef.current.on('error', ({ message }) => {
      setFeedback({ msgType: 'error', message });
    });
  };
  return (
    <TitledCard
      title='Live Monitoring'
      iconAvatar={<ContactlessOutlinedIcon />}
    >
      {isConnected ? (
        <Stack>
          {liveData ? (
            <FlatJsonView jsonObj={liveData} />
          ) : (
            <Typography>{t('NOTHING_RECEIVED_YET')}</Typography>
          )}
          <Button
            variant='outlined'
            color='default'
            startIcon={<ReplaySharpIcon />}
            onClick={requestLiveData}
          >
            {t('CLICK_TO_RECEIVE_DATA')}
          </Button>
          {feedback && (
            <FeedbackMessage
              type={feedback.msgType}
              message={feedback.message}
            />
          )}
        </Stack>
      ) : (
        <>
          <CardContent>
            You can retrieve live data from the device. Just click the link
            below and watch the magic happen.
          </CardContent>
          <Button
            variant='outlined'
            color='default'
            startIcon={<ReplaySharpIcon />}
            onClick={connectToDeviceSocketRoom}
          >
            {t('CLICK_TO_RECEIVE_DATA')}
          </Button>
        </>
      )}
    </TitledCard>
  );
};

export default LiveData;

import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { Link, useParams } from 'react-router-dom';
import { RouteNames } from '../../routing/RouteNames';
import Stack from '../../shared/layouts/Stack';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import { resetPassword } from '../../api';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { useInputValidation } from '../../hooks/useInputValidation';
import { usePasswordInputValidation } from '../../hooks/usePasswordInputValidation';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();

  const { passwordResetToken } = useParams<{ passwordResetToken: string }>();

  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [pwError, pwHelperText, validateAsPassword] =
    usePasswordInputValidation(newPassword);

  const passwordConfirmationValidations = [
    {
      errorCase: (pwConfirm: string) => pwConfirm !== newPassword,
      helperText: t('PASSWORD_CONFIRMATION_MUST_MATCH'),
    },
  ];
  const [pwMatchError, pwMatchHelperText, validateAsPasswordMatch] =
    useInputValidation(repeatPassword, passwordConfirmationValidations);

  const invalidInputs: boolean =
    newPassword === '' || repeatPassword === '' || pwError || pwMatchError;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(resetPassword, {
      token: passwordResetToken,
      newPassword,
    });

  const handleResetPasswordSubmit = makeSubmitHandler({
    preChecker: () => {
      // TODO: define across application what min pw length should be
      const passwordHasMinLength = newPassword.length > 4;
      const passwordsMatch = newPassword === repeatPassword;

      if (!passwordHasMinLength) {
        setFeedback({
          message: t('YOUR_PASSWORD_IS_TOO_SHORT'),
          msgType: 'error',
        });
        return false;
      }

      if (!passwordsMatch) {
        setFeedback({
          message: t('PASSWORDS_DO_NOT_MATCH'),
          msgType: 'error',
        });
        return false;
      }

      return true;
    },
    onSuccess: () => {
      setFeedback({
        message: t('PASSWORD_WAS_RESET'),
        msgType: 'success',
      });
    },
  });

  return (
    <form noValidate onSubmit={handleResetPasswordSubmit} autoComplete='off'>
      <Stack>
        <TextField
          fullWidth
          label={t('NEW_PASSWORD')}
          variant='standard'
          type='password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onBlur={validateAsPassword}
          error={pwError}
          helperText={pwHelperText}
        />
        <TextField
          fullWidth
          label={t('REPEAT_PASSWORD')}
          variant='standard'
          type='password'
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          onBlur={validateAsPasswordMatch}
          error={pwMatchError}
          helperText={pwMatchHelperText}
        />
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleResetPasswordSubmit}
          endIcon={loading && <LoopOutlinedIcon />}
          disabled={loading || invalidInputs}
        >
          {t('RESET_PASSWORD')}
        </Button>
        {!loading && feedback && (
          <FeedbackMessage type={feedback.msgType} message={feedback.message} />
        )}
        <Link to={RouteNames.LOGIN}>{t('GO_TO_LOGIN')}</Link>
      </Stack>
    </form>
  );
};

export default ResetPasswordForm;

import React from 'react';
import {
  AppBar,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../components/Logo';
import { useSelector } from 'react-redux';
import LanguagePicker from '../components/LanguagePicker';
import CountDownTimer from '../components/CountDownTimer';
import { usePasswordExpInfo } from '../../hooks/usePasswordExpInfo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '../../routing/RouteNames';

interface HeaderBarProps {
  handleDrawerToggle: () => void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerbar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    toolBar: {
      justifyContent: 'space-between',
    },
    rightToolbarSection: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    title: {
      fontWeight: 400,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    announcementBar: {
      textAlign: 'center',
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.getContrastText(theme.palette.warning.main),
      cursor: 'pointer',
    },
  })
);

const AnnouncementBar: React.FC<{
  showWhen: boolean;
  text: string;
  path: string;
}> = ({ showWhen, text, path }) => {
  const classes = useStyles();

  const history = useHistory();
  const navigateToPath = () => {
    history.push(path);
  };

  return (
    <>
      {showWhen && (
        <Box className={classes.announcementBar} onClick={navigateToPath}>
          {text && <Typography>{text}</Typography>}
        </Box>
      )}
    </>
  );
};

export const HeaderBar: React.FC<HeaderBarProps> = ({ handleDrawerToggle }) => {
  const { t } = useTranslation();

  const certosSettings = useSelector((state: State) => state.settings);
  const classes = useStyles();
  const { passwordExpiresInDays, shouldWarnUser } = usePasswordExpInfo();

  return (
    <AppBar position='fixed' className={classes.headerbar}>
      <AnnouncementBar
        showWhen={shouldWarnUser}
        path={RouteNames.PROFILE}
        text={
          t('YOUR_PASSWORD_EXPIRES_IN_N_DAYS', {
            passwordExpiresInDays,
          }) +
          '... ' +
          t('CLICK_HERE_TO_CHANGE_IT_NOW')
        }
      />

      <Toolbar className={classes.toolBar}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Logo
          imageProps={{ src: certosSettings.logoImgSrc, height: 64 }}
          dark
        />

        <div className={classes.rightToolbarSection}>
          {certosSettings.securitySettings.automaticLogoutActive && (
            <CountDownTimer
              minutesForCountdown={
                certosSettings.securitySettings.logoutTimeoutMinutes
              }
            />
          )}
          <LanguagePicker />
        </div>
      </Toolbar>
    </AppBar>
  );
};

import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackMessage from '../../shared/components/FeedbackMessage';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { DialogInfoText } from '../../shared/components/DialogInfoText';
import { fileSizeAsHumanReadable } from '../../utils';
import TitledCard from '../../shared/components/TitledCard';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { postVerifyPdf } from '../../api';
import { useSelector } from 'react-redux';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import Stack from '../../shared/layouts/Stack';

export const PdfValidation: React.FC = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token!);
  const [file, setFile] = useState<File>();
  const fileName = file?.name;
  const fileSize = file?.size;

  const [fileFeedback, setFileFeedback] = useState<Feedback>();

  const resetFileRelatedState = () => {
    setFile(undefined);
    setFileFeedback(undefined);
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    // reset File Feedback from any previous tries
    resetFileRelatedState();

    if (!event.target.files) {
      setFileFeedback({
        msgType: 'info',
        message: t('NOTHING_SELECTED'),
      });
      return;
    }

    const file = event.target.files[0];
    if (file && !file.name.includes('.pdf')) {
      setFileFeedback({
        msgType: 'error',
        message: t('FILE_NOT_A_PDF'),
      });
      return;
    }

    setFile(file);
  };

  const { feedback, loading, makeSubmitHandler, setFeedback } =
    useAsyncFormSubmit(postVerifyPdf, {
      token,
      pdfFile: file!,
    });

  const handleSubmit = makeSubmitHandler({
    preChecker: () => {
      const fileUploaded = typeof file === 'object';
      return fileUploaded;
    },
    onSuccess: () => {
      setFeedback({
        msgType: 'success',
        message: t('PDF_IS_VALID'),
      });
    },
  });

  return (
    <TitledCard
      title={t('PDF_VALIDATION')}
      iconAvatar={<VerifiedUserOutlinedIcon />}
    >
      <Stack>
        <Typography variant='h6'>{t('UPLOAD_PDF_TO_VERIFY')}</Typography>
        <Button
          component='label'
          variant='contained'
          startIcon={<InsertDriveFileOutlinedIcon />}
        >
          {file ? t('CHOOSE_ANOTHER_FILE') : t('OPEN_PDF_FILE')}
          <input
            type='file'
            accept='.pdf'
            hidden
            onChange={handleFileSelected}
          />
        </Button>

        {fileFeedback && (
          <FeedbackMessage
            type={fileFeedback.msgType}
            message={fileFeedback.message}
          />
        )}

        <>
          {fileName && (
            <DialogInfoText infoName={t('FILE_NAME')} infoValue={fileName} />
          )}
          {fileSize && (
            <DialogInfoText
              infoName={t('FILE_SIZE')}
              infoValue={fileSizeAsHumanReadable(fileSize)}
            />
          )}
        </>

        {file && (
          <FormSubmitButton
            btnName={t('VERIFY_PDF')}
            loading={loading}
            feedback={feedback}
            handleSubmit={handleSubmit}
            invalidInputs={false}
            startIcon={<VerifiedUserOutlinedIcon />}
          />
        )}
      </Stack>
    </TitledCard>
  );
};

export enum ActionTypes {
  SET_TOKEN = 'SET_TOKEN',
  REMOVE_TOKEN = 'REMOVE_TOKEN',

  SET_DEVICES = 'SET_DEVICES',
  DEVICE_WAS_REMOVED = 'DEVICE_WAS_REMOVED',
  SET_DEVICES_DATA = 'SET_DEVICES_DATA',
  ADD_DEVICE_DATA = 'ADD_DEVICE_DATA',
  UPDATE_DEVICE_DATA = 'UPDATE_DEVICE_DATA',

  SET_USERS = 'SET_USERS',
  UPDATE_USER = 'UPDATE_USER',

  SET_AUDIT_TRAIL_INFO = 'SET_AUDIT_TRAIL_INFO',

  SET_USER_PROFILE = 'SET_USER_PROFILE',

  SET_CERTOS_SETTINGS = 'SET_CERTOS_SETTINGS',

  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
}

export function setAuthToken(token: string | null) {
  const action: Action = {
    type: ActionTypes.SET_TOKEN,
    token,
  };
  return action;
}
export function removeAuthToken() {
  const action: Action = {
    type: ActionTypes.REMOVE_TOKEN,
  };
  return action;
}

export function setDevices(devices: Device[]) {
  const action: Action = {
    type: ActionTypes.SET_DEVICES,
    devices,
  };

  return action;
}
export function deviceWasRemoved(device: Device) {
  const action: Action = {
    type: ActionTypes.DEVICE_WAS_REMOVED,
    device,
  };

  return action;
}

export function setDevicesData(data: DevicesData) {
  const action: Action = {
    type: ActionTypes.SET_DEVICES_DATA,
    data,
  };
  return action;
}

export function addDeviceData(data: DevicesData) {
  const action: Action = {
    type: ActionTypes.ADD_DEVICE_DATA,
    data,
  };
  return action;
}

export function updateDeviceData(editedSingleRecord: any) {
  const action: Action = {
    type: ActionTypes.UPDATE_DEVICE_DATA,
    editedSingleRecord,
  };
  return action;
}

export function setUsers(data: UserProfile[]) {
  const action: Action = {
    type: ActionTypes.SET_USERS,
    data,
  };
  return action;
}

export function updateUser(userId: string, updateInfo: Partial<UserProfile>) {
  const action: Action = {
    type: ActionTypes.UPDATE_USER,
    userId,
    updateInfo,
  };
  return action;
}

export function setAuditTrailInfo({
  auditTrailEvents,
  totalSize,
}: AuditTrailEventsAndMeta) {
  const action: Action = {
    type: ActionTypes.SET_AUDIT_TRAIL_INFO,
    data: {
      auditTrailEvents,
      totalSize,
    },
  };
  return action;
}

export function setUserProfile(data: UserProfile) {
  const action: Action = {
    type: ActionTypes.SET_USER_PROFILE,
    data,
  };
  return action;
}

export function setCertosSettings(data: CertosSettings) {
  const action: Action = {
    type: ActionTypes.SET_CERTOS_SETTINGS,
    data,
  };
  return action;
}

export function setNotifications(data: UserNotification[]) {
  const action: Action = {
    type: ActionTypes.SET_NOTIFICATIONS,
    data,
  };
  return action;
}

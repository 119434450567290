import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { useAsyncFormSubmit } from '../../../hooks/useAsyncFormSubmit';
import { getDevicesData, removeAllDeviceDataFromSubmenu } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import FeedbackMessage from '../../../shared/components/FeedbackMessage';
import { setDevicesData } from '../../../redux/actionCreators';

interface RemoveAllSubMenuDialogProps {
  deviceKey: string;
  subMenuDefinition: SchemaObject;
  subMenuName: string;
  pagination: number;
}

const RemoveAllSubMenuDialog: React.FC<RemoveAllSubMenuDialogProps> = ({
  deviceKey,
  subMenuDefinition,
  subMenuName,
  pagination,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);

  const token = useSelector((state: State) => state.token)!;
  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(removeAllDeviceDataFromSubmenu, {
      token,
      deviceKey,
      subMenuName,
    });

  const openDeleteDialog = () => setDialogOpened(true);
  const closeDeleteDialog = () => {
    setDialogOpened(false);
    setFeedback(null);
  };

  const confirmDeleteSubmitHandler = makeSubmitHandler({
    onSuccess: (_, data: Array<any> | any) => {
      setDeleteDone(true);

      //refresh table with new data (maybe there is a better way with useEffect())
      getDevicesData({
        token: token!,
        deviceKey,
        submenueName: subMenuName,
        pagination,
      })
        .then((data) => {
          if (data.error) {
            alert(data.error);
            return setFeedback({
              msgType: 'error',
              message: t('UNKOWN_ERROR'),
            });
          } else {
            dispatch(setDevicesData(data));
          }
        })
        .catch(console.log);
      return setFeedback({
        msgType: 'success',
        message: t('ALL_SUBMENU_DATA_RECORD_WAS_REMOVED'),
      });
    },
  });

  const deleteRecordIsEnabled =
    subMenuDefinition.allowedUserActions &&
    subMenuDefinition.allowedUserActions.deleteRecord === true;

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        onClick={openDeleteDialog}
        disabled={!deleteRecordIsEnabled}
      >
        {t('REMOVE_RECORDS')}
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        aria-labelledby='delete-submenu-data-dialog-title'
        open={dialogOpened}
      >
        <DialogTitle>{t('CONFIRM_DATA_REMOVAL')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('DELETE_ALL_SUBMENU_CONFIRMATION')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>{t('CANCEL')}</Button>
          <Button
            color='primary'
            variant='contained'
            disabled={loading}
            startIcon={loading && <LoopOutlinedIcon />}
            onClick={
              deleteDone ? closeDeleteDialog : confirmDeleteSubmitHandler
            }
          >
            {deleteDone ? t('DONE') : t('YES')}
          </Button>
        </DialogActions>
        <DialogContent>
          {feedback && (
            <FeedbackMessage
              type={feedback.msgType}
              message={feedback.message}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RemoveAllSubMenuDialog;

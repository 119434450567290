import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { RouteComponentProps, withRouter } from 'react-router';

const Breadcrumbs: React.FC<RouteComponentProps> = (props) => {
  const {
    history,
    location: { pathname },
  } = props;

  const pathnameParts: string[] = pathname.split('/').filter((p: string) => p);
  const routeTo = (index: number) =>
    '/' + pathnameParts.slice(0, index + 1).join('/');

  return (
    <MUIBreadcrumbs
      aria-label='breadcrumb'
      separator={<NavigateNextIcon fontSize='small' />}
    >
      {pathnameParts.map((name, index) => {
        const isLast = index === pathnameParts.length - 1;
        const displayName = name.toUpperCase();
        return isLast ? (
          <Typography key={index}>{displayName}</Typography>
        ) : (
          <Link onClick={() => history.push(routeTo(index))} key={index}>
            {displayName}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);

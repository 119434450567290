import { makeStyles, Slider, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface NumberSliderProps {
  title: string;
  min: number;
  max: number;
  value: number;
  step?: number;
  disabled: boolean;
  label: (value: number) => string;
  onChange: (value: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  infoBox: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  title: {
    color: (disabled) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
  result: {
    fontWeight: 'bold',
  },
  sliderBox: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto', // minVal -   slider   - maxVal
    gap: theme.spacing(1),
  },
  minMax: {
    color: (disabled) =>
      disabled ? theme.palette.text.disabled : theme.palette.primary.main,
  },
}));

const NumberSlider: React.FC<NumberSliderProps> = ({
  title,
  min,
  max,
  value,
  step = 1,
  disabled,
  label,
  onChange,
}) => {
  const classes = useStyles(disabled);
  const labelId = title.replace(/[^\w]/g, '-') + '-slider';
  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <Typography id={labelId} className={classes.title}>
          {title}
        </Typography>
        {!disabled && (
          <Typography className={classes.result}>{label(value)}</Typography>
        )}
      </div>

      <div className={classes.sliderBox}>
        <Typography className={classes.minMax}>{min}</Typography>

        <Slider
          // valueLabelFormat={label} // left out because
          onChange={(_, value) =>
            onChange(typeof value === 'number' ? value : value[0])
          }
          aria-labelledby={labelId}
          valueLabelDisplay='auto'
          step={step}
          min={min}
          max={max}
          value={value}
          disabled={disabled}
        />

        <Typography className={classes.minMax}>{max}</Typography>
      </div>
    </div>
  );
};

export default NumberSlider;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSONTreeView from '../../shared/components/JSONTreeView';
import FlatJsonView from '../../shared/components/FlatJsonView';
import TitledCard from '../../shared/components/TitledCard';
import DefaultPageLayout from '../../shared/layouts/DefaultPageLayout';
import Stack from '../../shared/layouts/Stack';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteNames } from '../../routing/RouteNames';
import { CommentForm } from './CommentForm';

const AuditTrailDetailPage: React.FC = () => {
  const { t } = useTranslation();

  const { eventId } = useParams<{ eventId: string }>();
  const { auditTrailEvents } = useSelector(
    (state: State) => state.auditTrailInfo
  );

  const [currentEvent, setCurrentEvent] = useState(
    auditTrailEvents.find((event) => event._id === eventId)
  );

  const updateComment = (comment: string) =>
    setCurrentEvent((prev) => (prev ? { ...prev, comment } : prev));

  // Note: If the page was just reloaded (or currentEvent doesn't exists for any other reason...)
  // then just redirect to the main Audit Trail Page.
  // Note 2: If needed, then could implement a fetch for this case (eg: for sharing audit trail page links...)
  if (!currentEvent) return <Redirect to={RouteNames.AUDIT_TRAIL} />;
  else
    return (
      <DefaultPageLayout>
        <Stack>
          <TitledCard title={t('INFO')} iconAvatar={<InfoOutlinedIcon />}>
            <FlatJsonView jsonObj={currentEvent} />
          </TitledCard>
          {currentEvent && currentEvent.details && (
            <TitledCard title={t('DETAILS')} iconAvatar={<ListOutlinedIcon />}>
              <JSONTreeView
                name={currentEvent.eventType + ' Details'}
                jsonObj={currentEvent.details}
              />
            </TitledCard>
          )}
          <CommentForm event={currentEvent} updateComment={updateComment} />
        </Stack>
      </DefaultPageLayout>
    );
};

export default AuditTrailDetailPage;

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllUsers,
  getDevices,
  getUserNotifications,
  getUserProfile,
} from '../api';
import {
  setUsers,
  setDevices,
  setUserProfile,
  setNotifications,
} from '../redux/actionCreators';

import { decodeJWTPayload } from '../utils';

export const useLoadInitialData = () => {
  const [loadingData, setLoadingData] = useState(true);
  const token = useSelector((state: State) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;

    const tokenPayload = decodeJWTPayload(token);
    const userRole = tokenPayload.role as UserRole;
    const isAuthorizedForUserManagement =
      userRole === 'manufacturer' || userRole === 'certos_admin';

    const getUserProfilePromise = getUserProfile(token);
    const getDevicesPromise = getDevices(token);
    const getAllUsersPromise = isAuthorizedForUserManagement
      ? getAllUsers(token)
      : new Promise<null>((resolve) => resolve(null));
    const getAllNotifications = getUserNotifications(token);

    Promise.all([
      getUserProfilePromise,
      getDevicesPromise,
      getAllUsersPromise,
      getAllNotifications,
    ]).then(([userProfile, devices, allUserResult, notifications]) => {
      dispatch(setUserProfile(userProfile));
      dispatch(setDevices(devices));
      allUserResult && dispatch(setUsers(allUserResult.userList));
      dispatch(setNotifications(notifications));
      // now the
      setLoadingData(false);
    });
  }, [token, dispatch]);

  return loadingData;
};

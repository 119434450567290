import React from 'react';
import { TextField } from '@material-ui/core';
import Stack from '../../shared/layouts/Stack';
import { useSelector } from 'react-redux';
import { useAsyncFormSubmit } from '../../hooks/useAsyncFormSubmit';
import { updateCertosSettings } from '../../api';
import { setCertosSettings } from '../../redux/actionCreators';
import TitledCard from '../../shared/components/TitledCard';
import { useEmailInputValidation } from '../../hooks/useEmailInputValidation';
import FormSubmitButton from '../../shared/components/FormSubmitButton';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import useSyncedState from '../../hooks/useSyncedState';
import { useTranslation } from 'react-i18next';

interface CompanyInfoSettingsProps {}

const CompanyInfoSettings: React.FC<CompanyInfoSettingsProps> = () => {
  const { t } = useTranslation();

  const token = useSelector((state: State) => state.token)!;
  const certosSettings = useSelector((state: State) => state.settings);
  const { companyInfo } = certosSettings;

  const [companyName, setCompanyName] = useSyncedState<string>(
    companyInfo.companyName
  );
  const [email, setEmail] = useSyncedState<string>(companyInfo.email);
  const [phone, setPhone] = useSyncedState<string>(companyInfo.phone);
  const [fax, setFax] = useSyncedState<string>(companyInfo.fax);
  const [address, setAddress] = useSyncedState<string>(companyInfo.address);
  const [address2, setAddress2] = useSyncedState<string>(companyInfo.address2);
  const [ceoName, setCeoName] = useSyncedState<string>(companyInfo.ceoName);
  const [moreInfo, setMoreInfo] = useSyncedState<string>(companyInfo.moreInfo);

  const [emailError, emailHelperText, validateAsEmail] =
    useEmailInputValidation(email);

  const invalidInputs: boolean =
    companyName === '' || email === '' || emailError;

  const { makeSubmitHandler, loading, feedback, setFeedback } =
    useAsyncFormSubmit(updateCertosSettings, {
      token,
      ...certosSettings,
      companyInfo: {
        companyName,
        email,
        phone,
        fax,
        address,
        address2,
        ceoName,
        moreInfo,
      },
    });

  const handleSubmit = makeSubmitHandler({
    onSuccess: (dispatch, data) => {
      dispatch(setCertosSettings(data));
      setFeedback({
        message: t('COMPANY_INFORMATION_UPDATED'),
        msgType: 'success',
      });
    },
  });

  return (
    <TitledCard
      title={t('COMPANY_INFORMATION')}
      iconAvatar={<BusinessOutlinedIcon />}
    >
      <Stack>
        <TextInput
          label={t('COMPANY_NAME')}
          value={companyName}
          setValue={setCompanyName}
        />
        <TextInput label={t('CEO')} value={ceoName} setValue={setCeoName} />

        <TextInput
          label={t('EMAIL')}
          value={email}
          setValue={setEmail}
          onBlur={validateAsEmail}
          error={emailError}
          helperText={emailHelperText}
        />
        <TextInput label={t('PHONE')} value={phone} setValue={setPhone} />
        <TextInput label={t('FAX')} value={fax} setValue={setFax} />

        <TextInput label={t('ADDRESS')} value={address} setValue={setAddress} />
        <TextInput
          label={t('ADDRESS_LINE_2')}
          value={address2}
          setValue={setAddress2}
        />

        <TextInput
          label={t('ADDITIONAL_INFO_IMPRINT')}
          value={moreInfo}
          setValue={setMoreInfo}
          multiline
        />

        <FormSubmitButton
          btnName={t('SAVE_CHANGES')}
          handleSubmit={handleSubmit}
          invalidInputs={invalidInputs}
          loading={loading}
          feedback={feedback}
        />
      </Stack>
    </TitledCard>
  );
};

interface TextInputProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
  multiline?: boolean;
  onBlur?:
    | React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  error?: boolean | undefined;
  helperText?: string | undefined;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  setValue,
  multiline,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <TextField
      fullWidth
      multiline={multiline}
      label={label}
      variant='standard'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    />
  );
};

export default CompanyInfoSettings;

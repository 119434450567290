import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import TitledCard from '../../../shared/components/TitledCard';
import { sampleScatterChartData } from './sampleData';

const ScatterChartSamples: React.FC = () => {
  return (
    <TitledCard title='ScatterChart'>
      <ResponsiveContainer width='100%' height={400}>
        <ScatterChart
          width={500}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type='number' dataKey='x' name='stature' unit='cm' />
          <YAxis
            yAxisId='left'
            type='number'
            dataKey='y'
            name='weight'
            unit='kg'
            stroke='#8884d8'
          />
          <YAxis
            yAxisId='right'
            type='number'
            dataKey='y'
            name='weight'
            unit='kg'
            orientation='right'
            stroke='#82ca9d'
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter
            yAxisId='left'
            name='A school'
            data={sampleScatterChartData.data01}
            fill='#8884d8'
          />
          <Scatter
            yAxisId='right'
            name='A school'
            data={sampleScatterChartData.data02}
            fill='#82ca9d'
          />
        </ScatterChart>
      </ResponsiveContainer>
    </TitledCard>
  );
};

export default ScatterChartSamples;
